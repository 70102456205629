import Button from '../components/button/button.component';
import FaqItem, {FaqItemProps} from './components/faq-item';
import './faq.scss';

type Props = {
  items: FaqItemProps[];
};

const Faq: React.FC<Props> = ({items}) => {
  const elements = items.map((item) => (
    <FaqItem {...item} key={item.question} />
  ));
  return (
    <div className="faq">
      <div className="col">
        <h2>De meest gestelde vragen</h2>
        <div className="items-holder">{elements}</div>
        <Button type="secondary" link="https://aanmelden.amuse.nl/">
          Meer tonen
        </Button>
      </div>
    </div>
  );
};

export default Faq;

export type JoinSessionResponseJSON = {
  changeToken: string;
  creationDate: string;
  donations: '0' | '1';
  paymentMethod: string;
  roundNumber: string;
  status: 'open' | 'closed';
  tableNumber: string;
  id: string;
  restaurantId: string;
  token: string;
};

export default class JoinSessionResponse {
  changeToken: string;
  creationDate: string;
  donations: '0' | '1';
  paymentMethod: string;
  roundNumber: string;
  status: 'open' | 'closed';
  tableNumber: string;
  id: string;
  restaurantId: string;
  token: string;

  constructor(json: any) {
    this.changeToken = json.changeToken;
    this.creationDate = json.creationDate;
    this.donations = json.donations;
    this.paymentMethod = json.paymentMethod;
    this.roundNumber = json.roundNumber;
    this.status = json.status;
    this.tableNumber = json.tableNumber;
    this.id = json.id;
    this.restaurantId = json.restaurantId;
    this.token = json.token;
  }
}

import HistoricOrderDetails from './historicOrderDetails';

export default class HistoricOrder {
  status: string;
  details: HistoricOrderDetails;
  orderDate: string;
  roundNumber: string;
  sessionId: string;
  restaurantId: string;
  donations: string;
  tips: string;
  amount: number;

  constructor(json: any) {
    this.status = json.status;
    this.details = new HistoricOrderDetails(json.details);
    this.orderDate = json.orderDate;
    this.roundNumber = json.roundNumber;
    this.sessionId = json.sessionId;
    this.restaurantId = json.restaurantId;
    this.donations = json.donations;
    this.tips = json.tips;
    this.amount = 1;
  }

  equals = (other: HistoricOrder) => {
    return this.details.equals(other.details);
  };
}

import React, {useCallback, useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import RestaurantLabel from '../../models/restaurantLabel';
import {AppState, useAppThunkDispatch} from '../../redux/reducers/root.reducer';
import {FilterType} from '../../redux/search/filter';
import {
  addFilter,
  removeFilter,
  getLabels,
} from '../../redux/search/search.thunks';
import CategoryItem from '../category-item/category-item';
import './category-list.scss';

type Props = {};

const CategoryList: React.FC<Props> = () => {
  const dispatch = useAppThunkDispatch();

  useEffect(() => {
    dispatch(getLabels());
  }, [dispatch]);

  const currentFilters = useSelector(
    (state: AppState) => state.search.activeFilters,
  );

  const categories = useSelector((state: AppState) =>
    state.search.labels.filter((x) => x.highlighted === '1'),
  );

  const toggleCategory = useCallback(
    (category: RestaurantLabel) => {
      let add = true;
      for (const filter of currentFilters) {
        if (filter.value === category.label) {
          add = false;
          dispatch(removeFilter(filter));
        }
      }
      if (add) {
        dispatch(addFilter(FilterType.Label, category.label, category.title));
      }
    },
    [currentFilters, dispatch],
  );

  const categoryElements = useMemo(() => {
    return categories.map((category, i) => {
      return (
        <CategoryItem
          key={i}
          category={category}
          toggleCategory={toggleCategory}
        />
      );
    });
  }, [toggleCategory, categories]);

  return (
    <div className="wrapper">
      <div className="category-list">{categoryElements}</div>
    </div>
  );
};
export default CategoryList;

import OccupationGroupsScreen from './screens/occupation-groups/occupation-groups.screen';
import FacebookCallback from './screens/facebook-callback/facebook.callback.screen';
import AccountConfirm from './screens/account-confirmed/account-confirmed.screen';
import LandingPageComponent from './screens/landing-page/landing-page.component';
import ForgotPassword from './screens/forgot-password/forgot-password.screen';
import PaymentResult from './screens/payment-result/payment-result.screen';
import ChangePassword from './screens/change-password/change-password';
import Restaurants from './screens/restaurants/restaurants.screen';
import Walkthrough from './screens/walkthrough/walkthrough.screen';
import Restaurant from './screens/restaurant/restaurant.screen';
import QRScanner from './screens/qr-scanner/qr.scanner.screen';
import Allergies from './screens/allergies/allergies.screen';
import {restoreSession} from './redux/session/session.thunks';
import {loadAccount} from './redux/actions/account.actions';
import Register from './screens/register/register.screen';
import {getLabels} from './redux/search/search.thunks.ts';
import Profile from './screens/profile/profile.screen';
import Filter from './screens/filters/filters.screen';
import Orders from './screens/orders/orders.screen';
import Search from './screens/search/search.screen';
import Visits from './screens/visits/visits.screen';
import Logout from './screens/logout/logout.screen';
import {sendToDevice} from './utils/native_utils';
import AndroidBack from './services/android_back';
import Visit from './screens/visit/visit.screen';
import Login from './screens/login/login.screen';
import {Route, Switch} from 'react-router-dom';
import Home from './screens/home/home.screen';
import React, {Component} from 'react';
import Syncer from './services/syncer';
import {connect} from 'react-redux';
import './styles/main.scss';
import MapScreen from './screens/map/map.screen';
import ReservationTicketScreen from './screens/reservation-ticket/reservation-ticket.screen';
import PrivacyPolicyPage from './screens/privacy-policy/privacy-policy.page';

class App extends Component {
  componentDidMount() {
    this.props.restoreSession();
    this.props.loadAccount();
    this.props.getLabels();

    window.appReady = this.appReady;

    sendToDevice({method: 'ready'});
    this.checkHapStapTheme();
  }

  appReady = (os) => {
    if (os === 'ios') {
      localStorage.setItem('is_ios_app', '1');
      localStorage.setItem('is_native_app', '1');
    } else if (os === 'android') {
      localStorage.setItem('is_android_app', '1');
      localStorage.setItem('is_native_app', '1');
    }
  };

  checkHapStapTheme = () =>{
    if(process.env.REACT_APP_HAP_STAP === 'true'){
      const root = document.documentElement;
      root.style.setProperty('--primary-color', '#e62059');
      root.style.setProperty('--primary-color-new', '#e62059');
      root.style.setProperty('--primary-color-new-hover', '#f71154');
    }
  }

  render() {
    return (
      <>
        <AndroidBack />
        <Syncer />
        <Switch>
          <Route path="/account-confirm" component={AccountConfirm} />
          <Route
            path="/restaurant/:slug/menuitem/:menuitemid"
            component={Restaurant}
          />
          <Route path="/reservation" component={ReservationTicketScreen} />
          <Route path="/restaurant/:slug" component={Restaurant} />
          <Route path="/restaurants/:city" component={Restaurants} />
          <Route path="/restaurants" component={Home} />
          <Route path="/scanner" component={QRScanner} />
          <Route path="/profile" component={Profile} />
          <Route path="/orders" component={Orders} />
          <Route path="/search" component={Search} />
          <Route path="/filter" component={Filter} />
          <Route path="/walkthrough" component={Walkthrough} />
          <Route path="/allergies" component={Allergies} />
          <Route path="/login/fb" component={FacebookCallback} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/change-password" component={ChangePassword} />
          <Route path="/logout" component={Logout} />
          <Route path="/visits" component={Visits} />
          <Route path="/visit/:sessionid" component={Visit} />
          <Route path="/occupations" component={OccupationGroupsScreen} />
          <Route path="/home" component={Home} />
          <Route path="/payment-result" component={PaymentResult} />
          <Route path="/restaurants-map" component={MapScreen} />
          <Route path="/privacy-policy" component={PrivacyPolicyPage} />
          <Route path="/" component={LandingPageComponent} />
        </Switch>
      </>
    );
  }
}

export default connect(
  () => {
    return {};
  },
  {getLabels, restoreSession, loadAccount},
)(App);

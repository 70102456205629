export class SideDishOption {
  id: string;
  option: string;
  price: number;

  constructor(json: any) {
    this.id = json.id;
    this.option = json.option;
    this.price = parseInt(json.price);
  }
}

export default class SideDish {
  key: string;
  title: string;
  multiSelectable: boolean;
  items: Array<SideDishOption>;

  constructor(json: any) {
    this.key = json.key;
    this.title = json.title;
    this.multiSelectable = json.multiSelectable === '1';
    this.items = json.items.map((x: any) => new SideDishOption(x));
  }
}

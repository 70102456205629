import _ from 'lodash';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router-dom';

import CategoryHeader from '../../components/category-header/category.header.component';
import Content from '../../components/content/content.component';
import DonationOrderItemHeader from '../../components/donation-order-item/donation-order-item.component';
import OrderItem from '../../components/order-item/order.item.component';
import OrderTip from '../../components/order-tip/order.tip.component';
import OrderTotal from '../../components/order-total/order.total.component';
import Toolbar from '../../components/toolbar/toolbar.component';
import HistoricOrder from '../../models/history/historicOrder';
import HistoricSession from '../../models/history/historicSession';
import {getSessionHistory} from '../../redux/order-history/session.history.thunks';
import {AppState} from '../../redux/reducers/root.reducer';

type ReduxActions = {
  getSessionHistory: (token: string) => void;
};

type ReduxProps = {
  account: any;
  loggedIn: boolean;
  sessionHistory: Array<HistoricSession>;
  targetSession?: HistoricSession;
};

type RouteParams = {
  sessionid: string;
};

type InputProps = {} & RouteComponentProps<RouteParams>;

type Props = ReduxActions & ReduxProps & InputProps;

class Visit extends Component<Props> {
  componentDidMount() {
    setTimeout(() => {
      const {account, loggedIn} = this.props;

      if (loggedIn) {
        this.props.getSessionHistory(account.token);
      }
    }, 50);
  }

  _getItems = () => {
    const {targetSession} = this.props;
    if (targetSession) {
      let orders = targetSession.orders;

      const items: Array<HistoricOrder> = orders;

      orders = items.map((item) => {
        return {...item, ordered: true};
      });

      let categories = [
        {type: 'eten', title: 'Eten'},
        {type: 'drinken', title: 'Drinken'},
      ];

      return [...orders]
        .sort((a, b) => a.details.type.localeCompare(b.details.type))
        .reduce((previous: any, current: any) => {
          const category = categories.find(
            (category) => current.details.type === category.type,
          );
          if (category) {
            categories = categories.filter((c) => c.type !== category.type);
            return [
              ...previous,
              {type: 'category', title: category.title},
              {...current, type: 'order'},
            ];
          }
          return [...previous, {...current, type: 'order'}];
        }, []);
    } else {
      return [];
    }
  };

  getSideDishesPrice = (sideDishes: {[key: string]: any}) => {
    let sideDishPrice = 0;
    Object.keys(sideDishes).forEach((key) => {
      const dish = sideDishes[key];
      if (dish.options && dish.options.length > 0) {
        dish.options.forEach(
          (option: any) => (sideDishPrice += parseInt(option.priceModifier)),
        );
      }
    });
    return sideDishPrice;
  };

  _renderItems = () => {
    const {targetSession} = this.props;
    const items = this._getItems();

    const totalDonations = (targetSession?.donations ?? 0) * 100;
    if (totalDonations > 0) {
      items.push({
        type: 'donations',
        title: 'Donaties',
        amount: totalDonations,
      });
    }

    return items.map((item, index) => {
      if (item.type === 'donations') {
        return (
          <DonationOrderItemHeader title={item.title} amount={item.amount} />
        );
      }
      if (item.type === 'category') {
        return <CategoryHeader title={item.title} key={index} />;
      }
      if (item.type === 'order') {
        const details = item.details;

        return (
          <OrderItem
            title={details.title}
            description={details.description}
            extra={details.extra}
            item={details}
            key={index}
            price={details.mainPrice}
            ordered={true}
            sideDish={details.sideDishes}
            comment={details.comment}
            allergies={details.allergies}
            amount={item.amount}
            status={item.status}
          />
        );
      }
      return <></>;
    });
  };

  render() {
    const {loggedIn, targetSession, history} = this.props;

    if (!loggedIn) {
      history.push('/login');
      return <></>;
    }

    const tipsAmount = (targetSession?.tips ?? 0) * 100;
    return (
      <>
        <Toolbar title="Bestelgeschiedenis" back={true} />
        <Content toolbar={true}>
          {this._renderItems()}
          {tipsAmount > 0 && <OrderTip price={tipsAmount} />}
          <OrderTotal price={(targetSession?.totalPrice ?? 0).toFixed(2)} />
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state: AppState, props: InputProps) => {
  const {sessionid} = props.match.params;

  return {
    loggedIn: state.account.loggedIn,
    account: state.account,
    sessionHistory: state.sessionHistory,
    targetSession: state.sessionHistory.find((s) => s.sessionId === sessionid),
  };
};

export default connect(mapStateToProps, {getSessionHistory})(Visit);

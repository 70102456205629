import Content from '../../components/content/content.component';
import {useCallback, useEffect, useState} from 'react';
import Bg from '../../utils/bg';
import './home.style.scss';
import {isMobileOrTablet} from '../../utils/isMobileOrTablet';
import Toolbar, {MenuItem} from '../../components/toolbar/toolbar.component';
import {ReactComponent as ScanIcon} from '../../assets/icons/scan.svg';
import LocationPicker from '../../components/location-picker/location-picker.component';
import MapButton from '../../components/map-button/map-button.component';
import SearchBar from '../../components/search-bar/search.bar.component';
import CategoryList from '../../components/category-list/category-list';
import {useSelector} from 'react-redux';
import {AppState, useAppThunkDispatch} from '../../redux/reducers/root.reducer';
import Restaurant from '../../models/restaurant';
import RestaurantItem from '../../components/restaurant-item/restaurant.item.component';
import {FilterType, QueryFilter, QueryType} from '../../redux/search/filter';
import api from '../../services/api';
import FiltersContainer from '../../components/filters-container/filters.container.component';
import {cancelSession} from '../../redux/session/session.thunks';
import {useHistory} from 'react-router';
import useUserLocation from '../../utils/hooks/useUserLocation';
import SearchResult from '../../components/search-result/search-result.component';
import RestaurantMenuItem from '../../components/restaurant-menu-item/restaurant.menu.item.component';

type Props = {};

const menuItems: MenuItem[] = [
  {
    id: 'scan',
    icon: ScanIcon,
    className: isMobileOrTablet() ? '' : 'dont-show-on-desktop',
  },
];

const Home: React.FC<Props> = ({}) => {
  const history = useHistory();
  const userLocation = useUserLocation();
  const queryFilter = useSelector((state: AppState) =>
    state.search.activeFilters.find((x) => x.type === FilterType.Query),
  ) as QueryFilter;
  const isSearchingMenuItems = queryFilter?.queryType === QueryType.Menu;

  const restaurants = useSelector(
    (state: AppState) => state.restaurants.restaurants,
  );
  const restaurantFavorites = useSelector(
    (state: AppState) => state.account.restaurantFavorites || {},
  );
  const currentSort = useSelector((state: AppState) =>
    state.search.activeFilters.find((x) => x.type === FilterType.Sort),
  );
  const simpleFilters = useSelector((state: AppState) =>
    state.search.activeFilters.filter((x) => x.type !== FilterType.Query),
  );
  const account = useSelector((state: AppState) => state.account);
  const session = useSelector((state: AppState) => state.session.session);
  const orders = useSelector((state: AppState) => state.orders);
  const foundMenuItems = useSelector(
    (state: AppState) => state.search.foundMenuItems,
  );

  const currentMealType = useSelector((state: AppState) =>
    state.search.activeFilters.find((x) => x.type === FilterType.Mealtype),
  );
  useEffect(() => {
    if (
      localStorage.getItem('shown_walkthrough') !== '1' &&
      isMobileOrTablet()
    ) {
      localStorage.setItem('shown_walkthrough', '1');
      setTimeout(() => history.push('/walkthrough'), 0);
    }
  }, [history]);

  const dispatch = useAppThunkDispatch();

  const _getRestaurants = useCallback(() => {
    var sortFunction;

    if (currentSort?.value === 'category') {
      sortFunction = (a: any, b: any) => {
        return (a.category || '').localeCompare(b.category || '');
      };
    } else if (currentSort?.value === 'locale') {
      sortFunction = (a: any, b: any) => {
        return (a.city || '').localeCompare(b.city || '');
      };
    } else if (currentSort?.value === 'z-a') {
      sortFunction = (a: any, b: any) => {
        return (b.title || '').localeCompare(a.title || '');
      };
    } else {
      sortFunction = (a: any, b: any) => {
        return (a.title || '').localeCompare(b.title || '');
      };
    }

    let filteredRestaurants = [...restaurants];
    const labelFilters = simpleFilters.filter(
      (x) => x.type === FilterType.Label,
    );
    if (labelFilters) {
      filteredRestaurants = filteredRestaurants.filter((rest) =>
        labelFilters.every((labelFilter) => rest.labels[labelFilter.value]),
      );
    }
    const reservationFilter = simpleFilters.find(
      (x) => x.type === FilterType.ReservationEnabled,
    );
    if (reservationFilter) {
      filteredRestaurants = filteredRestaurants.filter(
        (rest) => rest.reservationEnabled === '1',
      );
    }

    const items = filteredRestaurants.reduce(
      (previous: any, current: any) => {
        if (restaurantFavorites[current.id]) {
          previous.favorited.push(current);
        } else {
          previous.items.push(current);
        }

        return previous;
      },
      {favorited: [], items: []},
    );

    return [
      ...items.favorited.sort(sortFunction),
      ...items.items.sort(sortFunction),
    ];
  }, [currentSort?.value, restaurants, restaurantFavorites, simpleFilters]);

  const onMenuItemClicked = useCallback(
    (item: MenuItem) => {
      if (item.id === 'scan') {
        history.push('/scanner');
      }
    },
    [history],
  );

  const onRestaurantClicked = useCallback(
    async (restaurant: Restaurant) => {
      let canJoin = true;
      if (
        session &&
        session.restaurantId !== restaurant.id &&
        orders.menuItemsQueue.length === 0 &&
        orders.menuItemsOrdered.length === 0
      ) {
        canJoin = await dispatch(cancelSession());
      } else if (session && session.status === 'payment-requested') {
        await api.recoverFailedSession(session.id, session.token);
        canJoin = false;
      }
      if (
        (currentMealType && currentMealType.value === 'takeout') ||
        currentMealType?.value === 'delivery'
      ) {
        if (canJoin) {
          const userToken = account.loggedIn ? account.token : null;
          //     await dispatch(
          //       joinSession(restaurant.id, 'order_from_home', '', userToken),
          //     );
        }
      }

      history.replace('/restaurants', {scrollTop: window.scrollY});
      history.push(`/restaurant/${restaurant.generateSlug()}`);
    },
    [
      account.loggedIn,
      account.token,
      currentMealType,
      dispatch,
      history,
      session,
      orders.menuItemsOrdered.length,
      orders.menuItemsQueue.length,
    ],
  );

  const onRestaurantMenuItemClicked = useCallback(
    async (restaurantId: string) => {
      const restaurant = restaurants.find(
        (restaurant) => restaurant.id === restaurantId,
      );
      if (restaurant) {
        onRestaurantClicked(restaurant);
      }
    },
    [onRestaurantClicked, restaurants],
  );

  const renderMenuItems = useCallback(() => {
    return foundMenuItems.map((menuItem, index) => {
      let image = '';
      if (menuItem.imageSquare && menuItem.imageSquare.length > 0) {
        image = menuItem.imageSquare;
      } else if (menuItem.image && menuItem.image.length > 0) {
        image = menuItem.image;
      }
      return (
        <RestaurantMenuItem
          key={index}
          image={image}
          renderStandalone={true}
          onClick={() => onRestaurantMenuItemClicked(menuItem.restaurantId)}
          menuItem={menuItem}
        />
      );
    });
  }, [foundMenuItems, onRestaurantMenuItemClicked]);

  const renderRestaurants = useCallback(() => {
    return _getRestaurants().map((restaurant, i) => {
      return (
        <RestaurantItem
          key={i}
          restaurant={restaurant}
          onClick={onRestaurantClicked}
          userLocation={userLocation}
        />
      );
    });
  }, [_getRestaurants, onRestaurantClicked, userLocation]);

  return (
    <Content toolbar={true}>
      <Bg bg="bg-gray" />
      <Toolbar
        showMenuIcon={true}
        items={menuItems}
        onItemClicked={onMenuItemClicked}
      />
      <div className="location-wrapper">
        <LocationPicker />
        <MapButton />
      </div>
      <SearchBar />
      <CategoryList />
      {queryFilter && <SearchResult query={queryFilter as QueryFilter} />}
      {simpleFilters.length > 0 && <FiltersContainer filters={simpleFilters} />}
      <div className="restaurants-wrapper">
        {isSearchingMenuItems ? renderMenuItems() : renderRestaurants()}
      </div>
    </Content>
  );
};

export default Home;

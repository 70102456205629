import RestaurantOccupation from "../../../models/restaurantOccupation";
import OccupationGroup from '../../../models/occupationGroup';

export type GetOccupationResponseJSON = {
   groups: Array<any>;
   restaurants: Array<any>; 
}

export default class GetOccupationResponse {
    groups: Array<OccupationGroup> = [];
    restaurants: Array<RestaurantOccupation> = [];

    constructor(json: any) {
        this.groups = json.groups.map((x: any) => new OccupationGroup(x));
        this.restaurants = json.restaurants.map((x: any) => new RestaurantOccupation(x));
    }
}
import axios from 'axios';
import {Item} from '../../screens/landing-page/components/featured-item/featured-item';

const API_URL = process.env.REACT_APP_B2B_API_URL;

export default class BlogsAPI {
  getBlogs = async (): Promise<Item[]> => {
    const url = `${API_URL}/blogs`;
    const response = await axios.get(url);

    if (response.status !== 200) {
      return [];
    }

    return response.data.data.data
      .map((item: any) => ({
        title: item.title,
        image: item.image,
        category: item.tags.name,
        url: `https://www.aanmelden.amuse-menu.nl/${item.slug}`,
        date: item.created_at,
      }))
      .slice(-3);
  };
}

export default class Profile {
  email: string;
  firstName: string;
  lastName: string;
  optIn: boolean;
  phoneNumber: string;
  street: string;
  houseNumber: string;
  postalCode: string;
  city: string;
  comment: string;
  profileImage: string;

  constructor(json: any) {
    this.email = json.email;
    this.firstName = json.firstName;
    this.lastName = json.lastName;
    this.optIn = json.optIn === '1';
    this.phoneNumber = json.phoneNumber;
    this.street = json.street;
    this.houseNumber = json.houseNumber;
    this.postalCode = json.postalCode;
    this.city = json.city;
    this.comment = json.comment;
    this.profileImage = json.profileImage;
  }
}

import axios from 'axios';
import Restaurant from '../../models/restaurant';
import Filter, {
  FilterType,
  QueryFilter,
  QueryType,
} from '../../redux/search/filter';
import imageUrl from '../../utils/scalar';
import APIResponse from './responses/APIResponse';

const API_URL = process.env.REACT_APP_API_URL;

export default class RestaurantsAPI {
  getRestaurantBySlug = async (
    slug: string,
  ): Promise<APIResponse<Restaurant>> => {
    const url = `${API_URL}/getRestaurant?slug=${slug}`;
    const response = await axios.get(url);
    const data = response.data;

    return new APIResponse(data, Restaurant);
  };

  getRestaurants = async (filters: Array<Filter> = []) => {
    const searchParams = filters
      .map((filter) => {
        switch (filter.type) {
          case FilterType.Label:
            return '';
          case FilterType.Price:
            return 'pricing=' + filter.value;
          case FilterType.Location:
            return 'city=' + filter.value;
          case FilterType.Mealtype:
            return 'mealType=' + filter.value;
          case FilterType.Query:
            switch ((filter as QueryFilter).queryType) {
              case QueryType.Menu:
                return 'menuItem=' + filter.value;
              case QueryType.Restaurant:
                return 'search=' + filter.value;
              case QueryType.Postalcode:
                return 'postalCode=' + filter.value;
              case QueryType.Preview:
                return 'preview=lwpv';
              default:
                return '';
            }
          default:
            return '';
        }
      })
      .filter((x) => x !== '');
    //TODO: Preview filter (preview=lwpv)
    let queryParam = '';
    if (searchParams.length > 0) {
      queryParam = '?' + searchParams.join('&');
    }

    const url = `${API_URL}/getRestaurants${queryParam}`;
    const response = await axios.get(url);
    const data = response.data;

    if (data.success === 1 && data.data) {
      return data.data.map((x: any) => new Restaurant(x));
    } else {
      return null;
    }
  };

  getFeaturedRestaurants = async () => {
    const url = `${API_URL}/getRestaurants$`;
    const response = await axios.get(url);
    const data = response.data;

    if (data.success === 1 && data.data) {
      return data.data.slice(0, 4).map((x: any) => ({
        title: x.title,
        image: imageUrl(x.bannerImage),
        location: `${x.street} ${x.streetNumber}, ${x.city}`,
        tags: x.labels,
        url: `/restaurant/${x.slug}`,
      }));
    } else {
      return null;
    }
  };
}

import {ReactComponent as ArrowIcon} from '../../assets/icons/arrow.svg';
import {ReactComponent as DeleteIcon} from '../../assets/icons/close.svg';
import './filter.search.item.style.scss';
import React, {useCallback} from 'react';
import {addFilter, addQueryFilter} from '../../redux/search/search.thunks';
import {connect} from 'react-redux';
import {FilterType, QueryType} from '../../redux/search/filter';
import Formatters from '../../utils/formatters';
import {useAppThunkDispatch} from '../../redux/reducers/root.reducer';
import {primaryColor} from '../../utils/constants';

type SearchHistoryItem = {
  label: {title: string};
  value: string;
  type: string;
};

type ReduxProps = {
  addFilter: (
    filterType: FilterType,
    value: string,
    displayValue: string,
  ) => void;
  addQueryFilter: (queryType: QueryType, query: string) => void;
};

type InputProps = {
  label?: any;
  type: string;
  value: string;
  onComplete: (value: SearchHistoryItem) => void;
  onClick?: () => void;
  onDelete?: (value: string) => void;
};

type Props = ReduxProps & InputProps;

const FilterSearchItem: React.FC<Props> = (props: Props) => {
  const dispatch = useAppThunkDispatch();

  const addFilters = useCallback(() => {
    const {onComplete, type, value, label} = props;

    if (type === 'label') {
      props.addFilter(
        FilterType.Label,
        label.label,
        Formatters.capitalize(label.label),
      );
    }

    if (type === 'search') {
      props.addQueryFilter(QueryType.Restaurant, value);
    }

    if (type === 'menu') {
      dispatch(addQueryFilter(QueryType.Menu, value));
    }

    if (type === 'postalcode') {
      props.addQueryFilter(QueryType.Postalcode, value);
    }

    if (type === 'search' || type === 'menu') {
      onComplete({
        label: {title: label},
        value,
        type,
      });
    } else {
      onComplete({
        label: {title: label.label},
        value: label.label,
        type,
      });
    }
  }, [props, dispatch]);

  const _renderLabelDetails = (label: any, value: string) => {
    let selectedTitle = '';
    let unselectedTitle = '';

    if (label.title.toLowerCase().startsWith(value.toLowerCase())) {
      selectedTitle = label.title.substr(0, value.length);
      unselectedTitle = label.title.substr(
        value.length,
        label.title.length - value.length,
      );
    } else {
      unselectedTitle = label.title;
    }

    return (
      <>
        <div className="title">
          <span className="selected">{selectedTitle}</span>
          {unselectedTitle}
        </div>
        <div className="subtitle">In categorieën</div>
      </>
    );
  };

  const _renderSearchDetails = (value: string) => {
    return (
      <>
        <div className="title">{value}</div>
        <div className="subtitle">In restaurants</div>
      </>
    );
  };

  const _renderMenuSearchDetails = (value: string) => {
    return (
      <>
        <div className="title">{value}</div>
        <div className="subtitle">In gerechten</div>
      </>
    );
  };

  const {value, label, type, onDelete} = props;

  return (
    <div
      className="filter_search_item"
      onClick={onDelete ? undefined : addFilters}>
      <div
        className="filter_search_item_details"
        onClick={onDelete ? addFilters : undefined}>
        {type === 'label' && _renderLabelDetails(label, value)}
        {type === 'search' && _renderSearchDetails(value)}
        {type === 'menu' && _renderMenuSearchDetails(value)}
      </div>
      {onDelete ? (
        <DeleteIcon
          onClick={() => onDelete(value)}
          className="filter_search_item_selector delete"
          fill={primaryColor}
        />
      ) : (
        <ArrowIcon className="filter_search_item_selector" fill="#BDC3C7" />
      )}
    </div>
  );
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {addFilter, addQueryFilter})(
  FilterSearchItem,
);

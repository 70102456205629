import Session from '../../models/session';
import {
  SessionActions,
  SET_SESSION,
  RESTORE_SESSION,
  UPDATE_SESSION_DATA,
  REMOVE_SESSION,
  SET_SESSION_CHANGE_TOKEN,
  UPDATE_SESSION_STATUS,
  UPDATE_USER_GREETING_STATE,
} from './session.actiontypes';
import {SessionJSON} from '../../models/json/session.json';
import Logger from '../../utils/logger';

type SessionState = {
  hasSession: boolean;
  session: Session | null;
  hasUserBeenGreeted: boolean;
};

const initialState: SessionState = {
  hasSession: false,
  session: null,
  hasUserBeenGreeted: false,
};

const SessionReducer = (
  state = initialState,
  action: SessionActions,
): SessionState => {
  switch (action.type) {
    case RESTORE_SESSION:
      const savedState = loadSession();
      Logger.log(`loaded session ${JSON.stringify(savedState)}`);
      return savedState;
    case SET_SESSION:
      return saveState(setSession(action.payload));
    case UPDATE_SESSION_STATUS:
      return saveState(setSessionStatus(state, action.payload));
    case UPDATE_SESSION_DATA:
      return saveState(updateSessionData(state, action.payload));
    case REMOVE_SESSION:
      return saveState(removeSession());
    case SET_SESSION_CHANGE_TOKEN:
      return saveState(setSessionChangeToken(state, action.payload));
    case UPDATE_USER_GREETING_STATE:
      return saveState({...state, hasUserBeenGreeted: action.payload});
    default:
      return state;
  }
};

function loadSession() {
  try {
    const session = localStorage.getItem('session');
    if (session) {
      return setSession(Session.fromJson(JSON.parse(session)));
    } else {
      return initialState;
    }
  } catch {
    return initialState;
  }
}

function setSession(payload: Session): SessionState {
  return {
    hasSession: true,
    session: payload,
    hasUserBeenGreeted: false,
  };
}

function setSessionStatus(currentState: SessionState, newStatus: string) {
  const {session} = currentState;
  session!.paymentStatus = newStatus;
  return {
    ...currentState,
  };
}

function updateSessionData(state: SessionState, payload: SessionJSON) {
  if (state.session) {
    state.session.update(payload);
  }
  return {
    ...state,
  };
}

function setSessionChangeToken(
  state: SessionState,
  payload: string,
): SessionState {
  if (state.session) {
    state.session.changeToken = payload;
  }
  return {...state};
}

function removeSession(): SessionState {
  return {...initialState};
}

function saveState(state: SessionState) {
  localStorage.setItem('session', JSON.stringify(state.session));
  return state;
}

export default SessionReducer;

import axios from 'axios';
import SessionDataJSON from '../../models/json/session-data.json';
import JoinSessionResponse from './responses/joinSessionResponse';
import APIResponse from './responses/APIResponse';

const API_URL = process.env.REACT_APP_API_URL;

export default class SessionAPI {
  getSessionData = async (
    sessionId: string,
    sessionToken: string,
  ): Promise<SessionDataJSON | null> => {
    const url = `${API_URL}/getSessionData?sessionId=${encodeURIComponent(
      sessionId,
    )}&sessionToken=${encodeURIComponent(sessionToken)}`;
    const response = await axios.get(url);

    if (response.data.success === 1) {
      return response.data.data;
    }
    return null;
  };

  joinSession = async (
    restaurantId: string,
    restaurantToken: string,
    tableNumber: string,
    userToken: string,
    ggdOptIn: boolean,
    sameHousehold: boolean,
    hasNoSymptoms: boolean,
    name: string,
    phoneNumber: string,
  ): Promise<APIResponse<JoinSessionResponse> | null> => {
    const url = `${API_URL}/joinSession`;

    const data: any = {
      restaurantId: restaurantId,
      restaurantToken: restaurantToken,
      tableNumber: tableNumber,
      lw_token: '',
    };

    data.ggdOptIn = ggdOptIn ? '1' : '0';
    data.sameHousehold = sameHousehold ? '1' : '0';
    data.noSymptoms = hasNoSymptoms ? '1' : '0';
    if (ggdOptIn) {
      data.name = name;
      data.phoneNumber = phoneNumber;
    }

    if (userToken && userToken.length > 0) {
      data.lw_token = userToken;
    }

    const response = await axios.post(url, data);

    return new APIResponse(response.data, JoinSessionResponse);
  };

  cancelSession = async (
    sessionId: string,
    sessionToken: string,
  ): Promise<APIResponse<void>> => {
    const url = `${API_URL}/cancelSession`;
    const response = await axios.post(url, {
      sessionToken,
      sessionId,
    });

    return new APIResponse(response.data);
  };
}

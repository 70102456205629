import Notifications from './components/notifications/notifications.component';
import DrawerMenu from './sections/drawer-menu/drawer-menu.component';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {store} from './redux/store';
import Https from './utils/https';
import ReactDOM from 'react-dom';
import React from 'react';
import App from './app';

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <DrawerMenu/>

            <Https/>

            <App/>
            <Notifications/>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

import {SessionDataOrderJSON} from '../../models/json/session-data-order.json';

export const UPDATE_ORDERS = 'UPDATE_ORDERS';
export const CLEAR_ORDERS = 'CLEAR_ORDERS';
export const ADD_MENU_ITEM = 'ADD_MENU_ITEM';
export const REMOVE_MENU_ITEM = 'REMOVE_MENU_ITEM';
export const SUBMITTED_ORDER = 'SUBMITTED_ORDER';

export type UpdateOrdersAction = {
  type: typeof UPDATE_ORDERS;
  payload: Array<SessionDataOrderJSON>;
};

export type ClearOrdersAction = {
  type: typeof CLEAR_ORDERS;
  payload: void;
};

export type AddMenuItemAction = {
  type: typeof ADD_MENU_ITEM;
  payload: void;
};

export type RemoveMenuItemAction = {
  type: typeof REMOVE_MENU_ITEM;
  payload: void;
};

export type SubmittedOrderAction = {
  type: typeof SUBMITTED_ORDER;
  payload: void;
};

export type OrdersActions =
  | UpdateOrdersAction
  | ClearOrdersAction
  | AddMenuItemAction
  | RemoveMenuItemAction
  | SubmittedOrderAction;

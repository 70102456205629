import { TABLES_LOADED, TablesLoadedAction } from "./reservations.actiontypes"
import Table from "../../models/table"

export const tablesLoadedAction = (restaurantId: string, tables: Array<Table>): TablesLoadedAction => {
    return {
        type: TABLES_LOADED,
        payload: {
            restaurantId: restaurantId,
            tables: tables
        }
    }
}
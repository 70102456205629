import allergiesList from '../allergies/allergies.list';
import './restaurant.menu.item.style.scss';
import imageUrl from '../../utils/scalar';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import Formatters from '../../utils/formatters';
import {MenuItemFavoriteButton} from '../menu-item-favorite-button/menu-item-favorite-button.component';
import Restaurant from '../../models/restaurant';
import {AppState} from '../../redux/reducers/root.reducer';
import DOMPurify from 'dompurify';
import RestaurantMenuItem from '../../models/restaurantMenuItem';

type InputProps = {
  allergies?: Array<any>;
  image: string;
  menuItem: RestaurantMenuItem;
  onClick: () => void;
  renderStandalone?: boolean;
};

type ReduxProps = {
  activeAllergies: Array<any>;
  hideAllergies: boolean;
  restaurants: Array<Restaurant>;
};

type Props = {} & InputProps & ReduxProps;
class RestaurantMenuItemView extends Component<Props> {
  renderPriceBlock = (
    price: number | string,
    actionPrice: number | string,
    actionPriceActive: boolean,
  ) => {
    return (
      <div className="restaurant_menu_item_price_block">
        {actionPriceActive && (
          <p className="restaurant_menu_item_price">{`${Formatters.formatCurrency(
            actionPrice,
          )}`}</p>
        )}
        <p
          className={`restaurant_menu_item_price ${
            actionPriceActive ? 'action_active' : ''
          }`}>{`${Formatters.formatCurrency(price)}`}</p>
      </div>
    );
  };

  _getAllergyInfo = () => {
    const {activeAllergies, allergies} = this.props;

    if (!activeAllergies) return false;

    const targetAllergies = (allergies || [])
      .filter((allergy) => {
        return activeAllergies.find((a) => a === allergy);
      })
      .map((allergy) => {
        return allergiesList.find((a) => a.id === allergy);
      })
      .filter((item) => item)
      .map((item) => (item === undefined ? '' : item.name.toLowerCase()));

    if (targetAllergies.length === 0) return false;

    if (targetAllergies.length === 1) {
      return targetAllergies[0];
    }
    if (targetAllergies.length === 2) {
      return `${targetAllergies[0]} en ${targetAllergies[1]}`;
    }
    return (
      targetAllergies.slice(0, -1).join(', ') +
      ' en ' +
      targetAllergies[targetAllergies.length - 1]
    );
  };

  _getImage = (image: string | undefined) => {
    if (image && image.length > 0) {
      return imageUrl(image);
    }
  };

  render() {
    const {
      image,
      hideAllergies,
      onClick,
      renderStandalone = false,
      restaurants,
      menuItem,
    } = this.props;

    const {
      title,
      description,
      subTitle,
      actionPrice,
      actionActive,
      price,
      restaurantId,
    } = menuItem;

    const allergyInfo = this._getAllergyInfo();

    if (allergyInfo && hideAllergies) {
      return <></>;
    }

    let restaurantName = '';
    const restaurant = restaurants.find((x) => x.id === restaurantId);
    if (restaurant) {
      restaurantName = restaurant.title;
    }
    return (
      <div
        className={`restaurant_menu_item_container ${
          allergyInfo ? 'allergy' : ''
        } ${renderStandalone ? 'standalone' : ''}`}
        onClick={onClick}>
        {allergyInfo && (
          <div className="restaurant_menu_item_allergy">
            Let op! Onderstaand gerecht bevat {allergyInfo}.
          </div>
        )}
        <div className="restaurant_menu_item_wrapper">
          <div className="restaurant_menu_item_info">
            {renderStandalone && (
              <p className="restaurant_menu_item_restaurant">
                {restaurantName}
              </p>
            )}
            <div className="restaurant_menu_item_title_wrapper">
              <h1 className="restaurant_menu_item_title">{title}</h1>
              <MenuItemFavoriteButton
                className="restaurant_menu_item_like"
                menuItem={menuItem}
              />
            </div>
            {subTitle && (
              <h3 className="restaurant_menu_item_extra">{subTitle}</h3>
            )}
            <h2
              className="restaurant_menu_item_description"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(description, {
                  ALLOWED_TAGS: [],
                }),
              }}></h2>
            {this.renderPriceBlock(price, actionPrice, actionActive === '1')}
          </div>
          {image && (
            <img
              src={this._getImage(image)}
              className="restaurant_menu_item_image"
              alt="dish"
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): ReduxProps => {
  return {
    activeAllergies: state.account.allergies,
    hideAllergies: state.account.hideAllergies,
    restaurants: state.restaurants.restaurants,
  };
};

export default connect(mapStateToProps, {})(RestaurantMenuItemView);

import OccupationGroup from "../../models/occupationGroup";
import RestaurantOccupation from "../../models/restaurantOccupation";
import { OccupationActions, OCCUPATION_GROUPS_RETRIEVED } from "./occupation.actiontypes";

type State = {
    groups: Array<OccupationGroup>;
    ungroupedRestaurants: Array<RestaurantOccupation>;
}

const initialState: State = {
    groups: [],
    ungroupedRestaurants: []
};

const OccupationReducer = (state = initialState, action: OccupationActions): State => {
    switch (action.type) {
        case OCCUPATION_GROUPS_RETRIEVED:
            const flatGroupedRestaurants = action.payload.groups.map(x => x.restaurants).reduce((a, b) => [...a, ...b], [])
            return {
                ...state, 
                groups: action.payload.groups.sort((a, b) => a.name.localeCompare(b.name)), 
                ungroupedRestaurants: [...action.payload.ungroupedRestaurants, ...flatGroupedRestaurants].sort((a, b) => a.id.localeCompare(b.id))
            };
        default:
            return state;
    }
};

export default OccupationReducer;


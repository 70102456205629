import {
  updateSessionData,
  setSessionChangeToken,
  removeSession,
} from '../redux/session/session.thunks';
import {clearOrders, updateOrders} from '../redux/actions/orders.actions';
import {showNotification} from '../redux/actions/notifications.actions';
import {getRestaurants} from '../redux/actions/restaurants.actions';
import {getMenu} from '../redux/actions/restaurant.menu.actions';
import {getProfile} from '../redux/actions/account.actions';
import {withRouter} from 'react-router-dom';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import api from './api';
import apiv2 from './api/apiv2';
import {fetchOccupations} from '../redux/occupation/occupation.thunks';

class Syncer extends Component {
  state = {
    currentState: null,
  };

  syncSessionId = null;
  syncRestaurantsId = null;
  syncMenusId = null;
  syncProfileId = null;

  firstRun = true;

  componentDidMount() {
    this.syncSessionId = setInterval(this.refreshSession, 2000);
    this.syncRestaurantsId = setInterval(this.refreshRestaurants, 60000);
    this.syncProfileId = setInterval(this.refreshProfile, 120000);
    this.syncMenusId = setInterval(this.refreshMenus, 60000);

    setTimeout(this.refreshRestaurants, 50);
    setTimeout(this.refreshSession, 50);
    setTimeout(this.refreshProfile, 50);
    setTimeout(this.refreshOccupation, 50);
  }

  componentWillUnmount() {
    clearInterval(this.syncSessionId);
    clearInterval(this.syncRestaurantsId);
    clearInterval(this.syncMenusId);
    clearInterval(this.syncProfileId);
  }

  refreshOccupation = async () => {
    const {fetchOccupations} = this.props;
    fetchOccupations();
  };

  refreshSession = async () => {
    const {session, hasSession, restaurants} = this.props;

    if (!hasSession) {
      return;
    }

    const {id, token, changeToken} = session;

    let response = await api.checkForChanges(id, token);

    // no response, return
    if (!response) {
      return;
    }

    if (response === 'session_closed') {
      const restaurantId = session.restaurantId;

      this.props.removeSession();
      this.props.clearOrders();

      if (restaurantId) {
        const restaurant = restaurants.find((x) => x.id === restaurantId);
        this.props.history.replace(`/restaurant/${restaurant.generateSlug()}`);
      } else {
        this.props.history.replace('/home');
      }
      this.props.showNotification('Bedankt voor je bezoek!', 'success');
      return;
    }

    // change token same as old one, do nothing
    if (changeToken === response.changeToken && !this.firstRun) {
      return;
    }

    this.firstRun = false;

    // update change token
    this.props.setSessionChangeToken(response.changeToken);

    const status = session.status;

    // fetch session
    const sessionResponse = await apiv2.session.getSessionData(
      session.id,
      session.token,
    );

    // no response, return
    if (!sessionResponse) {
      return;
    }

    if (
      sessionResponse.session.status === 'closed' &&
      sessionResponse.session.status !== status
    ) {
      this.props.removeSession();
      this.props.clearOrders();
      this.props.showNotification('Bedankt voor je bezoek!', 'success');
    }

    this.props.updateSessionData(sessionResponse.session);
    this.props.updateOrders(sessionResponse.orders);

    return session;
  };

  refreshRestaurants = () => {
    const {activeNewFilters} = this.props;
    this.props.getRestaurants(activeNewFilters);
  };

  refreshMenus = () => {
    const {restaurantMenu} = this.props;
    if (!restaurantMenu) return;

    Object.keys(restaurantMenu).forEach((restaurantId) => {
      this.props.getMenu(restaurantId);
    });
  };

  refreshProfile = () => {
    const {loggedIn} = this.props;
    if (!loggedIn) return;

    this.props.getProfile(this.props.account.token);
  };

  render() {
    return <></>;
  }
}

const mapStateToProps = (state) => {
  return {
    hasSession: state.session.hasSession,
    session: state.session.session,
    restaurantMenu: state.restaurantMenu,
    loggedIn: state.account.loggedIn,
    account: state.account,
    activeNewFilters: state.search.activeFilters,
    restaurants: state.restaurants.restaurants,
  };
};

export default connect(mapStateToProps, {
  setSessionChangeToken,
  updateSessionData,
  updateOrders,
  getRestaurants,
  getMenu,
  showNotification,
  removeSession,
  clearOrders,
  getProfile,
  fetchOccupations,
})(withRouter(Syncer));

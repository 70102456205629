import Table from '../../models/table';
import {TABLES_LOADED, ReservationsActions} from './reservations.actiontypes';

type ReservationsState = {
  /** Map of table availability, mapped by restaurantId */
  tables: {[restaurantId: string]: Array<Table>};
};

const initialState: ReservationsState = {
  tables: {},
};

const ReservationsReducer = (
  state = initialState,
  action: ReservationsActions,
) => {
  switch (action.type) {
    case TABLES_LOADED:
      const tables = state.tables;
      tables[action.payload.restaurantId] = action.payload.tables;
      return {
        ...state,
        tables,
      };
    default:
      return state;
  }
};

export default ReservationsReducer;

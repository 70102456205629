import moment, {Moment} from 'moment';

export default class TableReservation {
  from: Moment;
  to: Moment;
  tableNumber: string;

  constructor(json: any) {
    this.tableNumber = json.tableNumber;
    this.from = moment(json.from, 'YYYY-MM-DD HH:mm:SS');
    this.to = moment(json.to, 'YYYY-MM-DD HH:mm:SS');
  }
}

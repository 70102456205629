import axios from 'axios';
import Profile from '../../models/profile';
import APIResponse from './responses/APIResponse';
import GetProfileResponse from './responses/getProfileResponse';

const API_URL = process.env.REACT_APP_API_URL;

export default class ProfileAPI {
  get = async (token: string): Promise<APIResponse<GetProfileResponse>> => {
    const url = `${API_URL}/getProfile`;
    const response = await axios.post(url, {lw_token: token});
    return new APIResponse(response.data, GetProfileResponse);
  };

  update = async (
    token: string,
    profile: Profile,
  ): Promise<APIResponse<void>> => {
    const url = `${API_URL}/updateProfile`;
    const response = await axios.post(url, {
      lw_token: token,
      email: profile.email,
      firstName: profile.firstName,
      lastName: profile.lastName,
      optIn: profile.optIn ? '1' : '0',
      phoneNumber: profile.phoneNumber,
      city: profile.city,
      street: profile.street,
      postalCode: profile.postalCode,
      houseNumber: profile.houseNumber,
      comment: profile.comment,
    });
    return new APIResponse(response.data);
  };

  delete = async (token: string, email: string) => {
    const url = `${API_URL}/deleteUser`;
    const response = await axios.post(url, {
      lw_token: token,
      email: email,
    });
    return new APIResponse(response.data);
  };
}

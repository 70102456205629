import React, {Component} from 'react';
//@ts-ignore
import QrReader from 'react-qr-reader2';

type Props = {
    onScanned?: (data: any) => void;
}

export default class Scanner extends Component<Props> {

    render() {
        return (
            <QrReader
                delay={300}
                onError={this.handleError}
                onScan={this.handleScan}
            />
        );
    }

    handleScan = (data: string | null) => {
        const {onScanned} = this.props;
        if (data && onScanned) {
            onScanned(data);
        }
    };
    handleError = (err: any) => {
        console.error(err);
    };

}

import {ReactComponent as MenuIcon} from '../../assets/icons/menu.svg';
import {ReactComponent as BackIcon} from '../../assets/icons/back.svg';
import {ReactComponent as Logo} from '../../assets/images/logo_white.svg';
import HapStapLogo from '../../assets/images/hap_stap_logo.png';
import {showMenu} from '../../redux/actions/menu.actions';
import React from 'react';
import './toolbar.style.scss';
import {isEmpty} from 'lodash';
import {useHistory} from 'react-router';
import {useAppThunkDispatch} from '../../redux/reducers/root.reducer';

export type MenuItem = {
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  id?: string;
  width?: number;
  height?: number;
  className?: string;
};

type Props = {
  showMenuIcon?: boolean;
  onItemClicked?: (clickedItem: MenuItem) => void;
  backLink?: string;
  back?: boolean;
  onBack?: () => boolean;
  items?: Array<MenuItem>;
  title?: string;
};

const Toolbar: React.FC<Props> = ({
  showMenuIcon,
  backLink,
  back,
  items,
  onBack,
  onItemClicked,
  title,
}) => {
  const history = useHistory();
  const dispatch = useAppThunkDispatch();
  const goBack = () => {
    let useDefault = true;
    if (onBack !== undefined) {
      useDefault = onBack();
    }
    if (useDefault) {
      if (backLink) {
        history.replace(backLink);
      } else {
        history.goBack();
      }
    }
  };

  const isHapStap = process.env.REACT_APP_HAP_STAP === 'true';

  const _showMenuIcon = () => {
    return (
      <button className="toolbar_button" onClick={() => dispatch(showMenu())}>
        <MenuIcon width={26} height={26} fill="white" />
      </button>
    );
  };

  const _showBack = () => {
    return (
      <button className="toolbar_button" onClick={goBack}>
        <BackIcon width={20} height={20} fill="white" />
      </button>
    );
  };

  const _renderMenuItem = (item: MenuItem, index: number) => {
    const Icon = item.icon;
    const width = item.width || 26;
    const height = item.height || 26;
    let listener = () => {};

    if (onItemClicked) {
      listener = () => onItemClicked(item);
    }

    return (
      <button
        key={index}
        className={`toolbar_button ${item.className}`}
        onClick={listener}>
        <Icon width={width} height={height} fill="white" />
      </button>
    );
  };

  const menuItems = (items || []).map((item: MenuItem, index: number) =>
    _renderMenuItem(item, index),
  );

  return (
    <div className="toolbar">
      {isEmpty(title) || title === '' ? (
        <>
          {isHapStap && (
            <img
              style={{width: 120, height: 70, marginRight: 8}}
              src={HapStapLogo}
              alt="Hap Stap"
            />
          )}
          <Logo width={70} height={70} />
        </>
      ) : (
        <span>{title}</span>
      )}

      <div className="toolbar_menu">
        {showMenuIcon && _showMenuIcon()}
        {(back || backLink) && _showBack()}
        <div className="flex-1" />
        {menuItems}
      </div>
    </div>
  );
};

export default Toolbar;

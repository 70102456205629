import Table from "../../models/table";

export const TABLES_LOADED = 'TABLES_LOADED';

export type TablesLoadedAction = {
    type: typeof TABLES_LOADED,
    payload: {
        restaurantId: string,
        tables: Array<Table>
    }
}

export type ReservationsActions = TablesLoadedAction
import {showNotification} from '../../redux/actions/notifications.actions';
import AltAction from '../../components/alt-action/alt.action.component';
import {Component} from 'react';
import './table.dialog.style.scss';
import {connect} from 'react-redux';
import {NotificationType} from '../../models/notification';
import DialogImageHeader from '../../components/dialog-image-header/dialog-image-header';
import {
  Button,
  Input,
  InputGroup,
  Label,
  Checkbox,
  RadioGroup,
  ModalDialog,
} from '../../components/ui-base';

class TableDialog extends Component {
  state = {
    inputTableNumber: '',
    inputPersonAmount: '',
    ggdOptInChecked: undefined,
    name: '',
    phoneNumber: '',
    isSameHousehold: undefined,
    hasNoSymptoms: undefined,
    invalidFields: [],
  };

  componentDidMount() {
    this.setState({inputTableNumber: this.props.tableNumber});
  }

  onTableInputChanged = (event) => {
    this.setState({inputTableNumber: event.target.value});
  };

  onNameInputChanged = (event) => {
    this.setState({name: event.target.value});
  };

  onPhoneNumberInputChanged = (event) => {
    this.setState({phoneNumber: event.target.value});
  };

  onSameHouseholdClicked = (isChecked) => {
    this.setState({isSameHousehold: isChecked});
  };

  onHasNoSymptomsClicked = (isChecked) => {
    this.setState({hasNoSymptoms: isChecked});
  };

  prefillAddressFromAccount = () => {
    const {account} = this.props;
    this.setState({
      name: account.firstName + ' ' + account.lastName,
      phoneNumber: account.phoneNumber,
    });
  };

  onSubmit = () => {
    const numberRegex = /^[1-9][0-9]*$/;

    const {
      inputTableNumber,
      name,
      phoneNumber,

    } = this.state;

    let invalidFields = [];
    if (!numberRegex.test(inputTableNumber)) {
      this.props.showNotification(
        'Vul een valide tafelnummer in.',
        NotificationType.ERROR,
      );
      invalidFields.push('tableNumber');
      return;
    }

    this.setState({invalidFields: invalidFields});

    if (invalidFields.length > 0) {
      this.props.showNotification(
        'Beantwoord deze vraag aub',
        NotificationType.ERROR,
      );
      return;
    }

    this.props.onComplete(
      parseInt(inputTableNumber, 10),
      1,
      {
        name: name,
        phoneNumber: phoneNumber,
      },
    );
  };

  render() {
    const { onCancel} = this.props;
    const {
      inputTableNumber,
    } = this.state;

    return (
      <ModalDialog>
        <DialogImageHeader />

        <h1 className="table_dialog_title">Laat ons weten waar je zit</h1>
        <h2 className="table_dialog_description">
          Registreer je tafelnummer en beantwoord onderstaande vragen om door te
          gaan.
        </h2>

        <InputGroup>
          <Label>Tafelnummer</Label>
          <Input
            type="number"
            placeholder="Bijvoorbeeld '32'"
            value={inputTableNumber}
            onChange={this.onTableInputChanged}
          />
        </InputGroup>
 


        <Button
          text="Volgende stap"
          className="mt-30"
          outline={true}
          onClick={this.onSubmit}
        />

        <AltAction
          text="Annuleren"
          className="mt-20"
          icon="close"
          onClick={onCancel}
        />
      </ModalDialog>
    );
  }
}

export default connect(
  (state) => {
    return {
      account: state.account,
    };
  },
  {showNotification},
)(TableDialog);

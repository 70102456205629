import React, {useCallback, useState} from 'react';
import {SideDishOption} from '../../../../models/sidedish';
import Formatters from '../../../../utils/formatters';
import {SideDishSelectable} from '../side.dishes.dialog.component';
import { InputGroup, Label, Select } from '../../../../components/ui-base';

type Props = {
  dish: SideDishSelectable;
  onSelectionChanged: () => void;
};

const SingleChoiceDish: React.FC<Props> = (props: Props) => {
  const [selection, setSelection] = useState<SideDishOption>(
    props.dish.items[0],
  );

  const opts = props.dish.items.map((option, index) => {
    const title =
      option.price > 0
        ? `${option.option} (+ ${Formatters.formatCurrency(option.price)})`
        : option.option;
    return (
      <option key={index} value={option.id}>
        {title}
      </option>
    );
  });

  const onChangeCallback = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedOption = props.dish.items.find(
        (x) => x.id === event.target.value,
      )!;
      props.dish.selections = [selectedOption];
      setSelection(selectedOption);
      props.onSelectionChanged();
    },
    [props],
  );

  return (
    <InputGroup>
      <Label>{props.dish.title}</Label>
      <Select value={selection.id} onChange={onChangeCallback}>
        {opts}
      </Select>
    </InputGroup>
  );
};

export default SingleChoiceDish;

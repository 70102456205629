import api from '../../services/api';
import {cancelSession} from '../session/session.thunks';
import SessionType from '../../models/session-type';
import {AppState} from '../reducers/root.reducer';
import {
  ADD_MENU_ITEM,
  CLEAR_ORDERS,
  SUBMITTED_ORDER,
  UPDATE_ORDERS,
} from '../actiontypes/orders.actiontypes';

export function updateOrders(orders: Array<any>) {
  return async function (dispatch: any) {
    dispatch({
      type: UPDATE_ORDERS,
      payload: orders,
    });
  };
}

export function addMenuItem(
  sessionId: string,
  sessionToken: string,
  menuItem: any,
  sideDishSelections: any,
  comment: string,
  amount: number,
) {
  return async function (dispatch: any) {
    // add item
    const response = await api.addOrder(
      sessionId,
      sessionToken,
      menuItem.id,
      sideDishSelections,
      comment,
      amount,
    );

    if (response.success === 1) {
      dispatch({
        type: ADD_MENU_ITEM,
        payload: response,
      });
    }

    return Promise.resolve(response);
  };
}

export function changeMenuItemAmount(
  sessionId: string,
  sessionToken: string,
  menuItem: any,
  add: boolean,
) {
  return async function (dispatch: any, getState: () => AppState) {
    if (add) {
      // add item

      // get any of the order ids
      const orderId = menuItem.orderIds[0];

      const response = await api.duplicateOrder(
        sessionId,
        sessionToken,
        orderId,
      );

      if (response) {
        dispatch({
          type: ADD_MENU_ITEM,
          payload: response,
        });
      }
    } else {
      const orderId =
        menuItem.orderIds[Math.floor(Math.random() * menuItem.orderIds.length)];

      const response = await api.removeOrder(sessionId, sessionToken, orderId);

      if (response) {
        // remove item
        await dispatch({
          type: 'REMOVE_MENU_ITEM',
          payload: orderId,
        });

        const state = getState();
        if (
          state.orders.menuItemsQueue.length === 0 &&
          state.session.session !== null &&
          state.session.session.sessionType === SessionType.FROM_HOME
        ) {
          dispatch(clearOrders());
          dispatch(cancelSession());
        }
      }
    }
  };
}

export function submitOrders(
  sessionId: string,
  sessionToken: string,
  extraOptions: any,
) {
  return async function (dispatch: any) {
    const response = await api.submitOrders(
      sessionId,
      sessionToken,
      extraOptions,
    );

    if (response.success === 1) {
      dispatch({
        type: SUBMITTED_ORDER,
        payload: {},
      });
    }

    return Promise.resolve(response);
  };
}

export function clearOrders() {
  return async function (dispatch: any) {
    dispatch({
      type: CLEAR_ORDERS,
      payload: {},
    });
  };
}

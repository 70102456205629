export default class RestaurantOccupation {
  /** Restaurant id */
  id: string;
  /** Restaurant title */
  title: string;
  /** Total amount of tables this restaurant has */
  physicalTables: number;
  /**
   * @deprecated use physicalTables
   * Total amount of tables the restaurant has (its capacity)
   * */
  get numberOfTables() {
    return this.physicalTables;
  }
  /** Id of the group this restaurant is assigned to */
  groupId: string;
  /** How many tables are currently occupied */
  occupants: number;
  /** The percentage of tables that's currently occupied */
  occupationPercentage: number;

  constructor(json: any) {
    this.id = json.id;
    this.title = json.title;
    this.groupId = json.groupId;
    this.occupants = json.occupants;
    this.occupationPercentage = json.occupationPercentage;
    this.physicalTables = json.physicalTables;
  }
}

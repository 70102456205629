import api from '../../services/api';
import api2 from '../../services/api/apiv2';
import {menuItemsSearchResultAction} from '../search/search.action';

export function getMenu(restaurantId, isPreview = false) {
  return async function (dispatch) {
    const menu = await api.getMenu(restaurantId, isPreview);
    const menuCategories = await api.getMenuCategories(restaurantId);

    if (menu) {
      dispatch({
        type: 'GET_MENU',
        payload: {id: restaurantId, items: menu},
      });
    }
    if (menuCategories) {
      dispatch({
        type: 'GET_MENU_CATEGORIES',
        payload: {id: restaurantId, items: menuCategories},
      });
    }
  };
}

export function searchMenuItems(filters = []) {
  return async function (dispatch) {
    const menu = await api2.menu.search(filters);

    if (menu) {
      dispatch(menuItemsSearchResultAction(menu));
    }
  };
}

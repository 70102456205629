import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import AltAction from '../../../components/alt-action/alt.action.component';
import PaymentMethodSelector from '../../../components/payment-method-selector/payment-method-selector';
import TimeslotSelector from '../../../components/timeslot-selector/timeslot-selector';
import { ModalDialogTitle, ModalDialogDescription, Button, InputGroup, Label, Input, Checkbox } from '../../../components/ui-base';
import Restaurant from '../../../models/restaurant';
import Timeslot from '../../../models/timeslot';
import { AppState } from '../../../redux/reducers/root.reducer';
import DateSelect from './date-select.component';

type ReduxProps = {
  restaurant: Restaurant | undefined;
  account: any;
}

type Props = {
  name: string;
  phone: string;
  email: string;
  donation: boolean;
  paymentMethod: "pin" | "cash" | "ideal"
  nameInvalid: boolean;
  phoneInvalid: boolean;
  emailInvalid: boolean;
  deliveryTime: moment.Moment
  timeslots: Array<Timeslot>
  updateState: (state: {}) => void; 
  onDateChangeEvent: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
} & ReduxProps;

const TakeoutContentDialog: React.FC<Props> = (props) => {
  const {
    restaurant,
    account,
    updateState,
  } = props;

  const _prefillAddressFromAccount = () => {
    updateState({
      name: account.firstName + ' ' + account.lastName,
      phone: account.phoneNumber,
      streetname: account.street,
      housenumber: account.houseNumber,
      city: account.city,
      zipcode: account.postalCode,
      email: account.email,
    });
  }

  const _onDeliveryTimeChanged = (selectedTimeslot: Timeslot) => {
    const { deliveryTime } = props;
    deliveryTime.hour(selectedTimeslot.when.hour());
    deliveryTime.minute(selectedTimeslot.when.minute());
    deliveryTime.second(0);
    updateState({ deliveryTime: moment(deliveryTime) });
  };
  
  return (
    <>
      <ModalDialogTitle className="mt-20">Afhalen</ModalDialogTitle>
      <ModalDialogDescription className="pay_dialog_description">
        Vul onderstaande gegevens in
        {account.loggedIn ? (
          ''
        ) : (
          <span className="register-tip">
            Tip: <a href="/login">Maak een account aan</a> om dit de volgende
            keer over te slaan!
          </span>
        )}
      </ModalDialogDescription>
      {account.loggedIn && (
        <Button
          className="mt-20 mb-20"
          text="Gebruik account gegevens"
          onClick={_prefillAddressFromAccount}
        />
      )}
      <InputGroup>
        <Label>Contactgegevens</Label>
        <Input
          className={`full-width ${props.nameInvalid ? 'invalid' : ''}`}
          placeholder="Naam"
          value={props.name}
          onChange={(event) =>
            updateState({ name: event.target.value, nameInvalid: false })
          }
        />
        <Input
          className={`full-width ${props.phoneInvalid ? 'invalid' : ''}`}
          placeholder="Telefoonnummer"
          inputMode="tel"
          value={props.phone}
          onChange={(event) =>
            updateState({ phone: event.target.value, phoneInvalid: false })
          }
        />
        <Input
          className={`full-width ${props.emailInvalid ? 'invalid' : ''}`}
          placeholder="E-mail adres"
          inputMode="email"
          value={props.email}
          onChange={(event) =>
            updateState({ email: event.target.value, emailInvalid: false })
          }
        />
      </InputGroup>

      <DateSelect deliveryTime={props.deliveryTime} onChange={props.onDateChangeEvent} />
      <TimeslotSelector
        slots={props.timeslots}
        onSelectionChange={_onDeliveryTimeChanged}
      />

      <PaymentMethodSelector
        config={{
          cashEnabled: !!restaurant?.cashEnabled,
          pinEnabled: !!restaurant?.pinEnabled,
          iDealEnabled: !!restaurant?.iDealEnabled,
          iDealCost: restaurant?.iDealCost || '0.00',
        }}
        onSelectionChange={(newPaymentMethod) => updateState({ paymentMethod: newPaymentMethod })}
        currentValue={props.paymentMethod}
      />

      {restaurant?.enableDonations === '1' && (
        <Checkbox
          className="donation-checkbox"
          textComponent={
            <span>
              Ja, ik doneer graag 1 euro voor{' '}
              <a
                href="https://www.metjehart.nl/"
                target="_blank"
                rel="noopener noreferrer">
                stichting Met je Hart
              </a>
            </span>
          }
          checked={props.donation}
          onChecked={(checked) => updateState({ donation: checked })}
        />
      )}

      <Button
        text="Bevestig gegevens"
        className="mt-40"
        onClick={() =>
          updateState({ showConfirmOrder: true, showTakeoutContent: false })
        }
      />
      <AltAction
        text="Terug"
        className="mt-20"
        icon="close"
        onClick={() => updateState({ showTakeoutContent: false })}
      />
    </>
  );
}

const mapStateToProps = (state: AppState) => {
  return {
    account: state.account,
    restaurant: state.restaurants.restaurants.find(
      (x) => x.id === state.session.session?.restaurantId,
    ),
  };
};

export default connect(mapStateToProps, {})(TakeoutContentDialog);
import TableReservation from '../../../models/tableReservation';

export default class GetReservationsResponse {
  reservations: Array<TableReservation>;

  constructor(json: any) {
    if (json && Array.isArray(json)) {
      this.reservations = json.map((x: any) => new TableReservation(x));
    } else {
      this.reservations = [];
    }
  }
}

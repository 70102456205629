import React, {Component} from 'react';
import CategorySelect from '../../components/category-select/category-select.component';
import RestaurantMenuItemView from '../../components/restaurant-menu-item/restaurant.menu.item.component';
import ReactVisibilitySensor from 'react-visibility-sensor';
import CategoryHeader from '../../components/category-header/category.header.component';
import RestaurantMenuItem from '../../models/restaurantMenuItem';
import MenuCategoryHeader from './menu/category-header/menu-category-header.component';

type Props = {
  account: any;
  addMenuFavorite: (token: string, menuItemId: string) => void;
  menu: Array<RestaurantMenuItem>;
  menuFavorites: any;
  removeMenuFavorite: (token: string, menuItemId: string) => void;
  onMenuItemClicked: (menuItemId: string) => void;
  onMenuItemFavoriteClicked: (menuItemId: string) => void;
};

type State = {
  categorySelectedIndex: number;
};

export class RestaurantMenu extends Component<Props, State> {
  categorySelect = React.createRef<CategorySelect>();
  categoryRefs: Array<React.RefObject<HTMLDivElement>>;

  state = {
    categorySelectedIndex: 0,
  };

  constructor(props: Props) {
    super(props);
    this.categoryRefs = props.menu.map((x) => React.createRef());
  }

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.menu.length !== this.props.menu.length) {
      this.categoryRefs = this.props.menu.map((x) => React.createRef());
      this.setState({categorySelectedIndex: this.state.categorySelectedIndex});
    }
  };

  handleCategoryVisibilityChange = (isVisible: boolean, index: number) => {
    // We double-check the index to make sure the right tab is selected. Otherwise
    // we might skip the selected tab to the next if the category is very small.
    if (isVisible && this.state.categorySelectedIndex === index) {
      this.categorySelect.current?.setSelected(index);
    }
  };

  onCategoryChanged = (index: number) => {
    this.setState({categorySelectedIndex: index});
    const selected = this.categoryRefs[index].current;

    if (selected && selected instanceof HTMLElement) {
      window.scrollTo(0, selected.offsetTop - 113);
    }
  };

  _renderCategoryHeader = (category: any, index: number) => {
    return <MenuCategoryHeader category={category} />;
  };

  _renderCategorySection = (category: any, categoryIndex: number) => {
    const visibilityTrigger = window.innerHeight / 2;
    const key = 'visibilitysensor_' + category.category.name;
    return (
      <ReactVisibilitySensor
        key={key}
        onChange={(isVisible) =>
          this.handleCategoryVisibilityChange(isVisible, categoryIndex)
        }
        partialVisibility={true}
        offset={{bottom: visibilityTrigger, top: visibilityTrigger}}>
        <div className="full-width" ref={this.categoryRefs[categoryIndex]}>
          {this._renderCategoryHeader(category.category, categoryIndex)}
          {category.menuItems.map((x: any, index: number) =>
            this._renderMenuItem(x, index),
          )}
        </div>
      </ReactVisibilitySensor>
    );
  };

  _renderMenuItem = (menuItem: any, index: number) => {
    const allergies = menuItem.allergies ? menuItem.allergies.split(',') : [];

    let image = null;
    if (menuItem.imageSquare && menuItem.imageSquare.length > 0) {
      image = menuItem.imageSquare;
    } else if (menuItem.image && menuItem.image.length > 0) {
      image = menuItem.image;
    }

    return (
      <RestaurantMenuItemView
        key={index}
        allergies={allergies}
        image={image}
        menuItem={menuItem}
        onClick={() => this.props.onMenuItemClicked(menuItem.id)}
      />
    );
  };

  render() {
    const {menu} = this.props;
    const items = menu.map((category, index) =>
      this._renderCategorySection(category, index),
    );
    return (
      <>
        <CategorySelect
          ref={this.categorySelect}
          onChange={this.onCategoryChanged}
          categories={menu.map((x) => x.category)}
        />
        {items}
      </>
    );
  }
}

export default RestaurantMenu;

import moment, {Moment} from 'moment';
import {OpeningTime} from '../services/timeslotgenerator/timeslotgenerator';

export type MealTime = {mealType: string; open: string; closed: string};
export type MealTimes = {[key: string]: MealTime};

export default class Restaurant {
  active: boolean;
  bannerImage: string;
  bannerImage2: string;
  bannerImage3: string;
  cashEnabled: boolean;
  city: string;
  closedOn: string;
  companyPhoneNumber: string;
  crowded: string;
  deliveryCost: string;
  description: string;
  email: string;
  enableDonations: string;
  enableTipping: string;
  groupId: string;
  iDealCost: string;
  iDealEnabled: boolean;
  id: string;
  labels: {[key: string]: string};
  latitude: string;
  longitude: string;
  mealTimes: MealTimes;
  mollieEnabled: boolean;
  mollieValid: boolean;
  numberOfTables: string;
  openingTimes: {
    [key: string]: {
      open: string;
      closed: string;
      kitchenOpen: string;
      kitchenClosed: string;
    };
  };
  pinEnabled: boolean;
  postalCode: string;
  pricing: string;
  profileImage: string;
  reservationEnabled: string;
  reservationCapacityDelivery: number;
  reservationCapacityTakeout: number;
  reservationCapacityToGo: number;
  reservationTimeslotsDelivery: string;
  reservationTimeslotsTakeout: string;
  reservationTimeslotsToGo: string;
  street: string;
  streetNumber: string;
  tableLabels: string;
  terms: string;
  title: string;
  viewOnly: boolean;
  whatsappPhoneNumber: string;
  exceptionClosedOn: string[];
  exceptionOpenOn: string[];
  extraCostMinAmount: number;
  minAmount: number;

  constructor(json: any) {
    this.active = json.active === '1';
    this.bannerImage = json.bannerImage;
    this.bannerImage2 = json.bannerImage2;
    this.bannerImage3 = json.bannerImage3;
    this.cashEnabled = json.cashEnabled === '1';
    this.city = json.city;
    this.closedOn = json.closedOn;
    this.companyPhoneNumber = json.companyPhoneNumber;
    this.crowded = json.crowded;
    this.deliveryCost = json.deliveryCost;
    this.description = json.description;
    this.email = json.email;
    this.enableDonations = json.enableDonations;
    this.enableTipping = json.enableTipping;
    this.groupId = json.groupId;
    this.iDealCost = json.iDealCost;
    this.id = json.id;
    this.labels = json.labels;
    this.latitude = json.latitude;
    this.longitude = json.longitude;
    this.mealTimes = json.mealTimes;
    this.mollieEnabled = json.mollieEnabled === '1';
    this.mollieValid = json.mollieValid === '1';
    this.iDealEnabled = this.mollieEnabled && this.mollieValid;
    this.numberOfTables = json.numberOfTables;
    this.openingTimes = json.openingTimes;
    this.pinEnabled = json.pinEnabled === '1';
    this.postalCode = json.postalCode;
    this.pricing = json.pricing;
    this.profileImage = json.profileImage;
    this.reservationEnabled = json.reservationEnabled;
    this.reservationTimeslotsDelivery = json.reservationTimeslotsDelivery;
    this.reservationTimeslotsTakeout = json.reservationTimeslotsTakeout;
    this.reservationCapacityDelivery = parseInt(
      json.reservationCapacityDelivery,
    );
    this.reservationCapacityTakeout = parseInt(json.reservationCapacityTakeout);
    this.reservationTimeslotsToGo = json.reservationTimeslotsToGo;
    this.reservationCapacityToGo = parseInt(json.reservationCapacityToGo);
    this.street = json.street;
    this.streetNumber = json.streetNumber;
    this.tableLabels = json.tableLabels;
    this.terms = json.terms;
    this.title = json.title;
    this.viewOnly = json.viewOnly === '1';
    this.whatsappPhoneNumber = json.whatsappPhoneNumber;
    this.exceptionClosedOn = json.exceptionClosedOn
      ? json.exceptionClosedOn.split(',')
      : [];
    this.exceptionOpenOn = json.exceptionOpenOn
      ? json.exceptionOpenOn.split(',')
      : [];
    this.extraCostMinAmount = parseInt(json.extraCostMinAmount);
    this.minAmount = parseInt(json.minAmount);
  }

  /**
   * Parsed the passed string into a Moment object
   * @param timeString String to parse, should be formatted HH:mm
   * @param baseMoment Moment to apply this time to, defaults to a new instance. Is not mutated in the process.
   *
   * //TODO: Make it configurable which time to pick, kitchen or restaurant openingtime. Reservations use restaurant, delivery/takeout use kitchen.
   *
   * @returns A Moment object with the passed time string applied.
   */
  private parseTimeString = (
    timeString: string,
    baseMoment: Moment = moment(),
  ): Moment => {
    const splittedTime = timeString.split(':');
    return moment(baseMoment)
      .hour(parseInt(splittedTime[0]))
      .minute(parseInt(splittedTime[1]))
      .second(0);
  };

  getMealTime = (
    type: 'takeout' | 'delivery' | 'to-go',
    baseMoment: Moment = moment(),
  ): Array<OpeningTime> => {
    const inputClone = moment(baseMoment);
    const localized: {[key: string]: string} = {
      maandag: 'monday',
      dinsdag: 'tuesday',
      woensdag: 'wednesday',
      donderdag: 'thursday',
      vrijdag: 'friday',
      zaterdag: 'saturday',
      zondag: 'sunday',
    };

    const raw = this.mealTimes[type];
    const open = this.parseTimeString(raw.open, inputClone);
    const closed = this.parseTimeString(raw.closed, inputClone);
    let retVal = [];
    if (closed.isBefore(open)) {
      closed.add(1, 'day');
      const previous: OpeningTime = {
        from: moment(inputClone).hour(0).minute(0).second(0),
        to: moment(closed).subtract(1, 'day'),
      };
      retVal.push(previous);
    }

    const day = inputClone.format('dddd').toLowerCase();
    const translatedDay = localized[day];
    if (!this.closedOn.includes(translatedDay)) {
      // Restaurant is not closed today, add todays result
      retVal.push({
        from: open,
        to: closed,
      });
    }

    return retVal;
  };

  /**
   * Generates the openingtimes for the passed date
   * @param queryDate Date to find the openingtimes for
   * @param propertyToUse Use the kitchen openingtimes or restaurant openingtimes
   * @param maxDepth How many days in the past to include in this search, defaults to 1, do not pass, used for recursion.
   * @param currentDepth How deep we are currently backtracking, defaults to 0, do not pass, used for recursion.
   */
  getOpeningTimesForDate = (
    queryDate: Moment,
    propertyToUse: 'kitchen' | 'restaurant',
    maxDepth: number = 1,
    currentDepth: number = 0,
  ): Array<OpeningTime> => {
    const retVal: Array<OpeningTime> = [];
    const inputClone = moment(queryDate);

    // Check if openingstime for the the day before carried over into today
    if (currentDepth < maxDepth) {
      const recursiveResult = this.getOpeningTimesForDate(
        moment(inputClone).subtract(1, 'day'),
        propertyToUse,
        maxDepth,
        currentDepth + 1,
      );
      if (recursiveResult.length > 0) {
        recursiveResult[0].from = inputClone.hour(0).minute(0).second(0);
        retVal.push(recursiveResult[0]);
      }
    }

    const localized: {[key: string]: string} = {
      maandag: 'monday',
      dinsdag: 'tuesday',
      woensdag: 'wednesday',
      donderdag: 'thursday',
      vrijdag: 'friday',
      zaterdag: 'saturday',
      zondag: 'sunday',
    };

    const day = inputClone.format('dddd').toLowerCase();
    const translatedDay = localized[day];
    if (this.closedOn.includes(translatedDay)) {
      // Restaurant is closed today, return any previous results
      return retVal;
    }

    const openingTimeData = this.openingTimes[translatedDay];

    const openingMoment = this.parseTimeString(
      propertyToUse === 'kitchen'
        ? openingTimeData.kitchenOpen
        : openingTimeData.open,
      inputClone,
    );
    const closingMoment = this.parseTimeString(
      propertyToUse === 'kitchen'
        ? openingTimeData.kitchenClosed
        : openingTimeData.closed,
      inputClone,
    );

    // Account for restaurants who are open longer than midnight
    if (openingMoment.isAfter(closingMoment)) {
      closingMoment.add(1, 'day');
    }

    const openingTime: OpeningTime = {
      from: openingMoment,
      to: closingMoment,
    };

    retVal.push(openingTime);

    return retVal;
  };

  generateSlug() {
    var baseParam = this.title
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    const city = this.city
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    if (!baseParam.endsWith(city)) {
      baseParam += ` ${city}`;
    }
    const slug = baseParam
      .replace(/[^A-Za-z0-9]+/g, '-')
      .replace(/-$/g, '')
      .toLowerCase();
    return slug;
  }
}

import FiltersContainer from '../../components/filters-container/filters.container.component';
import LoadingIndicator from '../../components/loading-indicator/loading.indicator.component';
import RestaurantItem from '../../components/restaurant-item/restaurant.item.component';
import SearchResult from '../../components/search-result/search-result.component';
import SearchBar from '../../components/search-bar/search.bar.component';
import {ReactComponent as ScanIcon} from '../../assets/icons/scan.svg';
import {ReactComponent as ProfileIcon} from '../../assets/icons/profile.svg';
import {ReactComponent as ToTopIcon} from '../../assets/icons/arrow-up.svg';
import {getRestaurants} from '../../redux/actions/restaurants.actions';
import {searchMenuItems} from '../../redux/actions/restaurant.menu.actions';
import Content from '../../components/content/content.component';
import Toolbar from '../../components/toolbar/toolbar.component';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import RestaurantMenuItem from '../../components/restaurant-menu-item/restaurant.menu.item.component';
import NetworkState from '../../redux/network-state';
import {isMobileOrTablet} from '../../utils/isMobileOrTablet';
import FloatingButton from '../../components/ui-base/floating-button/floating.button.component';
import BackToTop from '../../components/back-to-top/back-to-top';

import './restaurants-screen.scss';
import ReactVisibilitySensor from 'react-visibility-sensor';
import {addFilter} from '../../redux/search/search.thunks';
import {cancelSession, joinSession} from '../../redux/session/session.thunks';
import api from '../../services/api';
import {FilterType, QueryType, SortFilter} from '../../redux/search/filter';
import RestaurantsQuickActions from '../../components/restaurants-quickactions/restaurants-quickactions.component';
import DesktopHeader from './desktop-header/desktop-header';
import FairSpecialHeader from './fair-special-header/fair-special-header';
import MobileHeader from './mobile-header/mobile-header';

class Restaurants extends Component {
  menuItems = [
    {
      id: 'profile',
      icon: ProfileIcon,
    },
    {
      id: 'scan',
      icon: ScanIcon,
      className: isMobileOrTablet() ? '' : 'dont-show-on-desktop',
    },
  ];

  state = {
    showToTop: false,
  };

  componentDidMount() {
    const {location, match} = this.props;

    if (match.params.city) {
      this.props.addFilter(
        FilterType.Location,
        match.params.city,
        match.params.city,
      );
    }

    if (location.state && location.state.scrollTop) {
      setTimeout(() => {
        window.scroll(0, location.state.scrollTop);
      }, 100);
    }
  }

  _getRestaurants = () => {
    const {restaurants, restaurantFavorites, currentSort} = this.props;

    var sortFunction;

    if (currentSort === SortFilter.Category) {
      sortFunction = (a, b) => {
        return (a.category || '').localeCompare(b.category || '');
      };
    } else if (currentSort === SortFilter.Location) {
      sortFunction = (a, b) => {
        return (a.city || '').localeCompare(b.city || '');
      };
    } else if (currentSort === SortFilter.AlphabeticDescending) {
      sortFunction = (a, b) => {
        return (b.title || '').localeCompare(a.title || '');
      };
    } else {
      sortFunction = (a, b) => {
        return (a.title || '').localeCompare(b.title || '');
      };
    }

    let filteredRestaurants = [...restaurants];
    const labelFilters = this.props.simpleFilters.filter(
      (x) => x.type === FilterType.Label,
    );
    if (labelFilters) {
      filteredRestaurants = filteredRestaurants.filter((rest) =>
        labelFilters.every((labelFilter) => rest.labels[labelFilter.value]),
      );
    }
    const reservationFilter = this.props.simpleFilters.find(
      (x) => x.type === FilterType.ReservationEnabled,
    );
    if (reservationFilter) {
      filteredRestaurants = filteredRestaurants.filter(
        (rest) => rest.reservationEnabled === '1',
      );
    }

    const items = filteredRestaurants.reduce(
      (previous, current) => {
        if (restaurantFavorites[current.id]) {
          previous.favorited.push(current);
        } else {
          previous.items.push(current);
        }

        return previous;
      },
      {favorited: [], items: []},
    );

    return [
      ...items.favorited.sort(sortFunction),
      ...items.items.sort(sortFunction),
    ];
  };

  onMenuItemClicked = (item) => {
    const {account} = this.props;
    if (item.id === 'scan') {
      this.props.history.push('/scanner');
    } else if (item.id === 'profile') {
      if (account.loggedIn) {
        this.props.history.push('/profile');
      } else {
        this.props.history.push('/login');
      }
    }
  };

  onRestaurantMenuItemClicked = async (restaurantId) => {
    const {restaurants} = this.props;
    const restaurant = restaurants.find(
      (restaurant) => restaurant.id === restaurantId,
    );
    this.onRestaurantClicked(restaurant);
  };

  onRestaurantClicked = async (restaurant) => {
    const {currentMealType, account, orders, session} = this.props;
    let canJoin = true;
    if (
      session &&
      session.restaurantId !== restaurant.id &&
      orders.menuItemsQueue.length === 0 &&
      orders.menuItemsOrdered.length === 0
    ) {
      canJoin = await this.props.cancelSession();
    } else if (session && session.status === 'payment-requested') {
      await api.recoverFailedSession(session.id, session.token);
      canJoin = false;
    }
    if (
      currentMealType &&
      (currentMealType.value === 'takeout') |
        (currentMealType.value === 'delivery')
    ) {
      if (canJoin) {
        const userToken = account.loggedIn ? account.token : null;
        await this.props.joinSession(
          restaurant.id,
          'order_from_home',
          '',
          userToken,
        );
      }
    }

    this.props.history.replace('/restaurants', {scrollTop: window.scrollY});
    this.props.history.push(`/restaurant/${restaurant.generateSlug()}`);
  };

  _renderMenuItem = (menuItem, index) => {
    let image = null;
    if (menuItem.imageSquare && menuItem.imageSquare.length > 0) {
      image = menuItem.imageSquare;
    } else if (menuItem.image && menuItem.image.length > 0) {
      image = menuItem.image;
    }

    return (
      <RestaurantMenuItem
        key={index}
        image={image}
        renderStandalone={true}
        onClick={() => this.onRestaurantMenuItemClicked(menuItem.restaurantId)}
        menuItem={menuItem}
      />
    );
  };

  _renderContent = () => {
    const {foundMenuItems, queryFilter, simpleFilters} = this.props;

    let listItems;
    if (queryFilter && queryFilter.queryType === QueryType.Menu) {
      listItems = foundMenuItems.map((menuItem, index) =>
        this._renderMenuItem(menuItem, index),
      );
    } else {
      listItems = this._getRestaurants().map((restaurant) => (
        <RestaurantItem
          key={restaurant.id}
          restaurant={restaurant}
          onClick={this.onRestaurantClicked}
        />
      ));
    }
    return (
      <>
        <ReactVisibilitySensor
          onChange={(isVisible) => this.setState({showToTop: !isVisible})}>
          <div>
            <SearchBar />
            <RestaurantsQuickActions />
          </div>
        </ReactVisibilitySensor>

        <LoadingIndicator isLoading={this.props.loading} />
        {queryFilter && <SearchResult query={queryFilter} />}

        {simpleFilters.length > 0 && (
          <FiltersContainer filters={simpleFilters} />
        )}
        <div className="full-width content">{listItems}</div>
      </>
    );
  };

  render() {
    const {showToTop} = this.state;
    return (
      <>
        <Toolbar
          title="Restaurants"
          showMenuIcon={true}
          items={this.menuItems}
          onItemClicked={this.onMenuItemClicked}
        />
        <Content toolbar={true}>
          {isMobileOrTablet() ? <MobileHeader /> : <DesktopHeader />}
          {this._renderContent()}
        </Content>
        <FloatingButton
          className={'back-to-top' + (showToTop ? ' visible' : '')}
          onClick={() => window.scroll({left: 0, top: 0, behavior: 'smooth'})}>
          <ToTopIcon width={24} height={24} />
        </FloatingButton>
        <BackToTop
          onVisibilityChange={(isVisible) =>
            this.setState({showToTop: !isVisible})
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const queryFilter = state.search.activeFilters.find(
    (x) => x.type === FilterType.Query,
  );
  const simpleFilters = state.search.activeFilters.filter(
    (x) => x.type !== FilterType.Query,
  );
  return {
    restaurants: state.restaurants.restaurants,
    loading: state.restaurants.networkState === NetworkState.LOADING,
    currentSort: state.search.activeFilters.find(
      (x) => x.type === FilterType.Sort,
    ),
    restaurantFavorites: state.account.restaurantFavorites || {},
    foundMenuItems: state.search.foundMenuItems,
    menuFavorites: state.account.menuFavorites || {},
    account: state.account,
    session: state.session.session,
    orders: state.orders,
    simpleFilters: simpleFilters,
    queryFilter: queryFilter,
    currentMealType: state.search.activeFilters.find(
      (x) => x.type === FilterType.Mealtype,
    ),
  };
};

export default connect(mapStateToProps, {
  addFilter,
  getRestaurants,
  searchMenuItems,
  cancelSession,
  joinSession,
})(Restaurants);

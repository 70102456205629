import {ReactComponent as ArrowIcon} from '../../assets/icons/arrow.svg';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import Button from '../ui-base/button/button.component';
import './restaurant.details.style.scss';
import React, {Component} from 'react';
import moment from 'moment';
import PriceRange from '../price-range/price-range.component';

export default class RestaurantDetails extends Component {
  state = {
    showingOpeningTimes: false,
  };

  render() {
    const {visible} = this.props;

    return (
      <TransitionGroup>{visible && this._renderDetails()}</TransitionGroup>
    );
  }

  _renderDetails = () => {
    const {
      restaurant,
      hideDetails,
      occupation,
    } = this.props;

    const {
      description,
      pricing,
      companyPhoneNumber,
      openingTimes,
    } = restaurant;

    const animations = {
      enter: 'enter',
      enterActive: 'enter_active',
      exit: 'exit',
      exitActive: 'exit_active',
    };

    return (
      <CSSTransition timeout={300} classNames={animations}>
        <div className="restaurant_details">
          <div className="restaurant_details_separator mt-20" />
          <span className="restaurant_details_price_range">
            {this._renderPriceRange(pricing)}
          </span>
          <p className="restaurant_details_description">
            {description.split('\n').map((item, index) => (
              <span key={index}>
                {item}
                <br />
              </span>
            ))}
          </p>
          <div className="restaurant_details_separator" />
          <span className="restaurant_details_information mt-20">
            Keuken drukte:{' '}
            <span className="value">{this._getCrowdedDescription()}</span>
          </span>
          <span className="restaurant_details_information">
            Bezetting:{' '}
            <span className="value">{`${occupation.occupants}/${occupation.numberOfTables}`}</span>
          </span>
          <span className="restaurant_details_information">
            Telefoon:{' '}
            <span className="value" onClick={this.makeReservation}>
              {companyPhoneNumber}
            </span>
          </span>
          <div className="restaurant_details_opening_times">
            <span className="restaurant_details_information mb-20">
              Openingstijden:{' '}
            </span>
            {this._renderOpeningTimes(openingTimes)}
          </div>
          {companyPhoneNumber && companyPhoneNumber.length > 0 && (
            <Button
              className="ml-16 mr-16 mb-20"
              text="Bellen"
              onClick={this.makeReservation}
            />
          )}

          {hideDetails && (
            <ArrowIcon
              className="restaurant_details_arrow_up"
              fill="#222D39"
              onClick={hideDetails}
            />
          )}
        </div>
      </CSSTransition>
    );
  };

  makeReservation = () => {
    window.location = `tel:${this.props.restaurant.companyPhoneNumber}`;
  };

  _renderPriceRange = (amount) => {
    return (
      <div className="restaurant_details_price_range_container">
        <PriceRange value={amount} />
      </div>
    );
  };

  _getCurrentWeek = () =>{
      const currentDate = moment();
      const weekStart = currentDate.clone().startOf('isoWeek');
      const days = [];
      for (let i = 0; i <= 6; i++) {
        days.push(moment(weekStart).add(i, 'days').format("YYYY-MM-DD"));
      }
      return days;
  }

  _renderOpeningTimes = () => {
    let times = [];

    const {showingOpeningTimes} = this.state;

    if (!showingOpeningTimes) {
      // show if opened or closed
      if (this._isOpened()) {
        times.push(
          <li className="open" key={1}>
            Geopend
            <ArrowIcon
              className="restaurant_details_arrow_opening_times_down"
              fill="#222D39"
              onClick={this.showOpeningTimes}
            />
          </li>,
        );
      } else {
        times.push(
          <li className="closed" key={1}>
            Gesloten
            <ArrowIcon
              className="restaurant_details_arrow_opening_times_down"
              fill="#222D39"
              onClick={this.toggleOpeningTimes}
            />
          </li>,
        );
      }
    } else {
      const {openingTimes, restaurant} = this.props;
      const localized = {
        monday: 'maandag',
        tuesday: 'dinsdag',
        wednesday: 'woensdag',
        thursday: 'donderdag',
        friday: 'vrijdag',
        saturday: 'zaterdag',
        sunday: 'zondag',
      };
      const days = Object.keys(openingTimes);

      const weekdays=this._getCurrentWeek();

      const currentDate = moment();
      const day = currentDate.format('dddd').toLowerCase();

      times = days.reduce((previous, current, index) => {
        const openingTime = openingTimes[current];
        let closedThisDay = (restaurant.closedOn && restaurant.closedOn.includes(current)) || (restaurant.exceptionClosedOn && restaurant.exceptionClosedOn.includes(weekdays[index]));

        if(restaurant.exceptionOpenOn && restaurant.exceptionOpenOn.includes(weekdays[index])){
          closedThisDay = false;
        }

        const classDay =
          current === day
            ? 'restaurant_details_day today'
            : 'restaurant_details_day';
        const classTime =
          current === day
            ? 'restaurant_details_time today'
            : 'restaurant_details_time';

        previous.push(
          <li key={current}>
            <div className={classDay}>{`${localized[current]}`}</div>
            <span className={classTime}>
              {closedThisDay
                ? 'Gesloten'
                : `${openingTime.open}-${openingTime.closed}`}
            </span>
            {index === days.length - 1 && (
              <ArrowIcon
                className="restaurant_details_arrow_opening_times_down up"
                fill="#222D39"
              />
            )}
          </li>,
        );

        return previous;
      }, []);
      moment.locale('nl');
    }

    return (
      <ul
        className="restaurant_details_openings_times_list"
        onClick={this.toggleOpeningTimes}>
        {times}
      </ul>
    );
  };

  toggleOpeningTimes = () => {
    this.setState({showingOpeningTimes: !this.state.showingOpeningTimes});
  };

  _isOpened = () => {
    const {openingTimes, restaurant} = this.props;

    const daysOfWeek = {
      0: 'monday',
      1: 'tuesday',
      2: 'wednesday',
      3: 'thursday',
      4: 'friday',
      5: 'saturday',
      6: 'sunday',
    };
    const currentDate = moment();
    const currentWeekday = currentDate.weekday();
    const currentDay = daysOfWeek[currentWeekday];

    if(restaurant.exceptionOpenOn && restaurant.exceptionOpenOn.includes(currentDate.format('YYYY-MM-DD')) ){
      return true;
    }

    if ((restaurant.closedOn && restaurant.closedOn.includes(currentDay)) || (restaurant.exceptionClosedOn && restaurant.exceptionClosedOn.includes(currentDate.format('YYYY-MM-DD')))) {
      return false;
    }

    if (!openingTimes) {
      return false;
    }

    const todayTimes = openingTimes[currentDay];
    if (!todayTimes || !todayTimes.open || !todayTimes.closed) {
      return false;
    }

    const {open, closed} = todayTimes;

    const startDate = currentDate.clone();
    startDate.set({
      hour: parseInt(open.split(':')[0], 10),
      minute: parseInt(open.split(':')[1], 10),
      second: 0,
      millisecond: 0,
    });

    const endDate = currentDate.clone();
    endDate.set({
      hour: parseInt(closed.split(':'[0], 10)),
      minute: parseInt(closed.split(':')[1], 10),
      second: 0,
      millisecond: 0,
    });

    if (startDate.isAfter(endDate)) {
      // This restaurant is either misconfigured or open after midnight
      endDate.add(1, 'day');
    }
    return currentDate.isBetween(startDate, endDate);
  };

  _getCrowdedDescription = () => {
    const { restaurant } = this.props;
    const vals = { 1: 'Rustig', 2: 'Gemiddeld', 3: 'Druk' };
    const val = vals[restaurant.crowded];
    return val ? val : 'onbekend';
  };
}

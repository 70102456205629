import _ from 'lodash';
import {
  ADD_MENU_ITEM,
  CLEAR_ORDERS,
  REMOVE_MENU_ITEM,
  SUBMITTED_ORDER,
  UPDATE_ORDERS,
} from '../actiontypes/orders.actiontypes';

const initialState = {menuItemsQueue: [], menuItemsOrdered: []};

const OrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_MENU_ITEM:
      return saveState(addMenuItem(action.payload, state));
    case REMOVE_MENU_ITEM:
      return saveState(removeMenuItem(action.payload, state));
    case UPDATE_ORDERS:
      return saveState(updateOrders(state, action.payload));
    case SUBMITTED_ORDER:
      return saveState(submittedOrder(state));
    case CLEAR_ORDERS:
      return saveState(clearOrders());
    default:
      return state;
  }
};

function addMenuItem(payload, state) {
  let menuItems;
  if (payload.orders) {
    menuItems = payload.orders;
  } else {
    menuItems = [payload];
  }
  const currentMenuItems = state.menuItemsQueue;

  let queue = [...currentMenuItems];
  menuItems.forEach((newItem) => {
    // Check if the item is already in the queue
    const maybeItem = queue.find((item) => menuItemEqual(item, newItem));
    if (maybeItem) {
      // Item is already in queue, increment amount
      maybeItem.amount += 1;
    } else {
      // Item is not in queue, add it
      queue.push(newItem);
    }
  });

  return {...state, menuItemsQueue: queue};
}

function removeMenuItem(payload, state) {
  const queue = state.menuItemsQueue
    .map((item) => {
      if (containsOrderId(item, payload)) {
        if (item.amount === 1) return null;

        return {
          ...item,
          amount: item.amount - 1,
          orderIds: item.orderIds.filter((id) => id !== payload),
        };
      }

      return {...item};
    })
    .filter((item) => !!item);

  return {...state, menuItemsQueue: queue};
}

function updateOrders(state, payload) {
  const queueItems = [];
  const orderedItems = [];

  payload.forEach((item) => {
    let targetArray = item.status === 'queued' ? queueItems : orderedItems;
    const target = targetArray.find((i) => menuItemEqual(item, i));
    if (target) {
      target.orderIds = [...target.orderIds, item.orderId];
      target.amount = target.orderIds.length;
    } else {
      targetArray.push({...item, orderIds: [item.orderId], amount: 1});
    }
  });

  return {
    ...state,
    menuItemsQueue: queueItems,
    menuItemsOrdered: orderedItems,
  };
}

function submittedOrder(state) {
  const currentMenuItemsOrdered = state.menuItemsOrdered;
  let menuItemsQueue = [...state.menuItemsQueue];

  // merge
  const orderedMenuItems = currentMenuItemsOrdered.map((item) => {
    const target = menuItemsQueue.find((i) => menuItemEqual(item, i));
    if (target) {
      menuItemsQueue = menuItemsQueue.filter((i) => !menuItemEqual(i, item));
      return {
        ...item,
        amount: item.amount + target.amount,
        orderIds: [...item.orderIds, ...target.orderIds],
      };
    }

    return {...item};
  });

  // add which were not merged
  menuItemsQueue.forEach((item) => {
    orderedMenuItems.push(item);
  });

  return {...state, menuItemsQueue: [], menuItemsOrdered: orderedMenuItems};
}

function saveState(state) {
  localStorage.setItem('orders', JSON.stringify(state));
  return state;
}

function clearOrders() {
  return {...initialState};
}

function menuItemEqual(a, b) {
  return _.isEqual(a.details, b.details);
}

function containsOrderId(item, orderId) {
  return item && item.orderIds && !!item.orderIds.find((i) => i === orderId);
}

export default OrdersReducer;

import {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import Content from '../../components/content/content.component';
import ToolbarComponent, {
  MenuItem,
} from '../../components/toolbar/toolbar.component';
import ValidateReservationResponse from '../../services/api/responses/validateReservationResponse';
import Bg from '../../utils/bg';
import ReservationEntryTicket from './entry-ticket/entry-ticket.component';
import TicketForm from './form/ticket-form.component';
import './reservation-ticket.styles.scss';
import api from '../../services/api/apiv2';
import LoadingIndicator from '../../components/loading-indicator/loading.indicator.component';
import {useAppThunkDispatch} from '../../redux/reducers/root.reducer';
import {showNotification} from '../../redux/actions/notifications.actions';
import {NotificationType} from '../../models/notification';
import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg';

const useReservationValidation = (
  reservationCode: string | null,
): [ValidateReservationResponse | null, boolean] => {
  const [reservationData, setReservationData] =
    useState<ValidateReservationResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppThunkDispatch();

  useEffect(() => {
    async function actualRetrievalFunction() {
      if (reservationCode != null) {
        setIsLoading(true);
        var errorMessage: string | undefined;
        try {
          const apiResponse = await api.reservations.validate(reservationCode);
          if (apiResponse != null) {
            if (apiResponse.isSuccess) {
              setReservationData(apiResponse.data);
            } else if (apiResponse.message === 'No valid code was given.') {
              errorMessage =
                'Invalide code ingevoerd. Let op dat uw code pas actief wordt op dag van de reservering';
            } else {
              errorMessage = 'Onbekende serverfout';
            }
          }
        } catch (error: unknown) {
          errorMessage = error?.toString();
        } finally {
          if (errorMessage) {
            dispatch(showNotification(errorMessage, NotificationType.ERROR));
          }
        }
      } else {
        setReservationData(null);
      }

      setIsLoading(false);
    }
    actualRetrievalFunction();

    return () => {};
  }, [dispatch, reservationCode]);

  return [reservationData, isLoading];
};

const menuItems: Array<MenuItem> = [
  {
    icon: CloseIcon,
    id: 'close',
  },
];

const ReservationTicketScreen: React.FC = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [code, setCode] = useState<string | null>(null);
  const [reservationData, isLoading] = useReservationValidation(code);

  useEffect(() => {
    if (process.env.REACT_APP_HAP_STAP !== 'true') {
      history.replace('/restaurants');
    }
  }, [history]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const queryCode = query.get('code');
    setCode(queryCode);
    return () => {};
  }, [location.search]);

  var componentToRender;
  var background;
  if (reservationData !== null && code !== null) {
    componentToRender = (
      <ReservationEntryTicket validationData={reservationData} code={code} />
    );
    background = 'bg-white';
  } else {
    componentToRender = (
      <TicketForm onCodeChanged={(code: string) => setCode(code)} />
    );
    background = 'bg-white';
  }

  const onItemClicked = (item: MenuItem) => {
    if (item.id === 'close') {
      history.replace('/restaurants');
    }
  };

  return (
    <>
      <Content className="full-width reservation-ticket-screen">
        <Bg bg={background} />
        <ToolbarComponent
          title="Reservering"
          items={menuItems}
          onItemClicked={onItemClicked}
        />
        {componentToRender}
        <LoadingIndicator isLoading={isLoading} />
      </Content>
    </>
  );
};

export default ReservationTicketScreen;

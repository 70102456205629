import classNames from 'classnames';
import {useState} from 'react';
import AltAction from '../alt-action/alt.action.component';
import {
  Button,
  Input,
  InputGroup,
  Label,
  ModalDialog,
  ModalDialogActions,
  ModalDialogDescription,
  ModalDialogTitle,
} from '../ui-base';
import {ActionsOrientation} from '../ui-base/modal-dialog-actions/modal-dialog-actions.component';

type Props = {
  title: string;
  description: string;
  inputLabel: string;
  inputPlaceholder: string;
  confirmButtonText?: string;
  confirmButtonClass?: string;
  cancelButtonText?: string;
  onConfirm: (enteredValue: string) => void;
  onCancel: () => void;
};

const TextInputDialog: React.FC<Props> = ({
  title,
  description,
  inputLabel,
  inputPlaceholder,
  confirmButtonText = 'Ok',
  cancelButtonText = 'Terug',
  confirmButtonClass,
  onConfirm,
  onCancel,
}) => {
  const [currentValue, setCurrentValue] = useState<string>('');

  return (
    <ModalDialog>
      <ModalDialogTitle>{title}</ModalDialogTitle>
      <ModalDialogDescription>{description}</ModalDialogDescription>
      <InputGroup>
        <Label>{inputLabel}</Label>
        <Input
          placeholder={inputPlaceholder}
          onChange={(event) => setCurrentValue(event.target.value)}
        />
      </InputGroup>
      <ModalDialogActions orientation={ActionsOrientation.Vertical}>
        <Button
          text={confirmButtonText}
          className={classNames('mt-40', confirmButtonClass)}
          onClick={() => onConfirm(currentValue)}
          noIcon
          centerText
        />
        <AltAction
          text={cancelButtonText}
          className="mt-20"
          onClick={onCancel}
        />
      </ModalDialogActions>
    </ModalDialog>
  );
};

export default TextInputDialog;

import AspectRatioContainer from '../../components/aspect-ratio-container/aspect-ratio-container.component';
import LoadingIndicator from '../../components/loading-indicator/loading.indicator.component';
import placeholder_small from '../../assets/images/placeholder_small.png';
import AltAction from '../../components/alt-action/alt.action.component';
import {ReactComponent as CloseIcon} from '../../assets/icons/close.svg';
import {ReactComponent as InfoIcon} from '../../assets/icons/info.svg';
import {ReactComponent as InfoFilledIcon} from '../../assets/icons/info-filled.svg';
import {ReactComponent as PlusIcon} from '../../assets/icons/plus.svg';
import Allergies from '../../components/allergies/allergies.component';
import imageUrl from '../../utils/scalar';
import {ChangeEvent, Component} from 'react';
import './add.dish.dialog.style.scss';
import api from '../../services/api/apiv2';
import OrderItemAmount from '../../components/order-item-amount/order-item-amount';
import {connect} from 'react-redux';
import moment from 'moment';
import SideDishes, {
  SideDishSelectable,
} from './side-dishes/side.dishes.dialog.component';
import SessionType from '../../models/session-type';
import {AppState} from '../../redux/reducers/root.reducer';
import Restaurant, {MealTime} from '../../models/restaurant';
import Session from '../../models/session';
import SideDish, {SideDishOption} from '../../models/sidedish';
import {MenuItemFavoriteButton} from '../../components/menu-item-favorite-button/menu-item-favorite-button.component';
import Formatters from '../../utils/formatters';
import DOMPurify from 'dompurify';
import {
  TextArea,
  Button,
  InputGroup,
  Label,
  ModalDialog,
  ModalDialogTitle,
  ModalDialogDescription,
} from '../../components/ui-base';

type InputProps = {
  restaurantId: string;
  menuItem: any;
  item: any; // TODO: This is a duplicate of menuItem, merge them and remove either
  image: any;
  onCancel: () => void;
  onComplete: (
    item: any,
    sideDishSelections: string,
    comment: string,
    amount: number,
  ) => void;
};

type ReduxProps = {
  restaurant: Restaurant | undefined;
  session: Session | null;
  viewOnly: boolean;
};

type Props = {} & InputProps & ReduxProps;

type State = {
  loading: boolean;
  showExtraSection: boolean;
  selectedSideDish: {[key: string]: any};
  sideDishes: Array<SideDish>;
  amount: number;
  comment: string;
};

export class AddDishDialog extends Component<Props, State> {
  mealTimeCategories: {[key: string]: string} = {
    breakfast: 'Ontbijt',
    dinner: 'Diner',
    lunch: 'Lunch',
    snack: 'Snacks',
    takeout: 'Afhalen',
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: true,
      sideDishes: [],
      selectedSideDish: {},
      showExtraSection: false,
      comment: '',
      amount: 1,
    };
  }

  componentDidMount() {
    this._fetchSideDishes();
  }

  _fetchSideDishes = async () => {
    const {restaurantId, menuItem} = this.props;
    const {id} = menuItem;
    const response = await api.menu.getSideDishes(restaurantId, id);
    if (response.isSuccess) {
      const sideDishes = response.data.sideDishes;

      const selectedSideDish: {[key: string]: string} = {};
      sideDishes.forEach((sideDish) => {
        const options = sideDish.items;
        if (options && options.length > 0) {
          selectedSideDish[sideDish.key] = `${options[0].id}`;
        }
      });

      this.setState({
        loading: false,
        sideDishes: sideDishes,
        selectedSideDish: selectedSideDish,
      });
    } else {
      this.setState({loading: false});
    }
  };

  _canOrderDish = () => {
    const {menuItem, session} = this.props;
    const mealTypes = menuItem.mealTypes.split(',');

    // show order button when product is available for delivery/takeout
    if (
      (mealTypes.includes('takeout') ||
        mealTypes.includes('delivery') ||
        mealTypes.includes('to-go')) &&
      session?.sessionType !== SessionType.RESTAURANT
    ) {
      return true;
    }

    // hide order button when delivery/takeout is not available and user is not at restaurant
    if (
      !mealTypes.includes('takeout') &&
      !mealTypes.includes('delivery') &&
      !mealTypes.includes('to-go') &&
      session?.sessionType !== SessionType.RESTAURANT
    ) {
      return false;
    }

    // Show times when the user can order this item
    const now = moment();
    const availableMealtimes = this._getAvailableMealTimes();
    return availableMealtimes.some((timeframe) => {
      const dishStart = moment(timeframe.open, 'HH:mm');
      const dishEnd = moment(timeframe.closed, 'HH:mm');

      // If the endtime is smaller than starttime, it's the next day, for example: 15:00 - 01:00
      if (dishEnd < dishStart) {
        if (now < dishEnd) {
          return true;
        }
        return now.isSameOrAfter(dishStart, 'minute');
      }
      return (
        now.isSameOrAfter(dishStart, 'minute') &&
        now.isSameOrBefore(dishEnd, 'minute')
      );
    });
  };

  _getAvailableMealTimes = (): Array<MealTime> => {
    const {menuItem, restaurant} = this.props;
    const dishMealTypes = menuItem.mealTypes
      .split(',')
      .filter((x: string) => x);

    if (dishMealTypes.length > 0) {
      return dishMealTypes.map((mealType: string) => ({
        mealType,
        ...restaurant?.mealTimes[mealType],
      }));
    } else {
      return [];
    }
  };

  complete = () => {
    const {item, onComplete} = this.props;
    const {selectedSideDish, amount, comment} = this.state;
    const sideDishSelections = Object.keys(selectedSideDish)
      .map((key) => {
        return selectedSideDish[key];
      })
      .filter((x) => x !== '')
      .join(',');

    onComplete(item, sideDishSelections, comment, amount);
  };

  handleSideDishChange = (sideDishes: Array<SideDishSelectable>) => {
    let output: {[key: string]: string} = {};
    sideDishes.forEach((sideDish) => {
      output[sideDish.key] = sideDish.selections.map((x) => x.id).join(',');
    });
    this.setState({selectedSideDish: output});
  };

  onCommentChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({comment: event.target.value});
  };

  onAmountChange = (shouldIncrement: boolean) => {
    const {amount} = this.state;
    const incrementAmount = shouldIncrement ? 1 : -1;
    this.setState({amount: amount + incrementAmount});
  };

  onInfoClick = () => {
    const {showExtraSection} = this.state;
    this.setState({showExtraSection: !showExtraSection});
  };

  _getImage = (image: string, fallback: string) => {
    if (image && image.length > 0) {
      return imageUrl(image);
    }

    return fallback;
  };

  _renderAvailableMealTimes = () => {
    const availableMealtimes = this._getAvailableMealTimes();

    return (
      <>
        <h3 className="add_dish_dialog_mealtimes_text">
          Dit gerecht is enkel in het restaurant te bestellen:
        </h3>
        {availableMealtimes.map((timeFrame) => (
          <p className="add_dish_dialog_mealtimes">
            {this.mealTimeCategories[timeFrame.mealType]}: {timeFrame.open} -{' '}
            {timeFrame.closed}
          </p>
        ))}
      </>
    );
  };

  _renderSideDishes = () => {
    const {sideDishes} = this.state;
    return (
      <SideDishes
        sideDishes={sideDishes}
        onSideDishesChanged={this.handleSideDishChange}
      />
    );
  };

  getSelectedSideDishOptions = (
    sideDishes: Array<SideDish>,
    selectedIds: {[key: string]: any},
  ): Array<SideDishOption> => {
    const allOptions = sideDishes.flatMap((x) => x.items);
    const result = Object.values(selectedIds)
      .flatMap((x) => x.split(','))
      .filter((x) => x !== '');
    return result.map((id) => allOptions.find((x) => x.id === id)!);
  };

  _renderContent = () => {
    const {onCancel, menuItem, viewOnly} = this.props;
    const {selectedSideDish, sideDishes, amount, comment} = this.state;
    const canOrder = this._canOrderDish() && !viewOnly;

    const selectedOptions = this.getSelectedSideDishOptions(
      sideDishes,
      selectedSideDish,
    );
    const totalPriceSideDishes = selectedOptions
      .map((x) => x.price)
      .reduce((a, b) => a + b, 0);
    const menuItemPrice =
      menuItem.actionActive === '1' ? menuItem.actionPrice : menuItem.price;
    const total = (parseInt(menuItemPrice) + totalPriceSideDishes) * amount;

    return (
      <>
        {sideDishes && this._renderSideDishes()}
        {!viewOnly && (
          <InputGroup>
            <Label>Aantal</Label>
            <OrderItemAmount amount={amount} onChange={this.onAmountChange} />
          </InputGroup>
        )}

        {!viewOnly && (
          <InputGroup>
            <Label>Opmerking</Label>
            <TextArea
              rows={2}
              maxLength={150}
              className="mt-8 full-width textarea"
              value={comment}
              onChange={this.onCommentChange}
            />
          </InputGroup>
        )}

        {canOrder && (
          <Button
            text="Toevoegen"
            textOnEnd={Formatters.formatCurrency(total)}
            icon={<PlusIcon className="icon" fill="#ffffff" />}
            className="mt-20 small-icon"
            onClick={this.complete}
          />
        )}
        {!canOrder && !viewOnly && this._renderAvailableMealTimes()}
        <AltAction
          text={viewOnly ? 'Sluiten' : 'Annuleren'}
          className="mt-20"
          icon="close"
          onClick={onCancel}
        />
      </>
    );
  };

  render() {
    const {menuItem, image, onCancel} = this.props;
    const {
      allergies,
      title,
      description,
      extraDescription,
      subTitle,
      ingredients,
    } = menuItem;
    const {showExtraSection} = this.state;

    const parsedAllergies = allergies ? allergies.split(',') : [];

    return (
      <ModalDialog>
        <div className="add_dish_dialog_close" onClick={onCancel}>
          <CloseIcon className="add_dish_dialog_close_icon" />
        </div>
        <AspectRatioContainer ratio="56.6">
          <img
            src={this._getImage(image, placeholder_small)}
            className="full-image add_dish_dialog_image cover"
            alt="header"
          />
        </AspectRatioContainer>
        <div className="add_dish_dialog_row">
          <ModalDialogTitle className="add_dish_dialog_title">
            {title}
          </ModalDialogTitle>
          <MenuItemFavoriteButton menuItem={menuItem} />
        </div>

        {subTitle && (
          <ModalDialogDescription className="add_dish_dialog_subtitle">
            {subTitle}
          </ModalDialogDescription>
        )}

        <div className="add_dish_dialog_description_container">
          <div
            className="add_dish_dialog_description"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(description),
            }}></div>
          {(extraDescription || ingredients) && !showExtraSection && (
            <InfoIcon onClick={this.onInfoClick} />
          )}
          {(extraDescription || ingredients) && showExtraSection && (
            <InfoFilledIcon onClick={this.onInfoClick} />
          )}
        </div>
        {showExtraSection && (
          <div>
            {extraDescription && (
              <h2 className="add_dish_dialog_description">
                {extraDescription}
              </h2>
            )}
            {ingredients && (
              <h3 className="add_dish_dialog_description">
                {ingredients.split('\n').join(' • ')}
              </h3>
            )}
          </div>
        )}

        <Allergies allergies={parsedAllergies} />

        {!this.state.loading && this._renderContent()}
        <LoadingIndicator isLoading={this.state.loading} />
      </ModalDialog>
    );
  }
}

const mapStateToProps = (state: AppState, ownProps: InputProps): ReduxProps => {
  const restaurant = state.restaurants.restaurants.find(
    (x) => x.id === ownProps.restaurantId,
  );
  return {
    restaurant,
    session: state.session.session,
    viewOnly: restaurant ? restaurant.viewOnly : true,
  };
};

export default connect(mapStateToProps, {})(AddDishDialog);

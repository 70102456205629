import {ReactComponent as ArrowIcon} from '../../assets/icons/arrow.svg';
import React from 'react';
import './drawer.menu.item.style.scss';
import {primaryColor} from '../../utils/constants';

type Props = {
  item: {
    name: string;
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    link: string;
    iconWidth?: number;
    iconHeight?: number;
  };
  onClick: () => void;
};

const DrawerMenuItem: React.FC<Props> = ({item, onClick}) => {
  const {name, icon, iconWidth, iconHeight} = item;
  const Icon = icon;
  const width = iconWidth || 26;
  const height = iconHeight || 26;
  return (
    <button className="drawer_menu_item" onClick={onClick}>
      <div style={{width: 38}}>
        <Icon
          className="icon"
          width={width}
          height={height}
          fill={primaryColor}
        />
      </div>
      <h1 className="title">{name}</h1>

      <ArrowIcon fill={primaryColor} className="drawer_menu_item_arrow" />
    </button>
  );
};

export default DrawerMenuItem;

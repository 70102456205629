import RestaurantEatDrinkComponent from '../../components/restaurant-eat-drink/restaurant.eat.drink.component';
import RestaurantDetails from '../../components/restaurant-details/restaurant.details.component';
import RestaurantHeader from '../../components/restaurant-header/restaurant.header.component';
import FloatingButton from '../../components/ui-base/floating-button/floating.button.component';
import AddDishDialog from '../../sections/add-dish-dialog/add.dish.dialog.component';
import MessageDialog from '../../sections/message-dialog/message.dialog.component';
import {showNotification} from '../../redux/actions/notifications.actions';
import {joinSession, removeSession} from '../../redux/session/session.thunks';
import {ReactComponent as StoreIcon} from '../../assets/icons/store.svg';
import {ReactComponent as ToTopIcon} from '../../assets/icons/arrow-up.svg';
import {
  getRestaurant,
  getRestaurants,
} from '../../redux/actions/restaurants.actions';
import {getMenu} from '../../redux/actions/restaurant.menu.actions';
import Content from '../../components/content/content.component';
import {addMenuItem} from '../../redux/actions/orders.actions';
import React, {Component} from 'react';
import api from '../../services/api';
import {connect} from 'react-redux';
import Bg from '../../utils/bg';
import moment from 'moment';
import queryString from 'query-string';
import SessionType from '../../models/session-type';
import RestaurantMenu from './restaurant-menu';
import ReservationButton from './reservation-button/reservation-button';
import ReservationDialog from './reservation-dialog/reservation-dialog.new';
import { FilterType} from '../../redux/search/filter';
import {Helmet} from 'react-helmet';
import ReactVisibilitySensor from 'react-visibility-sensor';

import './restaurant-screen.scss';
import UserGreetingDialog from './user-greeting/user-greeting.dialog';

class Restaurant extends Component {
  mealTimeCategories = {
    breakfast: 'Ontbijt',
    dinner: 'Diner',
    lunch: 'Lunch',
    snack: 'Snacks',
    takeout: 'Afhalen',
  };

  state = {
    foodSelected: true,
    detailsVisible: false,
    showAddDishDialog: false,
    showMessageDialog: false,
    showReservationDialog: false,
    showTriageDialog: false,
    showToTop: false,
    messageTitle: '',
    messageDescription: '',
    targetMenuItem: null,
  };

  eatDrinkComponent = React.createRef();

  componentDidMount() {
    const {
      location,
      menu,
      restaurantId,
      restaurants,
      match,
      restaurant,
      isPreview,
    } = this.props;



    const data = queryString.parse(location.search);
    if (data.token && data.restaurantId === restaurantId) {
      this.setState({
        showTriageDialog: false,
        scanData: {
          token: data.token,
          restaurantId: data.restaurantId,
          tableNumber: data.tableNumber,
          isOber: data.directPay === '0'
        },
      });
      if (!this.state.showTriageDialog) {
        this.joinSession({
          token: data.token,
          restaurantId: data.restaurantId,
          tableNumber: data.tableNumber,
          isOber: data.directPay === '0'
        });
      }
    }

    const {session} = this.props;
    if (session && session.paymentStatus === 'ideal') {
      this.props.history.push('/payment-result');
    }

    const slug = match.params.slug;
    this.props.getRestaurant(slug);
    if (restaurants.length === 0) {
      if (isPreview) {
        this.props.getRestaurant(slug);
      } else {
        this.props.getRestaurants();
      }
    }

    if (!menu && restaurantId) {
      this.props.getMenu(restaurantId, isPreview);
    }

    if (match.params.menuitemid) {
      this.setState({targetMenuItem: this.props.match.params.menuitemid});
    }

    if (window) {
      window.scrollTo(0, 0);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {menu, location, restaurantId} = this.props;
    const {targetMenuItem, showAddDishDialog, showReservationDialog} = this.state;
    if (menu && targetMenuItem) {
      this.setState({targetMenuItem: null});

      this.fetchTargetMenuItem(targetMenuItem);

      this.onMenuItemClicked(targetMenuItem, true);
    }
    if (!menu && !prevProps.restaurantId && restaurantId) {
      const data = queryString.parse(location.search);
      this.props.getMenu(restaurantId, data.preview === 'lwpv');
    }

    const data = queryString.parse(location.search);
    if (
      data.token &&
      data.restaurantId === restaurantId &&
      !prevProps.restaurantId
    ) {
      this.setState({
        showTriageDialog: false,
        scanData: {
          token: data.token,
          restaurantId: data.restaurantId,
          tableNumber: data.tableNumber,
        },
      });
      if (!this.state.showTriageDialog) {
        this.joinSession({
          token: data.token,
          restaurantId: data.restaurantId,
          tableNumber: data.tableNumber,
          isOber: data.directPay === '0'
        });
      }
    }

    // if (!prevProps.hasSession && this.props.hasSession) {
    const {session} = this.props;
    if (session && session.paymentStatus === 'ideal') {
      this.props.history.push('/payment-result');
    }
    //}

    if (showAddDishDialog || showReservationDialog) {
      document.body.parentElement.style.overflow = 'hidden';
    } else {
      document.body.parentElement.style.overflow = 'auto';
    }
  }

  fetchTargetMenuItem = async (id) => {
    const {restaurantId} = this.props;
    const menuItem = await api.getMenuItemDetails(restaurantId, id);
    this.setState({menuItemPreview: menuItem});
  };

  onEatDrinkChanged = (foodSelected) => {
    this.setState({foodSelected, categorySelectedIndex: 0});
    if (this.categorySelect) {
      this.categorySelect.reset();
    }
  };

  onMenuItemClicked = (id, skipCheck) => {
    const {session, hasSession} = this.props;

    if (
      hasSession &&
      !skipCheck &&
      session.sessionType === SessionType.RESTAURANT
    ) {
      if (session.status === 'payment-requested') {
        this.setState({
          showMessageDialog: true,
          messageTitle: 'Let op',
          messageDescription:
            'Er is een betaalverzoek ingediend. Je kunt geen nieuwe bestellingen meer doen. Wilt je toch opnieuw bestellen? Scan dan opnieuw de QR-code op de tafel.',
        });
        return;
      }

      if (
        session.status === 'closed' &&
        session.sessionType === SessionType.RESTAURANT
      ) {
        this.setState({
          showMessageDialog: true,
          messageTitle: 'Let op',
          messageDescription:
            'U kunt geen bestellingen meer plaatsen. Wilt je toch bestellen. Scan dan opnieuw de QR-code op de tafel.',
        });
        return;
      }
    }

    this.setState({showAddDishDialog: true, showAddDishId: id});
  };

  addDish = async (menuItem, sideDishSelections, comment, amount) => {
    let {joinSession, restaurantId, hasSession, session, account, restaurant} =
      this.props;

    const userToken = account.loggedIn ? account.token : null;

    var isNewSession = false;
    const currentMealTimes = this.getCurrentMealtimes();
    if (
      (!hasSession || session.status === 'closed') &&
      this._canMenuItemBeOrderedRemotely(menuItem)
    ) {
      const newSession = await joinSession(
        restaurantId,
        'order_from_home',
        '',
        userToken,
      );
      session = newSession;
      hasSession = true;
      isNewSession = true;
    } else if (
      (!hasSession || session.status === 'closed') &&
      !this._canMenuItemBeOrderedRemotely(menuItem)
    ) {
      const restaurantAvailableForRemote =
        restaurant.reservationTimeslotsToGo !== '0' ||
        restaurant.reservationTimeslotsDelivery !== '0' ||
        restaurant.reservationTimeslotsTakeout !== '0';
      const dishSupportsRemote =
        menuItem.mealTypes.includes('to-go') ||
        menuItem.mealTypes.includes('delivery') ||
        menuItem.mealTypes.includes('takeout');
      if (!dishSupportsRemote) {
        this.props.showNotification(
          'Dit gerecht kan niet besteld worden voor afhalen/bezorgen',
          'error',
        );
      } else if (!restaurantAvailableForRemote) {
        this.props.showNotification(
          'Afhalen/bezorgen is op dit moment niet beschikbaar voor dit restaurant',
          'error',
        );
      }
      return;
    }

    let {token, id} = session;
    let selectedRestaurantId = session.restaurantId;

    if (
      `${restaurantId}` !== `${selectedRestaurantId}` &&
      session.sessionType === SessionType.FROM_HOME
    ) {
      this.props.showNotification(
        'U heeft op dit moment gerechten van een ander restaurant in uw order zitten. Verwijder de andere gerechten uit uw order om dit gerecht te bestellen',
        'error',
      );
      return;
    } else if (
      `${restaurantId}` !== `${selectedRestaurantId}` &&
      session.sessionType === SessionType.RESTAURANT
    ) {
      this.props.showNotification(
        'U zit op dit moment in een ander restaurant! Scan de QR-code als je wilt wisselen.',
        'error',
      );
      return;
    }
    if (session.status === 'closed') {
      this.props.showNotification('U', 'error');
      return;
    }

    if (
      !this._canMenuItemBeOrderedNow(menuItem) &&
      session.sessionType === SessionType.FROM_HOME
    ) {
      this.props.showNotification(
        'Dit gerecht kan niet voor afhalen/bezorgen worden besteld.',
        'error',
      );
      return;
    }

    const response = await this.props.addMenuItem(
      id,
      token,
      menuItem,
      sideDishSelections,
      comment,
      amount,
    );

    this.setState({showAddDishDialog: false});
    if (response.success === 1) {
      this.props.showNotification('Gerecht successvol toegevoegd!', 'success');
    } else if (response === 'kitchen_closed') {
      this.props.showNotification(
        'De keuken is gesloten. Je kunt alleen nog drinken bestellen.',
        'error',
      );
    } else if (response === 'restaurant_closed') {
      this.props.showNotification('Het restaurant is gesloten.', 'error');
    } else if (
      response === 'breakfast_closed' ||
      response === 'lunch_closed' ||
      response === 'dinner_closed' ||
      response === 'snacks_closed' ||
      response === 'delivery_closed'
    ) {
      const errors = {
        breakfast_closed: 'Ontbijt',
        lunch_closed: 'Lunch',
        dinner_closed: 'Diner',
        snack_closed: 'Snacks',
        delivery_closed: 'Bezorging',
      };
      if (currentMealTimes.length > 0) {
        this.props.showNotification(
          `${errors[response]} is gesloten, je kunt wel ${
            this.mealTimeCategories[currentMealTimes[0]]
          } bestellen.`,
          'error',
        );
      } else {
        this.props.showNotification(
          'De keuken is gesloten. Je kunt alleen nog drinken bestellen.',
          'error',
        );
      }
    } else {
      this.props.showNotification(
        'Er ging iets mis. Probeer het later opnieuw.',
        'error',
      );
    }
    if (response.success !== 1 && isNewSession) {
      this.props.removeSession();
    }
  };

  /** Group the menu items in their categories i.e. "Warme maaltijden, broodjes" */
  _getGroupedMenu = () => {
    const {menuFavorites} = this.props;
    const menu = this._getFilteredMenu();
    const categories = this._getCategories().map((x) => {
      return {category: x, menuItems: []};
    });

    menu.forEach((x) => {
      const category = categories.find(
        (y) =>
          y.category.name === x.category ||
          (y.category.name === 'Overig' && x.category === null),
      );
      if (category) {
        category.menuItems.push(x);
      }
      const mealTimeCategories = categories.filter((y) =>
        x.mealTypes.includes(y.category.mealTime),
      );
      mealTimeCategories.forEach((y) => y.menuItems.push(x));
      if (menuFavorites[x.id] && x.category !== 'Aanbevolen') {
        categories
          .find((y) => y.category.name === 'Favorieten')
          .menuItems.push(x);
      }
    });

    return categories
      .filter((category) => category.menuItems.length > 0)
      .sort((a, b) => a.category.orderNum - b.category.orderNum);
  };

  _canMenuItemBeOrderedNow = (menuItem) => {
    const {hasSession, restaurantId, session} = this.props;
    const currentMealTimes = this.getCurrentMealtimes();

    if (
      hasSession &&
      session.restaurantId === restaurantId &&
      session.sessionType !== SessionType.FROM_HOME
    ) {
      return currentMealTimes.some(
        (mealTime) =>
          menuItem.mealTypes.length === 0 ||
          menuItem.mealTypes.includes(mealTime),
      );
    }

    return true;
  };

  _canMenuItemBeOrderedRemotely = (menuItem) => {
    const {restaurant} = this.props;
    const restaurantAvailable =
      restaurant.reservationTimeslotsDelivery !== '0' ||
      restaurant.reservationTimeslotsTakeout !== '0' ||
      restaurant.reservationTimeslotsToGo !== '0';
    const dishSupportesRemote =
      menuItem.mealTypes.includes('to-go') ||
      menuItem.mealTypes.includes('delivery') ||
      menuItem.mealTypes.includes('takeout');
    return dishSupportesRemote && restaurantAvailable;
  };

  _getFilteredMenu = () => {
    const {currentMealType, menu} = this.props;

    if (!menu) return [];
    const {menuFavorites} = this.props;

    const type = this.state.foodSelected ? 'eten' : 'drinken';

    return menu
      .filter((menuItem) => menuItem.type === type)
      .filter((menuItem) =>
        currentMealType
          ? menuItem.mealTypes.includes(currentMealType.value)
          : true,
      )
      .filter((menuItem) => this._canMenuItemBeOrderedNow(menuItem))
      .sort((a, b) => {
        return (menuFavorites[b.id] || false) - (menuFavorites[a.id] || false);
      });
  };

  _getCategories = () => {
    const {apiCategories, menu, menuFavorites, restaurant} = this.props;

    if (!menu) return [];

    const type = this.state.foodSelected ? 'eten' : 'drinken';

    const categories = [];

    // add regular categories
    menu.forEach((item) => {
      if (item.type === type) {
        const alreadyExists = !!categories.find(
          (i) => i.type === 'category' && i.name === item.category,
        );
        if (alreadyExists) return;
        const apiCats = apiCategories[restaurant.id];
        if (apiCats) {
          const matchedCategory = apiCats.find(
            (x) => x.title === item.category,
          );
          if (matchedCategory) {
            categories.push({
              type: 'category',
              name: item.category,
              orderNum: matchedCategory.orderNum,
              image: matchedCategory.image,
            });
          } else if (!categories.find((x) => x.name === 'Overig')) {
            categories.push({type: 'category', name: 'Overig', orderNum: 999});
          } else if (!categories.find((x) => x.name === 'Aanbevolen')) {
            categories.push({
              type: 'category',
              name: 'Aanbevolen',
              orderNum: -1,
            });
          }
        }
      }
    });

    if (menu.some((item) => menuFavorites[item.id] !== undefined)) {
      categories.push({type: 'category', name: 'Favorieten', orderNum: -2});
    }

    return categories.sort((a, b) => a.orderNum - b.orderNum);
  };

  getCurrentMealtimes = () => {
    const {restaurant, hasSession, session} = this.props;
    const isRemoteSession =
      hasSession && session.sessionType === SessionType.FROM_HOME;
    const currentTime = moment();
    const mealTimesObj = restaurant.mealTimes;
    if (restaurant.mealTimes) {
      const filtered = Object.keys(mealTimesObj).filter((mealTime) => {
        let [hour, minute] = mealTimesObj[mealTime].open.split(':');
        const start = moment().set({
          hour: parseInt(hour, 10),
          minute: parseInt(minute, 10),
          second: 0,
        });

        [hour, minute] = mealTimesObj[mealTime].closed.split(':');
        const end = moment().set({
          hour: parseInt(hour, 10),
          minute: parseInt(minute, 10),
          second: 0,
        });
        if (end.isBefore(start)) {
          end.add(1, 'day');
        }

        if (
          currentTime.unix() >= start.unix() &&
          currentTime.unix() <= end.unix()
        ) {
          return true;
        } else {
          return false;
        }
      });
      if (filtered.length === 0) {
        return Object.keys(this.mealTimeCategories);
      } else {
        return filtered.filter((x) =>
          isRemoteSession
            ? ['takeout', 'delivery', 'to-go'].includes(x)
            : !['takeout', 'delivery', 'to-go'].includes(x),
        );
      }
    } else {
      return Object.keys(this.mealTimeCategories);
    }
  };

  goToProfile = () => {
    const {account} = this.props;
    if (account.loggedIn) {
      this.props.history.push('/profile');
    } else {
      this.props.history.push('/login');
    }
  };

  joinSession = async (
    {restaurantId, token, tableNumber, isOber = false},
    triageQuestionData,
  ) => {
    const {account, history, joinSession, loggedIn, restaurant} = this.props;

    const userToken = loggedIn ? account.token : null;

    let session;
    if (triageQuestionData) {
      session = await joinSession(
        restaurantId,
        token,
        tableNumber,
        userToken,
        triageQuestionData.ggdOptInEnabled,
        triageQuestionData.isSameHousehold,
        triageQuestionData.hasNoSymptoms,
        triageQuestionData.name,
        triageQuestionData.phoneNumber,
      );
    } else {
      session = await joinSession(
        restaurantId,
        token,
        tableNumber,
        userToken,
        '',
        '',
        isOber
      );
    }

    if (session) {
      this.props.showNotification(
        'Geslaagd! Je kunt nu bestellingen plaatsen.',
        'success',
      );
      history.replace('/restaurant/' + restaurant.generateSlug());
      this.setState({showTriageDialog: false, scanData: undefined});
    }
  };

  onTriageDialogCompleted = (
    isSameHousehold,
    hasNoSymptoms,
    phoneNumber,
    name,
    ggdOptInEnabled,
  ) => {
    const {scanData} = this.state;
    this.joinSession(scanData, {
      phoneNumber,
      name,
      isSameHousehold,
      hasNoSymptoms,
      ggdOptInEnabled,
    });
  };

  _renderDishDialog = () => {
    const {menu} = this.props;
    const cancel = () => this.setState({showAddDishDialog: false});
    let menuItem = menu.find((item) => item.id === this.state.showAddDishId);
    if (!menuItem) {
      if (
        !this.state.menuItemPreview ||
        this.state.menuItemPreview.id !== this.state.showAddDishId
      ) {
        return;
      }
      menuItem = this.state.menuItemPreview;
    }

    const {restaurant} = this.props;

    let image = null;
    if (menuItem.image && menuItem.image.length > 0) {
      image = menuItem.image;
    } else if (menuItem.imageSquare && menuItem.imageSquare.length > 0) {
      image = menuItem.imageSquare;
    } else {
      image = restaurant.bannerImage;
    }

    return (
      <AddDishDialog
        menuItem={menuItem}
        restaurantId={restaurant.id}
        item={menuItem}
        image={image}
        onCancel={cancel}
        onComplete={this.addDish}
      />
    );
  };

  render() {
    const {
      detailsVisible,
      showAddDishDialog,
      showMessageDialog,
      showReservationDialog,
      showToTop,
      messageTitle,
      messageDescription,
    } = this.state;
    const {
      hasSession,
      menuItemsQueue,
      occupation,
      occupationGroup,
      restaurant,
      restaurantId,
      hasUserBeenGreeted,
      session,
    } = this.props;

    const basketCount = menuItemsQueue.length;

    if (!restaurant) {
      return <></>;
    }

    const shouldShowUserGreeting =
      !hasUserBeenGreeted &&
      hasSession &&
      session.sessionType === SessionType.RESTAURANT;
    const menu = this._getGroupedMenu();
    console.log(menu);
    return (
      <>
        <Helmet>
          <title>{'Amuse | ' + restaurant.title}</title>
        </Helmet>

        <Bg bg="bg-white" />
        <Content className="restaurant-page">
          <ReactVisibilitySensor
            partialVisibility={true}
            onChange={(isVisible) => {
              this.setState({showToTop: !isVisible});
            }}>
            <div>
              <RestaurantHeader
                showDetails={() => this.setState({detailsVisible: true})}
                goBack={this.props.history.goBack}
                goToProfile={this.goToProfile}
                goToScan={() => this.props.history.push('/scanner')}
                occupation={occupation}
                restaurant={restaurant}
                occupationGroup={occupationGroup}
                detailsVisible={detailsVisible}
              />
              <RestaurantDetails
                restaurant={restaurant}
                openingTimes={restaurant.openingTimes}
                visible={detailsVisible}
                occupation={occupation}
                hideDetails={() => this.setState({detailsVisible: false})}
              />
            </div>
          </ReactVisibilitySensor>

          <RestaurantEatDrinkComponent
            ref={this.eatDrinkComponent}
            onChange={this.onEatDrinkChanged}
          />

          {hasSession && (
            <FloatingButton
              onClick={() => this.props.history.push('/orders')}
              number={basketCount}>
              <StoreIcon width={28} height={28} />
            </FloatingButton>
          )}

          {menu && (
            <RestaurantMenu
              menu={menu}
              onMenuItemClicked={this.onMenuItemClicked}
            />
          )}

          {!hasSession && restaurant.reservationEnabled === '1' && (
            <ReservationButton
              onClick={() => this.setState({showReservationDialog: true})}
            />
          )}

          {shouldShowUserGreeting && (
            <UserGreetingDialog
              restaurant={restaurant}
              onNoPressed={() => {}}
              onYesPressed={() => {
                this.eatDrinkComponent.current.selectDrinks();
              }}
            />
          )}

          <FloatingButton
            className={'back-to-top' + (showToTop ? ' visible' : '')}
            onClick={() =>
              window.scroll({left: 0, top: 0, behavior: 'smooth'})
            }>
            <ToTopIcon width={24} height={24} />
          </FloatingButton>
        </Content>

        {/* Dialogs */}
        {showMessageDialog && (
          <MessageDialog
            title={messageTitle}
            description={messageDescription}
            onComplete={() => this.setState({showMessageDialog: false})}
          />
        )}
        {showReservationDialog  && (
          <ReservationDialog
            restaurant={restaurant}
            onCancel={() => this.setState({showReservationDialog: false})}
          />
        )}
        {showAddDishDialog && this._renderDishDialog()}
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const slug = props.match.params.slug;
  const data = queryString.parse(props.location.search);
  const isPreview = data.preview === 'lwpv';
  let foundRestaurant;
  if (isNaN(slug)) {
    foundRestaurant = state.restaurants.restaurants.find(
      (restaurant) => slug === restaurant.generateSlug(),
    );
  } else {
    foundRestaurant = state.restaurants.restaurants.find(
      (restaurant) => slug === restaurant.id,
    );
  }
  let restaurant = isPreview
    ? state.restaurants.previewRestaurant
    : foundRestaurant;

    if(state.restaurants.detailRestaurant){
      //restaurant = state.restaurants.detailRestaurant;
    }

  const restaurantId = restaurant && restaurant.id;
  return {
    account: state.account,
    apiCategories: state.restaurantCategories,
    currentMealType: state.search.activeFilters.find(
      (x) => x.type === FilterType.Mealtype,
    ),
    hasSession: state.session.hasSession,
    isPreview: isPreview,
    menu: state.restaurantMenu[restaurantId],
    menuFavorites: state.account.menuFavorites || {},
    menuItemsQueue: state.orders.menuItemsQueue,
    occupationGroup: state.occupation.groups.find(
      (x) => x.restaurants.find((y) => y.id === restaurantId) !== undefined,
    ),
    occupation: state.occupation.ungroupedRestaurants.find(
      (x) => x.id === restaurantId,
    ),
    restaurant: restaurant,
    restaurantId: restaurantId,
    restaurants: state.restaurants.restaurants,
    session: state.session.session,
    hasUserBeenGreeted: state.session.hasUserBeenGreeted,
  };
};

export default connect(mapStateToProps, {
  getRestaurant,
  getRestaurants,
  getMenu,
  showNotification,
  addMenuItem,
  removeSession,
  joinSession,
})(Restaurant);

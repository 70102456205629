import React, { useCallback, useState } from 'react';
import { SideDishOption } from '../../../../models/sidedish';
import Formatters from '../../../../utils/formatters';
import { SideDishSelectable } from '../side.dishes.dialog.component';
import { CheckboxOption } from '../../../../components/ui-base/check-group/check-group';
import { CheckGroup, InputGroup, Label } from '../../../../components/ui-base';

type Props = {
  dish: SideDishSelectable;
  onSelectionChanged: () => void;
};

const getOptionLabel = (option: SideDishOption) => {
  return option.price > 0
    ? `${option.option} (+ ${Formatters.formatCurrency(option.price)})`
    : option.option;
};

const createCheckboxOption = (
  sideDishOption: SideDishOption,
): CheckboxOption<SideDishOption> => ({
  label: getOptionLabel(sideDishOption),
  value: sideDishOption,
});

export const MultipleChoiceSideDish: React.FC<Props> = (props: Props) => {
  const [selection, setSelection] = useState<
    Array<CheckboxOption<SideDishOption>>
  >([createCheckboxOption(props.dish.items[0])]);

  const onSelectionChangeCallback = useCallback(
    (newSelection: Array<CheckboxOption<SideDishOption>>) => {
      setSelection(newSelection);
      props.dish.selections = props.dish.items.filter((x) =>
        newSelection.some((y) => x.id === y.value.id),
      );
      props.onSelectionChanged();
    },
    [props],
  );

  const options: Array<CheckboxOption<SideDishOption>> =
    props.dish.items.map(createCheckboxOption);
  return (
    <InputGroup className="multiple-choice-sidedish">
      <Label>{props.dish.title}</Label>
      <CheckGroup<SideDishOption>
        options={options}
        selectedOptions={selection}
        onSelectionChange={onSelectionChangeCallback}
      />
    </InputGroup>
  );
};

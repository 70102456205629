import React from 'react';
import {ReactComponent as Logo} from '../../../assets/images/logo.svg';
import Button from '../components/button/button.component';
import './top-bar.scss';

const TopBar: React.FC = () => {
  return (
    <div className="top-bar">
      <div className="container">
        <Logo className="logo" />
        <div className="button-holder">
          <Button type="primary" link="/login">
            Inloggen
          </Button>
          <Button type="secondary" link="https://www.aanmelden.amuse-menu.nl">
            Voor ondernemers
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TopBar;

import React from 'react';
import './featured-items.scss';
import FeaturedItem, {Item} from '../components/featured-item/featured-item';
import {ReactComponent as Arrow} from '../../../assets/icons/chevron-right.svg';
import Button from '../components/button/button.component';

type Props = {
  items: Item[];
  title: string;
  showMoreUrl: string;
  offWhite?: boolean;
};

const FeaturedItems: React.FC<Props> = ({
  items,
  title,
  showMoreUrl,
  offWhite = false,
}) => {
  const elements = items.map((item) => <FeaturedItem {...item} />);

  return (
    <div className={`${offWhite && 'off-white'}`}>
      <div className={`featured-items`}>
        <div className="row featured-header">
          <h2>{title}</h2>
          <Button type="tertiary" link={showMoreUrl}>
            Meer tonen <Arrow className="arrow" />
          </Button>
        </div>
        <div className="row">{elements}</div>
      </div>
    </div>
  );
};

export default FeaturedItems;

export default class Table {
  id: string;
  restaurantId: string;
  tableNumber: string;
  location: string;
  amountOfChairs: number;
  timeslotInMinutes: number;
  isDisabled: boolean;

  constructor(json: any) {
    this.id = json.id;
    this.restaurantId = json.id;
    this.tableNumber = json.tableNumber;
    this.location = json.place;
    this.amountOfChairs = parseInt(json.chairs);
    this.timeslotInMinutes = parseInt(json.timeslotMinutes);
    this.isDisabled = json.disabled;
  }
}

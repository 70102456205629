import axios from 'axios';
import APIResponse from './responses/APIResponse';
import GetTablesResponse from './responses/getTablesResponse';
import MakeReservationResponse from './responses/makeReservationResponse';
import {Moment} from 'moment';
import GetReservationsResponse from './responses/getReservationsResponse';
import ValidateReservationResponse from './responses/validateReservationResponse';
import {ReservationData} from '../../screens/restaurant/reservation-dialog/reservation-interfaces';
import {DateTime} from 'luxon';

const API_URL = process.env.REACT_APP_API_URL;

export default class ReservationsAPI {
  generateTimeStampFromTimeAndDate = (time: string, date: Date) => {
    const timeArray = time?.split(':');
    if (timeArray?.length !== 2) return null;

    return DateTime.fromJSDate(date)
      .set({
        hour: parseInt(timeArray[0], 10),
        minute: parseInt(timeArray[1], 10),
        second: 0,
      })
      .toUnixInteger();
  };

  getTables = async (restaurantId: string) => {
    try {
      const response = await axios.get(
        API_URL + '/reservations/getTables?restaurantId=' + restaurantId,
      );
      return new APIResponse(response.data, GetTablesResponse);
    } catch (error) {
      // TODO: Handle error
    }
    return null;
  };

  getReservations = async (restaurantId: string, date: Moment) => {
    try {
      const response = await axios.get(
        API_URL +
          '/reservations/getReservations?restaurantId=' +
          restaurantId +
          '&date=' +
          date.unix(),
      );
      return new APIResponse(response.data, GetReservationsResponse);
    } catch (error) {
      // TODO: Handle error
    }
    return null;
  };

  validate = async (code: string) => {
    try {
      const response = await axios.get(
        API_URL + '/reservations/validate?code=' + code,
      );
      return new APIResponse(response.data, ValidateReservationResponse);
    } catch (error) {
      throw error;
    }
  };

  makeNew = async (restaurantId: string, reservationData: ReservationData) => {
    try {
      const timestamp = this.generateTimeStampFromTimeAndDate(
        reservationData.reservationTime ?? '',
        reservationData?.reservationDate ?? new Date(),
      );
      const body = {
        restaurantId,
        place: reservationData.preferredLocation,
        name: reservationData.customerDetails.name,
        phoneNumber: reservationData.customerDetails.phoneNumber,
        email: reservationData.customerDetails.email,
        people: reservationData.amountOfPersons,
        timestamp: timestamp,
      };

      const response = await axios.post(API_URL + '/reservations/add', body);
      return new APIResponse(response.data, MakeReservationResponse);
    } catch (error) {
      // TODO: Handle error
    }
    return null;
  };
}

import axios from 'axios';
import APIResponse from './responses/APIResponse';
import GetOccupationResponse from './responses/getOccupationResponse';

const API_URL = process.env.REACT_APP_API_URL;

export default class OccupationAPI {
    get = async (): Promise<APIResponse<GetOccupationResponse>> => {
        const url = `${API_URL}/getOccupation`;
        const response = await axios.get(url);
        return new APIResponse(response.data, GetOccupationResponse);;
    }
}
import { AppThunk } from "../reducers/root.reducer";
import Table from "../../models/table";
import api from "../../services/api/apiv2";
import { tablesLoadedAction } from "./reservations.actions";

export const loadTables = (restaurantId: string): AppThunk<Promise<Array<Table> | null>> => async dispatch => {
    try {
        const apiResponse = await api.reservations.getTables(restaurantId);
        if(apiResponse !== null && apiResponse.isSuccess) {
            dispatch(tablesLoadedAction(restaurantId, apiResponse.data.tables));
            return apiResponse.data.tables;
        }
    } catch(error) {
        //TODO: Handle error
    }
    return null;
}
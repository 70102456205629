import SideDish from '../../../models/sidedish';

export default class GetMenuItemDetailsResponse {
  sideDishes: Array<SideDish> = [];

  constructor(json: any) {
    // Check if this property exists. Menu items without side dishes do not have this property in the API response
    if (json.sideDishes) {
      this.sideDishes = Object.keys(json.sideDishes).map(
        (dishKey: string) =>
          new SideDish({key: dishKey, ...json.sideDishes[dishKey]}),
      );
    }
  }
}

import moment, {Moment} from 'moment';
import Timeslot from '../../models/timeslot';

export type OpeningTime = {
  from: Moment;
  to: Moment;
};

export default class TimeslotGenerator {
  /**
   *
   * @param openingTime From when to when a restaurant is open
   * @param interval The gap to leave between timeslots, should be greater than 0 and an error will be thrown when passing 0
   * @param now Moment to use as current time, can be used to build timeslots starting from a specific moment in time
   * @param allowWithinThisHour When false, will make sure generated timeslots start at the next hour.
   */
  static generateTimeslots = (
    openingTime: OpeningTime,
    interval: number,
    now: Moment = moment(),
    allowWithinThisHour: boolean = false,
  ): Array<Timeslot> => {
    const inputClone = moment(now);
    if (interval <= 0) {
      throw new Error(
        'Interval should be greater than 0, ' + interval + ' was passed.',
      );
    }

    const output: Array<Timeslot> = [];
    if (!allowWithinThisHour) {
      inputClone.add(1, 'hour');
      inputClone.add(1, 'minute');
    }

    const minute = inputClone.minute();
    if (interval === 15 && minute >= 45) {
      inputClone.hour(inputClone.hour() + 1);
    } else if (interval === 30 && minute >= 30) {
      inputClone.hour(inputClone.hour() + 1);
    } else if (interval >= 45) {
      inputClone.hour(inputClone.hour() + 1);
    }

    inputClone.minute(
      (Math.ceil(inputClone.minute() / interval) * interval) % 60,
    );
    inputClone.second(0);


    const firstAvailable = inputClone.isAfter(openingTime.from)
      ? inputClone
      : openingTime.from;
    firstAvailable.minute(
      (Math.ceil(firstAvailable.minute() / interval) * interval) % 60,
    );
    const lastAvailable = openingTime.to;

    var date = moment(firstAvailable);
    while (date.isSameOrBefore(lastAvailable)) {
      output.push(new Timeslot(date));
      date = moment(date).add(interval, 'minute');
    }

    return output;
  };
}

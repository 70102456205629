import React, {Component} from 'react';
import Formatters from '../../utils/formatters';
import './order.total.style.scss';

export default class OrderTotal extends Component {

    render() {
        const {price} = this.props;
        return (
            <div className="order_total">
                <div className="order_total_row mb-20">
                    <div className="order_total_text">
                        Totaal
                    </div>
                    <div className="flex-1"/>
                    <div className="order_total_text price">
                        {Formatters.currency(price,true)}
                    </div>
                </div>
            </div>
        );
    }
}

import React, {ChangeEvent, useState} from 'react';
import {connect} from 'react-redux';
import {AppState} from '../../redux/reducers/root.reducer';
import Formatters from '../../utils/formatters';
import AltAction from '../alt-action/alt.action.component';
import {ActionsOrientation} from '../ui-base/modal-dialog-actions/modal-dialog-actions.component';
import {
  ModalDialog,
  ModalDialogTitle,
  ModalDialogActions,
  Input,
  Label,
  InputGroup,
  Button,
} from '../ui-base';

import './order-confirmation-dialog.component.scss';
import OrderEntry from './order-entry/order-entry';

type Props = {
  menu: any;
  orders: any;
  paymentMethod: string;
  restaurant: any;
  onConfirm: (tipValue: number) => void;
  onCancel: () => void;
};

const getItemQueuePrice = (item: any, menu: any) => {
  const originalPrice = item.details.mainPrice;
  if (!menu) return originalPrice;

  if (item.status && item.status !== 'queued') {
    return originalPrice;
  }

  const menuItem = menu.find((i: any) => i.id === item.details.itemId);

  if (!menuItem) return originalPrice;

  if (menuItem.actionActive === '1') {
    return menuItem.actionPrice;
  }

  return menuItem.price;
};

const getSideDishesPrice = (sideDishes: any) => {
  let sideDishPrice = 0;
  Object.keys(sideDishes).forEach((key) => {
    const dish = sideDishes[key];
    if (dish.options && dish.options.length > 0) {
      dish.options.forEach(
        (option: any) => (sideDishPrice += parseInt(option.priceModifier)),
      );
    }
  });
  return sideDishPrice;
};

const getTotalPrice = (
  order: any,
  paymentMethod: any,
  tipValue: any,
  restaurant: any,
  menu: any,
) => {
  let deliveryCosts = 0;
  let transactionCosts = 0;
  if (paymentMethod === 'ideal') {
    transactionCosts = parseInt(restaurant.iDealCost);
  }

  return (
    order
      .map(
        (dish: any) =>
          dish.amount *
          (parseInt(getItemQueuePrice(dish, menu), 10) +
            getSideDishesPrice(dish.details.sideDishes)),
      )
      .reduce((a: any, b: any) => a + b, 0) +
    (tipValue ? tipValue : 0) * 100 +
    deliveryCosts +
    transactionCosts
  );
};

const OrderConfirmationDialog: React.FC<Props> = (props: Props) => {
  const [tipValue, setTipValue] = useState(0);

  const onTipChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTipValue(Math.abs(event.target.valueAsNumber));
  };

  return (
    <ModalDialog className="order-confirmation-dialog">
      <ModalDialogTitle>Bestelling overzicht</ModalDialogTitle>

      <div className="pay_dialog_dishes">
        <h1 className="pay_dialog_secondary_title">Bestelling</h1>

        {props.orders.menuItemsOrdered.map((menuItem: any) => {
          return (
            <OrderEntry
              amount={menuItem.amount}
              title={menuItem.details.title}
              price={getItemQueuePrice(menuItem, props.menu)}
              sideDishes={menuItem.details.sideDishes}
            />
          );
        })}
        {tipValue > 0 && (
          <>
            <div className="pay_dialog_menuitem">
              <div className="pay_dialog_menuitem_title">
                <span>Fooi</span>
                <span>{Formatters.formatCurrency(tipValue * 100)}</span>
              </div>
            </div>
          </>
        )}
        {props.paymentMethod === 'ideal' && (
          <div className="pay_dialog_menuitem">
            <div className="pay_dialog_menuitem_title">
              <span>iDeal transactiekosten</span>
              <span>
                {Formatters.formatCurrency(props.restaurant.iDealCost)}
              </span>
            </div>
          </div>
        )}
        <div className="pay_dialog_total">
          <span>Totaal</span>
          <span className="pay_dialog_total_price">
            {Formatters.formatCurrency(
              getTotalPrice(
                props.orders.menuItemsOrdered,
                props.paymentMethod,
                tipValue,
                props.restaurant,
                props.menu,
              ),
            )}
          </span>
        </div>
      </div>

      {props.restaurant && props.restaurant.enableTipping === '1' && (
        <InputGroup>
          <Label className="full-width">
            Wil je een fooi geven, vul dan hier een bedrag in.
          </Label>
          <Input
            className="full-width"
            type="number"
            value={tipValue}
            placeholder={'0,00'}
            prefix="€"
            onChange={onTipChange}
          />
        </InputGroup>
      )}

      <ModalDialogActions orientation={ActionsOrientation.Vertical}>
        <Button
          text="Betaal"
          className="mt-40"
          onClick={() => props.onConfirm(tipValue)}
        />
        <AltAction
          text="Terug"
          className="mt-20"
          icon="close"
          onClick={props.onCancel}
        />
      </ModalDialogActions>
    </ModalDialog>
  );
};

const mapStateToProps = (state: AppState) => {
  const session = state.session;
  let menu;
  let restaurantId = '';
  if (session.hasSession) {
    restaurantId = session.session!.restaurantId;
    menu = (state.restaurantMenu as any)[session.session!.restaurantId];
  }
  return {
    menu: menu,
    orders: state.orders,
    restaurant: state.restaurants.restaurants.find(
      (x) => x.id === restaurantId,
    ),
  };
};

export default connect(mapStateToProps)(OrderConfirmationDialog);

import { AppThunk } from "../reducers/root.reducer";
import api from "../../services/api/apiv2";
import { occupationGroupsRetrieved } from "./occupation.actions";

export const fetchOccupations = (): AppThunk<Promise<void>> => async dispatch => {
    const apiResponse = await api.occupation.get();
    if(apiResponse.isSuccess) {
        dispatch(occupationGroupsRetrieved(apiResponse.data.groups, apiResponse.data.restaurants));
    } else {
        console.error(apiResponse.message);
    }
}
import LoadingIndicator from '../../components/loading-indicator/loading.indicator.component';
import CategoryHeader from '../../components/category-header/category.header.component';
import {
  changeMenuItemAmount,
  submitOrders,
  clearOrders,
} from '../../redux/actions/orders.actions';
import {
  cancelSession,
  removeSession,
  updateSessionStatus,
} from '../../redux/session/session.thunks';
import TableHeader from '../../components/table-header/table.header.component';
import YesNoDialog from '../../sections/yes-no-dialog/yes.no.dialog.component';
import OrderTotal from '../../components/order-total/order.total.component';
import {showNotification} from '../../redux/actions/notifications.actions';
import OrderItem from '../../components/order-item/order.item.component';
import PayDialog, {
  PaymentMethod,
} from '../../sections/pay-dialog/pay.dialog.component';
import DeliverDialog from '../../sections/remote-order/deliver.dialog.component';
import Content from '../../components/content/content.component';
import Toolbar from '../../components/toolbar/toolbar.component';
import Button from '../../components/ui-base/button/button.component';
import {Component} from 'react';
import api from '../../services/api';
import {connect} from 'react-redux';
import {getMenu} from '../../redux/actions/restaurant.menu.actions';
import AltAction from '../../components/alt-action/alt.action.component';
import SessionType from '../../models/session-type';
import OrderTip from '../../components/order-tip/order.tip.component';
import OrderConfirmationDialog from '../../components/order-confirmation-dialog/order-confirmation-dialog.component';
import {NotificationType} from '../../models/notification';
import {ReactComponent as CheckIcon} from '../../assets/icons/check.svg';
import {AppState} from '../../redux/reducers/root.reducer';
import Restaurant from '../../models/restaurant';
import Session from '../../models/session';
import HistoricOrder from '../../models/history/historicOrder';
import {RouteComponentProps} from 'react-router-dom';
import MessageDialog from '../../sections/message-dialog/message.dialog.component';
import {Typography} from '../../components/ui-base';
import Formatters from '../../utils/formatters';
import TextInputDialog from '../../components/text-input-dialog/text-input-dialog.component';

type ReduxActions = {
  changeMenuItemAmount: (
    sessionId: string,
    sessionToken: string,
    menuItem: any,
    add: boolean,
  ) => void;
  showNotification: (
    message: string,
    type: NotificationType,
    duration?: number,
  ) => Promise<void>;
  submitOrders: (
    sessionId: string,
    sessionToken: string,
    extraOptions?: any,
  ) => Promise<any>;
  clearOrders: () => void;
  getMenu: (restaurantId: string, isPreview?: boolean) => void;
  removeSession: () => Promise<void>;
  cancelSession: () => Promise<Boolean>;
  updateSessionStatus: (newStatus: string) => Promise<void>;
};

type ReduxProps = {
  menuItemsQueue: any;
  menuItemsOrdered: any;
  menu: any;
  hasSession: boolean;
  session: Session | null;
  restaurants: Restaurant[];
  restaurant?: Restaurant | null;
};

type Props = {} & ReduxProps & ReduxActions & RouteComponentProps;

type State = {
  loading: boolean;
  showPayDialog: boolean;
  showSubmitConfirm: boolean;
  showDeliverDialog: boolean;
  showOberComingDialog: boolean;
  showPaymentConfirmationDialog: boolean;
  paymentMethod: PaymentMethod;
  extraAmount: number;
  showNameDialog: boolean;
  name: string;
  showLoader: boolean;
};

class Orders extends Component<Props, State> {
  state = {
    loading: false,
    showPayDialog: false,
    showSubmitConfirm: false,
    showDeliverDialog: false,
    showOberComingDialog: false,
    showNameDialog: false,
    showPaymentConfirmationDialog: false,
    paymentMethod: PaymentMethod.PIN,
    extraAmount: 0,
    name: '',
    showLoader: false,
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  _processItems = (items: HistoricOrder[]) => {
    let categories = [
      {type: 'eten', title: 'Eten'},
      {type: 'drinken', title: 'Drinken'},
    ];

    const mapped = categories
      .map((category) => {
        const orderItemsForCategory = items
          .filter((dish: any) => dish.details.type === category.type)
          .map((x: any) => ({...x, type: 'order'}))
          .sort((a: any, b: any) => b.roundNumber - a.roundNumber);
        return [
          {type: 'category', title: category.title},
          ...orderItemsForCategory,
        ];
      })
      .filter((groupedResult) => groupedResult.length > 1);

    return mapped.flat();
  };

  _getItems = () => {
    const {menuItemsQueue} = this.props;

    return this._processItems(menuItemsQueue);
  };

  _getAlreadyOrderedItems = () => {
    let {menuItemsOrdered} = this.props;
    menuItemsOrdered = menuItemsOrdered.map((item: HistoricOrder) => {
      return {...item, ordered: true};
    });
    return this._processItems(menuItemsOrdered);
  };

  _getTotalPrice = (items: HistoricOrder[]) => {
    const {session} = this.props;
    return (
      (items.reduce((previous: number, current: any) => {
        const price = parseInt(this.getItemQueuePrice(current));
        const sideDishesPrice = this.getSideDishesPrice(
          current.details.sideDishes,
        );
        return previous + (price + sideDishesPrice) * current.amount;
      }, 0) +
        (session?.tip || 0)) /
      100
    ).toFixed(2);
  };

  getSideDishesPrice = (sideDishes: any) => {
    let sideDishPrice = 0;
    if (sideDishes) {
      Object.keys(sideDishes).forEach((key) => {
        const dish = sideDishes[key];
        if (dish.options && dish.options.length > 0) {
          dish.options.forEach(
            (option: any) => (sideDishPrice += parseInt(option.priceModifier)),
          );
        }
      });
    }
    return sideDishPrice;
  };

  getItemQueuePrice = (item: HistoricOrder) => {
    const originalPrice = item.details.mainPrice;

    const menu = this._getMenu();
    if (!menu) return originalPrice;

    if (item.status && item.status !== 'queued') {
      return originalPrice;
    }

    const menuItem = menu.find((i: any) => i.id === item.details.itemId);

    if (!menuItem) return originalPrice;

    if (menuItem.actionActive === '1') {
      return menuItem.actionPrice;
    }

    return menuItem.price;
  };

  _getMenu = () => {
    const {session} = this.props;

    if (session?.restaurantId) {
      const menu = this.props.menu[session.restaurantId];
      if (!menu) {
        this.props.getMenu(session.restaurantId);
      }
      return menu;
    }

    return null;
  };

  addAmount = (item: any, add: boolean) => {
    const {session} = this.props;

    session &&
      this.props.changeMenuItemAmount(session.id, session.token, item, add);
  };

  callOberClicked = async () => {
    const {session, hasSession} = this.props;

    if (!hasSession && !session) {
      return;
    }

    this.setState({loading: true});
    const data = await api.callOber(session?.id, session?.token, 'call');
    const {success, error} = data;
    this.setState({loading: false});
    if (success) {
      this.props.showNotification(
        'De ober komt eraan!',
        NotificationType.SUCCESS,
      );
    } else if (error === 'alreadyRequested') {
      this.props.showNotification(
        'De ober komt eraan, een moment a.u.b.',
        NotificationType.ERROR,
      );
    } else {
      this.props.showNotification(
        'Er ging iets mis, probeer het later opnieuw.',
        NotificationType.ERROR,
      );
    }
  };

  leaveSessionClicked = () => {
    this.props.clearOrders();
    this.props.cancelSession();
    this.props.history.goBack();
  };

  payClicked = () => {
    const {session} = this.props;
    if (session?.status === 'payment-requested') {
      this.setState({showOberComingDialog: true});
    } else if (session?.sessionType === SessionType.FROM_HOME) {
      this.setState({showDeliverDialog: true, showSubmitConfirm: false});
    } else {
      this.setState({showPayDialog: true, showSubmitConfirm: false});
    }
  };

  onBack = () => {
    const {clearOrders, removeSession, session, hasSession} = this.props;
    if (hasSession && session?.status === 'closed') {
      clearOrders();
      removeSession();
    }
    return true;
  };

  submitOrders = async () => {
    if (!this.props.session) return;
    this.setState({loading: true, showSubmitConfirm: false});
    const {id, token, isOber} = this.props.session;
    const response = await this.props.submitOrders(id, token, {
      name: this.state.name,
    });
    this.setState({loading: false});
    if (response.success === 1) {
      this.props.showNotification(
        'Je bestelling is geplaatst en komt er z.s.m. aan!',
        NotificationType.SUCCESS,
      );
      if (process.env.REACT_APP_HAP_STAP === 'true' && !isOber) {
        this.setState({...this.state, showLoader: true});
        setTimeout(() => {
          this.setState({...this.state, showLoader: false});
          this.pay(PaymentMethod.IDEAL, 0);
        }, 200);
      }
    } else if (
      response === 'breakfast_closed' ||
      response === 'lunch_closed' ||
      response === 'dinner_closed' ||
      response === 'snacks_closed'
    ) {
      const errors: {[key: string]: string} = {
        breakfast_closed: 'Ontbijt',
        lunch_closed: 'Lunch',
        dinner_closed: 'Diner',
        snack_closed: 'Snacks',
      };
      this.props.showNotification(
        `U kunt op dit moment geen ${errors[response]} gerechten bestellen.`,
        NotificationType.ERROR,
      );
    } else {
      this.props.showNotification(
        'Er ging iets mis, probeer het later opnieuw.',
        NotificationType.ERROR,
      );
    }
  };

  paymentMethodClicked = async (method: PaymentMethod) => {
    this.setState({
      paymentMethod: method,
      showPaymentConfirmationDialog: true,
      showPayDialog: false,
    });
  };

  pay = async (method: any, tipValue: number) => {
    this.setState({loading: true});
    if (method === PaymentMethod.IDEAL) {
      await this.payIdeal(tipValue);
      return;
    }

    const {session} = this.props;

    const data = await api.callOber(
      session?.id,
      session?.token,
      method,
      tipValue,
    );
    const {success, error} = data;
    this.setState({loading: false});
    if (success) {
      this.props.showNotification(
        'De ober komt eraan!',
        NotificationType.SUCCESS,
      );
      this.setState({showPaymentConfirmationDialog: false});
    } else if (error === 'alreadyRequested') {
      this.props.showNotification(
        'De ober komt eraan, een moment a.u.b.',
        NotificationType.ERROR,
      );
      this.setState({showPaymentConfirmationDialog: false});
    } else {
      this.props.showNotification(
        'Er ging iets mis, probeer het later opnieuw.',
        NotificationType.ERROR,
      );
    }
  };

  payIdeal = async (tipValue: number) => {
    const {history, session, restaurants} = this.props;
    const data = await api.paySession(session?.id, session?.token, tipValue);

    const restaurant = restaurants.find(
      (restaurant) => session?.restaurantId === restaurant.id,
    );

    history.push('/restaurant/' + restaurant?.generateSlug());

    if (data.checkout_url) {
      await this.props.updateSessionStatus('ideal');
      window.location.href = data.checkout_url;
    } else {
      // this.props.showNotification(
      //   'Er ging iets mis, probeer het later opnieuw.',
      //   NotificationType.ERROR,
      // );
    }
  };

  _renderContent = () => {
    const {menuItemsQueue, menuItemsOrdered, session} = this.props;
    const isOrdering = menuItemsQueue.length > 0;
    const isOber = session?.isOber ?? false;
    const canPay =
      menuItemsQueue.length === 0 &&
      menuItemsOrdered.length > 0 &&
      session?.status !== 'closed' &&
      !isOber;
    const canCallOber =
      session?.status !== 'closed' &&
      session?.sessionType === SessionType.RESTAURANT;
    const canLeaveSession =
      menuItemsOrdered.length <= 0 &&
      session?.sessionType === SessionType.RESTAURANT;

    let items = isOrdering ? this._getItems() : this._getAlreadyOrderedItems();

    let itemQueue = menuItemsQueue;

    let cannotOrder = false;
    if (this.props.restaurant && this.props.restaurant.minAmount) {
      cannotOrder =
        Number(this._getTotalPrice(menuItemsQueue)) * 100 <
        this.props.restaurant.minAmount;
    }
    if (session && session.sessionType !== 'FROM_HOME') {
      cannotOrder = false;
    }

    if (
      cannotOrder &&
      this.props.restaurant &&
      this.props.restaurant.extraCostMinAmount > 0
    ) {
      cannotOrder = false;
      const itemDetails = {
        title: 'Extra kosten',
        description: `Extra kosten omdat je onder het minimumbedrag van ${Formatters.formatCurrency(
          this.props.restaurant.minAmount,
        )} zit`,
        status: 'queued',
        mainPrice: `${this.props.restaurant.extraCostMinAmount}`,
        hideAdd: true,
      };
      items.push({
        type: 'order',
        details: itemDetails,
      });
      itemQueue.push({amount: 1, details: itemDetails});
    }

    return (
      <>
        {this._renderItems(items)}
        {session && session.tip > 0 && <OrderTip price={session.tip} />}
        <OrderTotal
          price={this._getTotalPrice(isOrdering ? itemQueue : menuItemsOrdered)}
        />
        {isOrdering && (
          <>
            <Button
              text="Bestelling plaatsen"
              className="mt-20 ml-16 mr-16 mb-20"
              icon={<CheckIcon className="icon" fill="#ffffff" />}
              onClick={() => {
                if (!session?.isOber) {
                  this.setState({
                    ...this.state,
                    showNameDialog: true,
                  });
                } else {
                  this.setState({
                    ...this.state,
                    showSubmitConfirm: true,
                  });
                }
              }}
              disabled={cannotOrder}
            />
            {cannotOrder && (
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <Typography>
                  Je zit niet aan het minium bedrag van{' '}
                  {Formatters.formatCurrency(
                    this.props.restaurant?.minAmount ?? 0,
                  )}
                </Typography>
              </div>
            )}
          </>
        )}
        {canPay && (
          <Button
            text="Afrekenen"
            className="mt-20 ml-16 mr-16 mb-20"
            onClick={this.payClicked}
          />
        )}
        {canCallOber && !isOber && (
          <AltAction
            text="Ober roepen"
            className="mb-30 mt-10"
            onClick={this.callOberClicked}
          />
        )}

        {canLeaveSession && (
          <AltAction
            text="Tafel verlaten"
            className="mb-30 mt-10"
            onClick={this.leaveSessionClicked}
          />
        )}

        {isOrdering &&
          menuItemsOrdered.length > 0 &&
          this._renderItems(this._getAlreadyOrderedItems())}
        {isOrdering && menuItemsOrdered.length > 0 && (
          <OrderTotal price={this._getTotalPrice(menuItemsOrdered)} />
        )}
      </>
    );
  };

  _renderItems = (itemsToRender: any[]) => {
    return itemsToRender.map((item, index) => {
      if (item.type === 'category') {
        return <CategoryHeader title={item.title} key={index} />;
      }
      if (item.type === 'order') {
        const details = item.details;

        return (
          <OrderItem
            title={details.title}
            description={details.description}
            extra={details.extra}
            addAmount={(add: boolean) => this.addAmount(item, add)}
            item={details}
            key={index}
            price={this.getItemQueuePrice(item)}
            ordered={item.ordered}
            sideDish={details.sideDishes}
            comment={details.comment}
            allergies={details.allergies}
            amount={item.amount}
            status={item.status}
          />
        );
      }
      return <></>;
    });
  };

  render() {
    const {
      loading,
      showPayDialog,
      showSubmitConfirm,
      showDeliverDialog,
      showPaymentConfirmationDialog,
      showOberComingDialog,
      showNameDialog,
      showLoader,
      name: currentName,
    } = this.state;
    const {hasSession, session} = this.props;
    const ordersFromHome =
      hasSession && session?.sessionType === SessionType.FROM_HOME;

    return (
      <>
        {showLoader && <LoadingIndicator isLoading />}
        {showPayDialog && (
          <PayDialog
            onCancel={() => this.setState({showPayDialog: false})}
            onPaymentMethodSelected={this.paymentMethodClicked}
          />
        )}
        {showOberComingDialog && (
          <MessageDialog
            title={'De ober komt eraan'}
            description={'Je hebt al een ober gevraagd, even geduld a.u.b.'}
            onComplete={() => {
              this.setState({showOberComingDialog: false});
            }}
          />
        )}
        {showDeliverDialog && (
          <DeliverDialog
            onCancel={() => this.setState({showDeliverDialog: false})}
          />
        )}
        {showPaymentConfirmationDialog && (
          <OrderConfirmationDialog
            paymentMethod={this.state.paymentMethod}
            onConfirm={(tipValue) =>
              this.pay(this.state.paymentMethod, tipValue)
            }
            onCancel={() =>
              this.setState({
                showPayDialog: true,
                showPaymentConfirmationDialog: false,
              })
            }
          />
        )}

        {showSubmitConfirm && (
          <YesNoDialog
            title="Weet je het zeker?"
            description="Is je bestelling zo compleet?"
            onComplete={ordersFromHome ? this.payClicked : this.submitOrders}
            onCancel={() => this.setState({showSubmitConfirm: false})}
          />
        )}

        {showNameDialog && currentName.length === 0 && (
          <TextInputDialog
            title="Zouden we je naam mogen?"
            description="Dit helpt ons bij het uitserveren van je bestelling"
            inputLabel="naam"
            inputPlaceholder="Je naam"
            confirmButtonClass="confirm"
            onCancel={() => {
              this.setState({...this.state, showNameDialog: false});
            }}
            onConfirm={(name) => {
              this.setState({
                ...this.state,
                showNameDialog: false,
                showSubmitConfirm: true,
                name,
              });
            }}
          />
        )}

        <Toolbar title="Bestellingen" back={true} onBack={this.onBack} />
        <Content toolbar={true}>
          {hasSession && <TableHeader tableNumber={session?.tableNumber} />}
          <LoadingIndicator isLoading={loading} />
          {!loading && hasSession && this._renderContent()}
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    menuItemsQueue: state.orders.menuItemsQueue,
    menuItemsOrdered: state.orders.menuItemsOrdered,
    menu: state.restaurantMenu,
    hasSession: state.session.hasSession,
    session: state.session.session,
    restaurants: state.restaurants.restaurants,
    restaurant: state.restaurants.detailRestaurant,
  };
};

export default connect(mapStateToProps, {
  changeMenuItemAmount,
  showNotification,
  submitOrders,
  clearOrders,
  getMenu,
  removeSession,
  cancelSession,
  updateSessionStatus,
})(Orders);

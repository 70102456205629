import './bottom-section.scss';
import {ReactComponent as Logo} from '../../../assets/icons/logo-white.svg';
import {ReactComponent as Facebook} from '../../../assets/icons/social/facebook.svg';
import {ReactComponent as Instagram} from '../../../assets/icons/social/instagram.svg';
import {ReactComponent as Linkedin} from '../../../assets/icons/social/linkedin.svg';
import React from 'react';
import downloadAndroid from '../../../assets/images/google_play.png';
import downloadApple from '../../../assets/images/apple.png';

export const BottomSection: React.FC = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div>
            <div className="row">
              <Logo className="logo" />
            </div>
            <div className="row">
              <div>
                <p className="intro">
                  Amuse is dé app voor en door de horeca die bijdraagt aan een
                  optimale gastbeleving in elke fase van de guest journey en
                  zorgt voor een topavond voor gast, medewerker en ondernemer.
                </p>
              </div>
            </div>
            <div className="row">
              <a href="https://www.facebook.com/amusemenu">
                <div className="social-button">
                  <Facebook />
                </div>
              </a>
              <a href="https://www.instagram.com/amusemenu/">
                <div className="social-button">
                  <Instagram />
                </div>
              </a>
              <a href="https://www.linkedin.com/company/amuse-menu/">
                <div className="social-button">
                  <Linkedin />
                </div>
              </a>
            </div>
          </div>

          <div className="column-holder">
            <div className="column">
              <p className="column-header">Amuse</p>
              <a href="/restaurants">Restaurants</a>
              <a href="https://aanmelden.amuse-menu.nl/aanmelden">
                Heb je een horecazaak?
              </a>
              <a href="amuse-menu.nl">Veelgestelde vragen</a>
              <a href="amuse-menu.nl">Blog</a>
            </div>
            <div className="column">
              <p className="column-header">Handige links</p>
              <a href="/vacatures">Vacatures</a>
              <a href="mailto:info@amuse-menu.nl">Contact</a>
              <a href="https://aanmelden.amuse-menu.nl/privacy">privacy</a>
              <a href="https://aanmelden.amuse-menu.nl/terms">Terms</a>
            </div>
            <div className="column">
              <p className="column-header">Amuse</p>
              <a>Jan Frederik Vlekkeweg 10-13</a>
              <a>5026 RJ Tilburg</a>
              <a href="mailto:info@amuse-menu.nl">info@amuse-menu.nl</a>
              <a href="tel:0646979054">06 46979054</a>
            </div>
          </div>
        </div>

        <div className="line" />

        <div className="row bottom">
          <p className="copyright">© 2022 Amuse</p>
          <div className="logo-holder">
            <img
              src={downloadApple}
              onClick={() =>
                (window.location.href =
                  'https://apps.apple.com/us/app/amuse/id1507385684')
              }
              alt="App Store"
            />
            <img
              src={downloadAndroid}
              onClick={() =>
                (window.location.href =
                  'https://play.google.com/store/apps/details?id=com.livewall.amuse')
              }
              alt="Play Store"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomSection;

import React from 'react';
import Formatters from '../../../utils/formatters';
import './order-entry-sidedish.scss';

type Props = {
  option: any;
};

const OrderEntrySidedish: React.FC<Props> = (props: Props) => {
  return (
    <div className="order-entry-sidedish">
      <span>{props.option.option}</span>
      <span>{Formatters.formatCurrency(props.option.priceModifier)}</span>
    </div>
  );
};

export default OrderEntrySidedish;

import axios from 'axios';
import RestaurantMenuItem from '../../models/restaurantMenuItem';
import Filter, {
  FilterType,
  QueryFilter,
  QueryType,
} from '../../redux/search/filter';
import APIResponse from './responses/APIResponse';
import GetMenuItemDetailsResponse from './responses/getMenuItemDetailsResponse';

const API_URL = process.env.REACT_APP_API_URL;

export default class MenuAPI {
  getSideDishes = async (
    restaurantId: string,
    menuItemId: string,
  ): Promise<APIResponse<GetMenuItemDetailsResponse>> => {
    const response = await axios.get(`${API_URL}/getMenuItemDetails`, {
      params: {
        restaurantId,
        menuItemId,
      },
    });

    return new APIResponse(response.data, GetMenuItemDetailsResponse);
  };

  search = async (
    filters: Array<Filter>,
  ): Promise<Array<RestaurantMenuItem>> => {
    const searchParams = filters.map((filter) => {
      switch (filter.type) {
        case FilterType.Label:
          return '';
        case FilterType.Price:
          return 'pricing=' + filter.value;
        case FilterType.Location:
          return 'city=' + filter.value;
        case FilterType.Mealtype:
          return 'mealType=' + filter.value;
        case FilterType.Query:
          switch ((filter as QueryFilter).queryType) {
            case QueryType.Menu:
              return 'search=' + filter.value;
            case QueryType.Restaurant:
              return 'search=' + filter.value;
            default:
              return '';
          }
        default:
          return '';
      }
    });

    //TODO: Preview filter (preview=lwpv)
    let queryParam = '';
    if (searchParams.length > 0) {
      queryParam = '?' + searchParams.join('&');
    }

    const url = `${API_URL}/getMenuItems${queryParam}`;
    const response = await axios.get(url);
    const data = response.data;

    if (data.success === 1 && data.data) {
      return data.data.map((it: any) => new RestaurantMenuItem(it));
    } else {
      return [];
    }
  };
}

import Session from '../../models/session';
import {SessionJSON} from '../../models/json/session.json';

export const SET_SESSION = 'SET_SESSION';
export const RESTORE_SESSION = 'RESTORE_SESSION';
export const UPDATE_SESSION_DATA = 'UPDATE_SESSION_DATA';
export const REMOVE_SESSION = 'REMOVE_SESSION';
export const SET_SESSION_CHANGE_TOKEN = 'SET_SESSION_CHANGE_TOKEN';
export const UPDATE_SESSION_STATUS = 'UPDATE_SESSION_STATUS';
export const UPDATE_USER_GREETING_STATE = 'UPDATE_USER_GREETING_STATE';

type SetSessionAction = {
  type: typeof SET_SESSION;
  payload: Session;
};

type RestoreSessionAction = {
  type: typeof RESTORE_SESSION;
};

type UpdateSessionDataAction = {
  type: typeof UPDATE_SESSION_DATA;
  payload: SessionJSON;
};

type RemoveSessionAction = {
  type: typeof REMOVE_SESSION;
};

type SetSessionChangeTokenAction = {
  type: typeof SET_SESSION_CHANGE_TOKEN;
  payload: string;
};

type UpdateSessionStatusAction = {
  type: typeof UPDATE_SESSION_STATUS;
  payload: string;
};

type UpdateUserGreetingState = {
  type: typeof UPDATE_USER_GREETING_STATE;
  payload: boolean;
};

export type SessionActions =
  | SetSessionAction
  | RestoreSessionAction
  | UpdateSessionDataAction
  | RemoveSessionAction
  | SetSessionChangeTokenAction
  | UpdateSessionStatusAction
  | UpdateUserGreetingState;

import axios from 'axios';
import RestaurantLabel from '../models/restaurantLabel';
import RestaurantMenuItem from '../models/restaurantMenuItem';

const API_URL = process.env.REACT_APP_API_URL;

async function getMenuCategories(restaurantId, m) {
  const url = `${API_URL}/getRestaurantCategories?restaurantId=${restaurantId}`;
  const response = await axios.get(url);
  const data = response.data;

  if (data.success === 1 && data.data) {
    return data.data;
  } else if (data.success === 1) {
    return [];
  } else {
    return null;
  }
}

async function getMenu(restaurantId, isPreview) {
  let url = `${API_URL}/getMenuItems?restaurantId=${restaurantId}`;
  if (isPreview) {
    url += '&preview=lwpv';
  }
  const response = await axios.get(url);
  const data = response.data;

  if (data.success === 1 && data.data) {
    return data.data.map((it) => new RestaurantMenuItem(it));
  } else {
    return [];
  }
}

async function searchMenu({search, menuItem, pricing, city}) {
  const searchParams = [];
  if (menuItem) {
    searchParams.push('menuItem=' + menuItem);
  }
  if (search) {
    searchParams.push('search=' + search);
  }
  if (pricing) {
    searchParams.push('pricing=' + pricing);
  }
  if (city) {
    searchParams.push('city=' + city);
  }
  let queryParam = '';
  if (searchParams.length > 0) {
    queryParam = '?' + searchParams.join('&');
  }
  const url = `${API_URL}/getMenuItems${queryParam}`;
  const response = await axios.get(url);
  const data = response.data;

  if (data.success === 1 && data.data) {
    return data.data;
  } else {
    return null;
  }
}

async function callOber(sessionId, sessionToken, type, tips) {
  var url = `${API_URL}/callOber/?sessionId=${encodeURIComponent(
    sessionId,
  )}&sessionToken=${encodeURIComponent(sessionToken)}&request=${type}`;
  if (tips !== undefined) {
    url += `&tips=${tips * 100}`;
  }
  const response = await axios.get(url);
  const data = response.data;

  return data;
}

// this api call is used to get a menu item which could be inactive (&preview=lwpv)
async function getMenuItemDetails(restaurantId, menuItemId) {
  const url = `${API_URL}/getMenuItemDetails/?restaurantId=${restaurantId}&menuItemId=${menuItemId}&preview=lwpv`;
  const response = await axios.get(url);

  if (response.data.success === 1 && response.data.data) {
    return response.data.data;
  }

  return null;
}

async function getLabels() {
  const url = `${API_URL}/getRestaurantLabels`;
  const response = await axios.get(url);

  if (response.data.success === 1 && response.data.data) {
    return response.data.data.map((x) => new RestaurantLabel(x));
  }

  return [];
}

async function createSession(restaurantId, userToken) {
  const url = `${API_URL}/createSession`;

  const data = {
    restaurantId: restaurantId,
  };

  if (userToken && userToken.length > 0) {
    data.lw_token = userToken;
  }

  const response = await axios.post(url, data);

  if (response.data.success === 1) {
    return response.data.data;
  }

  return null;
}

async function checkForChanges(sessionId, sessionToken) {
  const url = `${API_URL}/checkForChanges?sessionId=${encodeURIComponent(
    sessionId,
  )}&sessionToken=${encodeURIComponent(sessionToken)}`;
  const response = await axios.get(url);
  if (response.data.success === 1) {
    return response.data.data;
  }

  if (
    response.data.success === 0 &&
    response.data.error === 'validationError' &&
    response.data.message &&
    response.data.message.includes('session is closed')
  ) {
    return 'session_closed';
  }

  return null;
}

async function addOrder(
  sessionId,
  sessionToken,
  itemId,
  sideDishSelections,
  comment,
  amount = 2,
) {
  const url = `${API_URL}/addOrder`;

  const response = await axios.post(url, {
    sessionId: sessionId,
    sessionToken: sessionToken,
    itemId: itemId,
    sideDishSelections: sideDishSelections,
    comment: comment,
    amount: amount,
  });

  if (response.data.success === 1) {
    return {...response.data.data, success: 1};
  }

  if (response.data.error) {
    return response.data.error;
  }

  return null;
}

async function duplicateOrder(sessionId, sessionToken, orderId) {
  const url = `${API_URL}/duplicateOrder`;

  const response = await axios.post(url, {
    sessionId: sessionId,
    sessionToken: sessionToken,
    orderId: orderId,
  });

  if (response.data.success === 1) {
    return response.data.data;
  }

  return null;
}

async function removeOrder(sessionId, sessionToken, orderId) {
  const url = `${API_URL}/removeOrder`;

  const response = await axios.post(url, {
    sessionId: sessionId,
    sessionToken: sessionToken,
    orderId: orderId,
  });

  if (response.data.success === 1) {
    return response.data.data;
  }

  return null;
}

async function submitOrders(sessionId, sessionToken, extraOptions) {
  const url = `${API_URL}/submitOrders`;

  let params = {
    sessionId: sessionId,
    sessionToken: sessionToken,
  };

  if (extraOptions) {
    params = Object.assign({}, params, extraOptions);
  }

  const response = await axios.post(url, params);

  if (response.data.success === 1) {
    return {...response.data.data, success: 1};
  }

  if (response.data.error) {
    return response.data;
  }

  return null;
}

async function login(email, password) {
  const url = `${API_URL}/login`;

  const response = await axios.post(url, {
    email: email,
    password: password,
  });

  if (response.data.success === 1) {
    const data = response.data.data;
    return {success: 1, data: data};
  }

  if (response.data.error === 'EmailNotVerified') {
    return 'email_not_verified';
  }

  if (response.data.error === 'InvalidCredentials') {
    return 'invalid_credentials';
  }

  return null;
}

async function register(email, password, firstName, optInChecked) {
  const url = `${API_URL}/register`;

  const response = await axios.post(url, {
    email: email,
    password: password,
    firstName: firstName,
    optIn: optInChecked ? '1' : '0',
  });

  if (response.data.success === 1) {
    return 'success';
  }

  if (
    response.data.error === 'EmailNotVerified' ||
    response.data.error === 'EmailExists'
  ) {
    // random error for register API call, but okay lets just say 'email taken'
    return 'email_taken';
  }

  return null;
}

async function changePassword(email, newPassword, resetToken) {
  const url = `${API_URL}/passwordReset`;

  const response = await axios.post(url, {
    email: email,
    password: newPassword,
    resetToken: resetToken,
  });

  return response.data.success === 1;
}

async function resetPassword(email) {
  const url = `${API_URL}/sendPasswordResetEmail`;

  const response = await axios.post(url, {
    email: email,
  });

  return response.data.success === 1;
}

async function setAllergies(token, allergies) {
  const url = `${API_URL}/setAllergies`;

  const response = await axios.post(url, {
    lw_token: token,
    allergies: allergies,
  });

  return response.data.success === 1;
}

async function linkUserSession(userToken, sessionId, sessionToken) {
  const url = `${API_URL}/linkUserSession`;

  const response = await axios.post(url, {
    lw_token: userToken,
    sessionId: sessionId,
    sessionToken: sessionToken,
  });

  return response.data.success === 1;
}

async function addFavoriteRestaurant(token, restaurantId) {
  const url = `${API_URL}/setFavoriteRestaurant`;

  const response = await axios.post(url, {
    lw_token: token,
    restaurantId: restaurantId,
  });

  return response.data.success === 1;
}

async function removeFavoriteRestaurant(token, restaurantId) {
  const url = `${API_URL}/removeFavoriteRestaurant`;

  const response = await axios.post(url, {
    lw_token: token,
    restaurantId: restaurantId,
  });

  return response.data.success === 1;
}

async function addFavoriteMenuItem(token, menuItemId) {
  const url = `${API_URL}/setFavoriteMenuItem`;

  const response = await axios.post(url, {
    lw_token: token,
    menuItemId: menuItemId,
  });

  return response.data.success === 1;
}

async function removeFavoriteMenuItem(token, menuItemId) {
  const url = `${API_URL}/removeFavoriteMenuItem`;

  const response = await axios.post(url, {
    lw_token: token,
    menuItemId: menuItemId,
  });

  return response.data.success === 1;
}

async function uploadProfileImage(token, profileImage) {
  const url = `${API_URL}/uploadProfileImage`;

  const response = await axios.post(url, {
    lw_token: token,
    profileImage: profileImage,
  });

  if (response.data.success === 1 && response.data.data.token) {
    return {success: 1, token: response.data.data.token};
  }

  return null;
}

async function getOrderHistory(token) {
  const url = `${API_URL}/getOrderHistory`;

  const response = await axios.post(url, {
    lw_token: token,
  });

  if (response.data.success === 1) {
    return response.data.data;
  }

  return null;
}

async function paySession(sessionId, sessionToken, tips = 0) {
  const url = `${API_URL}/mollie/paySession`;
  const response = await axios.post(url, {
    sessionId: sessionId,
    sessionToken: sessionToken,
    tips: tips * 100,
  });
  return response.data;
}

async function recoverFailedSession(sessionId, sessionToken) {
  const url = `${API_URL}/recoverFailedSession`;
  const response = await axios.post(url, {
    sessionId: sessionId,
    sessionToken: sessionToken,
  });

  return response.data;
}
const api = {
  getMenu: getMenu,
  searchMenu: searchMenu,
  getMenuCategories: getMenuCategories,
  callOber: callOber,
  getLabels: getLabels,
  checkForChanges: checkForChanges,
  addOrder: addOrder,
  duplicateOrder: duplicateOrder,
  removeOrder: removeOrder,
  submitOrders: submitOrders,
  login: login,
  register: register,
  setAllergies: setAllergies,
  resetPassword: resetPassword,
  changePassword: changePassword,
  linkUserSession: linkUserSession,
  addFavoriteRestaurant: addFavoriteRestaurant,
  removeFavoriteRestaurant: removeFavoriteRestaurant,
  addFavoriteMenuItem: addFavoriteMenuItem,
  removeFavoriteMenuItem: removeFavoriteMenuItem,
  uploadProfileImage: uploadProfileImage,
  getOrderHistory: getOrderHistory,
  getMenuItemDetails: getMenuItemDetails,
  paySession: paySession,
  createSession: createSession,
  recoverFailedSession: recoverFailedSession,
  API_URL: API_URL,
};

export default api;

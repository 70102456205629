import NotAuthenticatedError from '../../models/errors/notAuthenticated';
import {NotificationType} from '../../models/notification';
import api from '../../services/api';
import apiv2 from '../../services/api/apiv2';
import {showNotification} from './notifications.actions';

export function loadAccount() {
  return async function (dispatch) {
    dispatch({
      type: 'LOAD_ACCOUNT',
      payload: {},
    });
  };
}

export function login(email, password, sessionId, sessionToken) {
  return async function (dispatch, getState) {
    const response = await api.login(email, password);

    if (response && response.success === 1) {
      if (sessionId && sessionToken) {
        await api.linkUserSession(response.data.token, sessionId, sessionToken);
      }

      const state = getState();
      const favoriteRestaurants = Object.keys(
        state.account.restaurantFavorites,
      ).join(',');
      const favoriteMenuItems = Object.keys(state.account.menuFavorites).join(
        ',',
      );

      dispatch(addRestaurantFavorite(response.data.token, favoriteRestaurants));
      dispatch(addMenuFavorite(response.data.token, favoriteMenuItems));

      dispatch({
        type: 'SET_ACCOUNT',
        payload: response.data,
      });
    }

    return Promise.resolve(response);
  };
}

export function loginFromAccountConfirm(token, sessionId, sessionToken) {
  return async function (dispatch, getState) {
    const response = await apiv2.profile.get(token);

    if (sessionId && sessionToken) {
      await api.linkUserSession(token, sessionId, sessionToken);
    }

    const state = getState();
    const favoriteRestaurants = Object.keys(
      state.account.restaurantFavorites,
    ).join(',');
    const favoriteMenuItems = Object.keys(state.account.menuFavorites).join(
      ',',
    );

    dispatch(addRestaurantFavorite(token, favoriteRestaurants));
    dispatch(addMenuFavorite(token, favoriteMenuItems));

    response.token = token;

    dispatch({
      type: 'SET_ACCOUNT',
      payload: response,
    });

    return Promise.resolve(response);
  };
}

export function logout() {
  return async function (dispatch) {
    dispatch({
      type: 'REMOVE_ACCOUNT',
      payload: {},
    });
  };
}

export function register(email, password, firstName, optInChecked) {
  return async function (dispatch) {
    const response = await api.register(
      email,
      password,
      firstName,
      optInChecked,
    );

    return Promise.resolve(response);
  };
}

export function updateProfile(token, profile) {
  return async function (dispatch) {
    const response = await apiv2.profile.update(token, profile);
    if (response && response.isSuccess) {
      dispatch({
        type: 'SET_ACCOUNT',
        payload: {...profile, token},
      });
      dispatch(
        showNotification('Profiel bijgewerkt', NotificationType.SUCCESS),
      );
    } else {
      dispatch(
        showNotification(
          'Fout bij het bijwerken van profiel ' + response.message,
          NotificationType.ERROR,
        ),
      );
    }
  };
}

export function getProfile(token) {
  return async function (dispatch) {
    const response = await apiv2.profile.get(token);

    if (response.isSuccess) {
      if (response.allergies) {
        dispatch({
          type: 'SET_ALLERGIES',
          payload: response.allergies,
        });
      }

      if (response.favorites && response.favorites.restaurants) {
        const favorites = response.favorites.restaurants.reduce(
          (previous, current) => {
            previous[`${current.id}`] = true;
            return previous;
          },
          {},
        );

        dispatch({
          type: 'SET_RESTAURANT_FAVORITES',
          payload: favorites,
        });
      }

      if (response.favorites && response.favorites.menuItems) {
        const favorites = response.favorites.menuItems.reduce(
          (previous, current) => {
            previous[`${current.id}`] = true;
            return previous;
          },
          {},
        );

        dispatch({
          type: 'SET_MENU_FAVORITES',
          payload: favorites,
        });
      }

      if (response.profileImage) {
        dispatch({
          type: 'SET_PROFILE_IMAGE',
          payload: response.profileImage,
        });
      }
    } else {
      if (response.error === 'NotLoggedIn') {
        dispatch(logout());
        throw new NotAuthenticatedError();
      }
    }
  };
}

export function setAllergies(token, allergies) {
  return async function (dispatch) {
    const success = await api.setAllergies(token, allergies);

    if (success) {
      dispatch({
        type: 'SET_ALLERGIES',
        payload: allergies,
      });
    }
  };
}

export function setHideAllergies(hide) {
  return async function (dispatch) {
    dispatch({
      type: 'SET_HIDE_ALLERGIES',
      payload: hide,
    });
  };
}

export function addRestaurantFavorite(token, id) {
  return async function (dispatch) {
    let success = true;
    if (token) {
      success = await api.addFavoriteRestaurant(token, id);

      if (success) {
        dispatch({
          type: 'ADD_RESTAURANT_FAVORITE',
          payload: id,
        });

        dispatch(showNotification('Favoriet toegevoegd!', 'success'));
      }
    } else {
      dispatch({
        type: 'ADD_RESTAURANT_FAVORITE',
        payload: id,
      });
      dispatch(showNotification('Favoriet toegevoegd!', 'success'));
    }
  };
}

export function removeRestaurantFavorite(token, id) {
  return async function (dispatch) {
    let success = true;
    if (token) {
      success = await api.removeFavoriteRestaurant(token, id);
      if (success) {
        dispatch({
          type: 'REMOVE_RESTAURANT_FAVORITE',
          payload: id,
        });
        dispatch(showNotification('Favoriet verwijderd!', 'success'));
      } else {
        dispatch(
          showNotification('Fout bij het verwijderen van favoriet', 'error'),
        );
      }
    } else {
      dispatch({
        type: 'REMOVE_RESTAURANT_FAVORITE',
        payload: id,
      });
      dispatch(showNotification('Favoriet verwijderd!', 'success'));
    }
  };
}

export function addMenuFavorite(token, id) {
  return async function (dispatch) {
    let success = true;
    if (token) {
      success = await api.addFavoriteMenuItem(token, id);
      if (success) {
        dispatch({
          type: 'ADD_MENU_FAVORITE',
          payload: id,
        });
        dispatch(showNotification('Favoriet toegevoegd!', 'success'));
      }
    } else {
      dispatch({
        type: 'ADD_MENU_FAVORITE',
        payload: id,
      });
      dispatch(showNotification('Favoriet toegevoegd!', 'success'));
    }
  };
}

export function removeMenuFavorite(token, id) {
  return async function (dispatch) {
    let success = true;
    if (token) {
      success = await api.removeFavoriteMenuItem(token, id);

      if (success) {
        dispatch({
          type: 'REMOVE_MENU_FAVORITE',
          payload: id,
        });
        dispatch(showNotification('Favoriet verwijderd!', 'success'));
      } else {
        dispatch(
          showNotification('Fout bij het verwijderen van favoriet', 'error'),
        );
      }
    } else {
      dispatch({
        type: 'REMOVE_MENU_FAVORITE',
        payload: id,
      });
      dispatch(showNotification('Favoriet verwijderd!', 'success'));
    }
  };
}

export function loginFacebook(token) {
  return async function (dispatch) {
    const response = await apiv2.profile.get(token);

    if (!response) return Promise.resolve(false);

    dispatch({
      type: 'SET_ACCOUNT',
      payload: {...response.data.profile, token: token},
    });

    return Promise.resolve(true);
  };
}

export function uploadProfileImage(token, profileImage) {
  return async function (dispatch) {
    const response = await api.uploadProfileImage(token, profileImage);

    if (!response) {
      return;
    }

    if (response.success === 1) {
      dispatch({
        type: 'SET_TOKEN',
        payload: response.token,
      });

      const profileResponse = await apiv2.profile.get(response.token);

      if (!profileResponse) return;

      if (profileResponse.data.profile.profileImage) {
        dispatch({
          type: 'SET_PROFILE_IMAGE',
          payload: profileResponse.data.profile.profileImage,
        });
      }
    }
  };
}

export default class Formatters {
  static formatCurrency = (
    inputInCents: number | string,
    ignoreHapStap = false,
  ): string => {
    const isHapStap = process.env.REACT_APP_HAP_STAP === 'true';
    const price = Formatters.toNearestHalf(Number(inputInCents) / 100 / 3.3);
    if (isHapStap && !ignoreHapStap) {
      return `${price} ${price === 1 || price === 0.5 ? 'munt' : 'munten'}`;
    }
    return `€${(parseFloat(inputInCents.toString()) / 100)
      .toFixed(2)
      .replace('.', ',')}`;
  };

  static currency = (input: string | number, ignoreHapStap = false): string => {
    const isHapStap = process.env.REACT_APP_HAP_STAP === 'true';
    if (isHapStap && !ignoreHapStap) {
      const price = Formatters.toNearestHalf(Number(input) / 3.3);
      return `${price} ${price === 1 || price === 0.5 ? 'munt' : 'munten'}`;
    }
    return `€ ${input}`;
  };

  static capitalize = (s: string): string => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  static truncate(string: string, length: number) {
    return string.length > length
      ? `${string.substring(0, length)}...`
      : string;
  }

  static toNearestHalf = (number: number) => {
    return Math.round(number / 0.5) * 0.5;
  };
}

import LoadingIndicator from '../../components/loading-indicator/loading.indicator.component';
import { showNotification } from '../../redux/actions/notifications.actions';
import AltAction from '../../components/alt-action/alt.action.component';
import Toolbar from '../../components/toolbar/toolbar.component';
import Content from '../../components/content/content.component';
import { login } from '../../redux/actions/account.actions';
import { Component } from 'react';
import { connect } from 'react-redux';
import api from '../../services/api';
import Bg from '../../utils/bg';
import './login.style.scss';
import FacebookButton from '../../components/facebook-button/facebook-button';
import {
  Button,
  Input,
  InputGroup,
  Label,
} from '../../components/ui-base';
import { NotificationType } from '../../models/notification';
import { AppState } from '../../redux/reducers/root.reducer';
import { RouteComponentProps } from 'react-router-dom';
import Session from '../../models/session';

const FACEBOOK_LOGIN_URL = `${api.API_URL}/facebookLogin`;

type ReduxActions = {
  showNotification: (
    message: string,
    type: NotificationType,
    duration?: number,  
  ) => Promise<void>;
  login: (
    email: string,
    password: string,
    sessionId?: string,
    sessionToken?: string,
  ) => Promise<any>;
}

type ReduxProps = {
  hasSession: boolean,
  session: Session | null,
  loggedIn: boolean,
}

type Props = {} & RouteComponentProps & ReduxActions & ReduxProps;

class Login extends Component<Props> {
  state = {
    email: '',
    password: '',
    loading: false,
  };

  login = async () => {
    let { email, password } = this.state;
    const { showNotification, login } = this.props;
    email = email.toLowerCase();

    if (!this.validateEmail(email)) {
      showNotification('Vul een valide e-mailadres in', NotificationType.ERROR);
      return;
    }

    if (password.length === 0) {
      showNotification('Vul een wachtwoord in', NotificationType.ERROR);
      return;
    }

    this.setState({ loading: true });

    const { session } = this.props;
    const sessionId = session?.id;
    const sessionToken = session?.token;

    const response = await login(
      email,
      password,
      sessionId,
      sessionToken,
    );

    this.setState({ loading: false });

    if (response === 'email_not_verified') {
      showNotification(
        'Activeer uw account met de activatielink in de mail.',
        NotificationType.ERROR,
        5000,
      );
      return;
    }

    if (response === 'invalid_credentials') {
      showNotification(
        'Onjuist e-mailadres en/of wachtwoord.',
        NotificationType.ERROR,
      );
      return;
    }

    if (response && response.success === 1) {
      // success
      showNotification('U bent succesvol ingelogd!', NotificationType.SUCCESS);
    } else {
      showNotification(
        'Er ging iets mis, probeer het later opnieuw.',
        NotificationType.ERROR,
      );
    }
  };

  validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  render() {
    const { loggedIn, history } = this.props;
    const { email, password, loading } = this.state;

    if (loggedIn) {
      setTimeout(() => history.replace('/profile'), 0);
      return <></>;
    }

    return (
      <>
        <Toolbar showMenuIcon={true} title="Inloggen" />
        <Bg bg="bg-white" />

        <Content toolbar={true}>
          <div className="login_header" />

          <LoadingIndicator isLoading={loading} />
          {!loading && (
            <div className="login_content">
              <InputGroup>
                <Label>E-mailadres</Label>
                <Input
                  type="email"
                  placeholder="Bijv. email@gmail.com"
                  value={email}
                  onChange={e => this.setState({ email: e.target.value })}
                />
              </InputGroup>

              <InputGroup>
                <Label>Wachtwoord</Label>
                <Input
                  type="password"
                  placeholder="**********"
                  value={password}
                  onChange={e => this.setState({ password: e.target.value })}
                />
              </InputGroup>

              <Button text="Inloggen" className="mt-20" onClick={this.login} />

              <FacebookButton onClick={() => window.location.href = FACEBOOK_LOGIN_URL} />

              <AltAction
                text="Registreren"
                className="mt-20"
                onClick={() => history.push('/register')}
              />

              <AltAction
                text="Wachtwoord vergeten"
                className="mt-20"
                onClick={() => history.push('/forgot-password')}
              />
            </div>
          )}
        </Content>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    hasSession: state.session.hasSession,
    session: state.session.session,
    loggedIn: state.account.loggedIn,
  };
};

export default connect(mapStateToProps, { showNotification, login })(Login);

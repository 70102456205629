import SessionType from './session-type';
import JoinSessionResponse from '../services/api/responses/joinSessionResponse';
import {SessionJSON} from './json/session.json';

export default class Session {
  changeToken: string;
  creationDate: string;
  id: string;
  paymentStatus: string = '';
  restaurantId: string;
  roundNumber: string;
  sessionType: SessionType;
  status: 'open' | 'closed' | 'payment-requested';
  tableNumber: string;
  token: string;
  tip: number;
  isOber: boolean;

  static fromJson = (json: any): Session => {
    return new Session(json);
  };

  static fromJoinSessionResponse = (
    joinSessionResponse: JoinSessionResponse,
    sessionType: SessionType,
    isOber: boolean,
  ): Session => {
    return new Session({
      ...joinSessionResponse,
      sessionType: sessionType,
      isOber,
    });
  };

  constructor(data: any) {
    this.changeToken = data.changeToken;
    this.creationDate = data.creationDate;
    this.id = data.id;
    this.restaurantId = data.restaurantId;
    this.roundNumber = data.roundNumber;
    this.sessionType = data.sessionType;
    this.status = data.status;
    this.tableNumber = data.tableNumber;
    this.token = data.token;
    this.paymentStatus = data.paymentStatus;
    this.tip = parseInt(data.tips);
    this.isOber = data.isOber;
  }

  update(data: SessionJSON) {
    this.changeToken = data.changeToken;
    this.creationDate = data.creationDate;
    this.tableNumber = data.tableNumber;
    this.roundNumber = data.roundNumber;
    this.status = data.status;
    this.tip = parseInt(data.tips);
  }
}

import React, {useEffect, useState} from 'react';
import DatePicker, {registerLocale} from 'react-datepicker';
import nl from 'date-fns/locale/nl';
import 'react-datepicker/dist/react-datepicker.css';
import './step2.scss';
import {DateTime} from 'luxon';
import {useSelector} from 'react-redux';
import {AppState} from '../../../../../../redux/reducers/root.reducer';
import moment from 'moment';

type Props = {
  reservationDate: Date;
  closedOn: string;
  setReservationDate: (date: Date) => void;
};

const Step2: React.FC<Props> = ({
  reservationDate,
  closedOn,
  setReservationDate,
}) => {
  const [dateLocalesRegistered, setDateLocalesRegistered] = useState(false);
  const restaurant = useSelector(
    (appState: AppState) => appState.restaurants.detailRestaurant,
  );

  const isRestaurantClosed = (date: Date) => {
    const weekDays = [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
    ];
    const day = weekDays[date.getDay()];

    date.setDate(date.getDate() + 1);

    const momentDate = moment(date);

    if (
      restaurant &&
      restaurant.exceptionClosedOn &&
      restaurant.exceptionClosedOn.includes(momentDate.format('YYYY-MM-DD'))
    ) {
      return false;
    }

    if (
      restaurant &&
      restaurant.exceptionOpenOn &&
      restaurant.exceptionOpenOn.includes(momentDate.format('YYYY-MM-DD'))
    ) {
      return true;
    }

    return !closedOn.includes(day);
  };

  useEffect(() => {
    registerLocale('nl', nl);
    setDateLocalesRegistered(true);
  }, []);

  return (
    <div className="step2">
      {dateLocalesRegistered && (
        <DatePicker
          minDate={new Date()}
          maxDate={DateTime.now().plus({months: 6}).toJSDate()}
          selected={reservationDate}
          filterDate={isRestaurantClosed}
          onChange={(date: Date) => setReservationDate(date)}
          inline
          locale="nl"
        />
      )}
    </div>
  );
};

export default Step2;

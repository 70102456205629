import {showNotification} from '../actions/notifications.actions';
import api from '../../services/api/apiv2';
import {clearOrders} from '../actions/orders.actions';
import SessionType from '../../models/session-type';
import {AppThunk} from '../reducers/root.reducer';
import Session from '../../models/session';
import {
  setSession,
  restoreSessionAction,
  removeSessionAction,
  updateSessionDataAction,
  setSessionChangeTokenAction,
  updateSessionStatusAction,
  updateUserGreetingState,
} from './session.actions';
import {SessionJSON} from '../../models/json/session.json';
import Logger from '../../utils/logger';
import {NotificationType} from '../../models/notification';

export const restoreSession =
  (): AppThunk<Promise<void>> => async (dispatch) => {
    dispatch(restoreSessionAction());
  };

export const joinSession =
  (
    restaurantId: string,
    token: string,
    tableNumber: string,
    userToken: string,
    name: string,
    phoneNumber: string,
    isOber: boolean,
  ): AppThunk<Promise<Session | null>> =>
  async (dispatch) => {
    const response = await api.session.joinSession(
      restaurantId,
      token,
      tableNumber,
      userToken,
      false,
      true,
      true,
      name,
      phoneNumber,
    );
    if (response && response.isSuccess) {
      const sessionType =
        token === 'order_from_home'
          ? SessionType.FROM_HOME
          : SessionType.RESTAURANT;
      const session = Session.fromJoinSessionResponse(
        response.data,
        sessionType,
        isOber,
      );
      await dispatch(clearOrders());
      await dispatch(setSession(session));
      return session;
    } else if (response?.error === 'ggdMissingFields') {
      dispatch(
        showNotification(
          'Vul je naam en telefoonnummer in',
          NotificationType.ERROR,
        ),
      );
      return null;
    } else if (response?.message === "Given tablenumber isn't known") {
      dispatch(
        showNotification(
          'Het tafelnummer is niet bekend, controleer de code en/of contacteer de bediening.',
          NotificationType.ERROR,
        ),
      );
      return null;
    } else {
      dispatch(showNotification('Er ging iets mis!', NotificationType.ERROR));
      return null;
    }
  };

export const setSessionChangeToken =
  (token: string): AppThunk<Promise<void>> =>
  async (dispatch) => {
    dispatch(setSessionChangeTokenAction(token));
  };

export const updateSessionData =
  (session: SessionJSON): AppThunk<Promise<void>> =>
  async (dispatch) => {
    Logger.log(session);
    dispatch(updateSessionDataAction(session));
  };

export const updateSessionStatus =
  (newStatus: string): AppThunk<Promise<void>> =>
  async (dispatch) => {
    await dispatch(updateSessionStatusAction(newStatus));
  };

export const setUserGreeted =
  (): AppThunk<Promise<void>> => async (dispatch) => {
    await dispatch(updateUserGreetingState(true));
  };

export const removeSession =
  (): AppThunk<Promise<void>> => async (dispatch) => {
    dispatch(removeSessionAction());
  };

/**
 * Cancels a session in the API. To be used when the user doesn't want to finish a session he/she is in and will only result in a success when no payment is outstanding.
 * @param sessionToken Session to cancel
 */
export const cancelSession =
  (): AppThunk<Promise<boolean>> => async (dispatch, getState) => {
    const {hasSession, session} = getState().session;
    if (hasSession && session != null) {
      const response = await api.session.cancelSession(
        session.id,
        session.token,
      );
      if (response.isSuccess) {
        dispatch(removeSessionAction());
      }
      return response.isSuccess;
    }
    return true;
  };

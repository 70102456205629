import React, { Component } from 'react';
import './category-select.style.scss';

type Props = {
  categories: Array<any>;
  onChange: (changedIndex: number) => void;
};

export default class CategorySelect extends Component<Props> {
  state = {
    selectedIndex: 0,
  };

  categoriesContainer = React.createRef<HTMLDivElement>();
  categoryRefs: Array<React.RefObject<HTMLDivElement>>;

  constructor(props: Props) {
    super(props);
    this.categoryRefs = props.categories.map((x) =>
      React.createRef<HTMLDivElement>(),
    );
  }

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.categories.length !== this.props.categories.length) {
      this.categoryRefs = this.props.categories.map((x) =>
        React.createRef<HTMLDivElement>(),
      );
    }
  };

  getSelectedIndex = () => {
    return this.state.selectedIndex;
  };

  setSelected = (index: number) => {
    this.setState({ selectedIndex: index });
    const selected = this.categoryRefs[index].current;
    if (selected) {
      this.categoriesContainer.current?.scrollTo({
        left: selected.offsetLeft - 16,
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  reset = () => {
    this.setState({ selectedIndex: 0 });
  };

  onCategoryClicked = (index: number) => {
    this.setState({ selectedIndex: index });
    const { onChange } = this.props;
    if (onChange) {
      onChange(index);
    }
  };

  render() {
    const { categories } = this.props;
    const { selectedIndex } = this.state;

    return (
      <div
        ref={this.categoriesContainer}
        className="category_select_container_wrapper"
      >
        <div className="category_select_container">
          <div className="category_padding" />
          {categories.map((item, index) => (
            <div
              ref={this.categoryRefs[index]}
              key={index}
              className={`category_item ${selectedIndex === index && 'selected'}`}
              onClick={() => this.onCategoryClicked(index)}
            >
              {item.name}
            </div>
          )
          )}
          <div className="category_padding" />
        </div>
      </div>
    );
  }
}
